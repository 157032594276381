import React from 'react';


export class Header extends React.Component { 
  render() { 
    return (
      <div className="header">
        <button className="button-clear" onClick={this.props.toggleCompactMode}>
          <i className="fas fa-arrow-left"></i>
        </button>
        <div className="text-center d-inline">
          {this.props.children}
        </div>
      </div>
    );
  }
}
