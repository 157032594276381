import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image, Tab, Table } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Components
import { ErrorMessage, FactionIcon } from '../../Extras';
import { WideTemplate, WideHeader, WideDescription, WideTabs } from '../../WideTemplate';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

export default class Faction extends WideTemplate {
  constructor(props) {
    super(props);

    this.state = { 
      selectedTab: 'organisations'
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/factions/${this.props.match.params.sapbdb}`, 'faction');
  }

  render() {
    if (this.state.factionError) {
      return <ErrorMessage error={this.state.factionError} />;
    }

    if (this.state.factionLoaded) {
      return (
        <Container fluid className="faction-component">
          <MetaDecorator title={this.state.faction.sDisplayName} />
          <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
            <Row noGutters>
              <Image 
                alt="Contacts Background" 
                src={`${CDN_ROOT}/apbdb/background_detail.webp`}
                className="background-image opacity-5" />
              <WideHeader 
                title={this.state.faction.sDisplayName}
                contentRight={<FactionIcon faction={this.state.faction.eFaction} />} 
              />
              <WideDescription>
                <Col>
                  <p className="pre-wrap">
                    {this.state.faction.sFactionInfoDescription}
                  </p>
                </Col>
              </WideDescription>
            </Row>
            <FactionTabs 
              faction={this.state.faction}
              switchTab={this.switchTab}
              selectedTab={this.state.selectedTab} 
            />
          </SimpleBar>
        </Container>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class FactionTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasRelatedOrganisations: false
    }
  }

  componentDidMount() {
    if (this.props.faction.aRelatedOrganisations.length > 0) {
      this.setState({ hasRelatedOrganisations: true });
    }
  }

  render() {
    return (
      <WideTabs selectedTab={this.props.selectedTab} switchTab={this.props.switchTab}>
        <Tab 
          eventKey="organisations" 
          disabled={!this.state.hasRelatedOrganisations}
          title={<span><i className="fas fa-project-diagram" /> ORGANISATIONS</span>}
        >
          <OrganisationsList orgs={this.props.faction.aRelatedOrganisations} />
        </Tab>
      </WideTabs>
    );
  }
}

const OrganisationsList = props => (
  <Table>
    <tbody>
      {props.orgs.map(org => (
        <tr>
          <td className="small-width text-center">
            <Image fluid src={org.eOrganisationIcon} alt={org.sName} />
          </td>
          <td>
            <NavLink to={`/organisations/${org.sAPBDB}`}>
              {org.sName}
            </NavLink>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)