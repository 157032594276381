import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Row, Col, Table, Image } from 'react-bootstrap';

// Components
import { FactionIcon, ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Heat.scss';

export default class Heat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heatError: false
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/heat', 'heat');
  }

  levels(faction) {
    let levels = [];
    this.state.heat.forEach(lvl => {
      if (lvl.sAPBDB.startsWith(faction)) {
        levels.push(lvl)
      }
    })
    return levels;
  }

  render() {
    if (this.state.heatError) {
      return <ErrorMessage error={this.state.heatError} />;
    }

    if (this.state.heatLoaded) {
      const crim = this.levels("Notoriety");
      const enf = this.levels("Prestige");
      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <Container className="heat-component screen-center pt-3">
            <MetaDecorator title="Heat" />
            <Row>
                <Levels faction={2} levels={crim} />
                <Levels faction={1} levels={enf} />
            </Row>
          </Container>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    } 
  }
}

const Levels = props => (
  <Col xl={12}>
    <h2 className="title">
      <FactionIcon faction={props.faction} />
      {props.faction === 1 ? "ENFORCER" : "CRIMINAL"}
    </h2>
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>Description</th>
          <th>Reward Multiplier</th>
          <th>Threshold</th>
        </tr>
      </thead>
      <tbody>
      {props.levels.map(lvl => (
        <tr key={lvl.sAPBDB}>
          <td><Image src={lvl.eHUDTexture} /></td>
          <td>{lvl.sDescription}</td>
          <td>{lvl.fRewardMultiplier}</td>
          <td>{lvl.fThreshold}</td>
        </tr>
      ))}
      </tbody>
    </Table>
  </Col>
)