import React from 'react';
import SimpleBar from 'simplebar-react';
import { Col, InputGroup, Form, Button } from 'react-bootstrap';

// Components
import { FAIcon, ErrorMessage } from './Extras';

// Utilities
import utils from '../_helpers/utils';
import { API_ROOT } from '../_helpers/constants';

// Styles
import '../styles/FilterTemplate.scss';

export class FilterTemplate extends React.Component {
  UNSAFE_componentWillMount() {
    try {
      this.updateFiltersFromSearch();
    } catch {  }
  }

  componentDidMount() {
    this.getList();

    window.addEventListener("popstate", this.onBackButtonEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onBackButtonEvent);
  }

  onBackButtonEvent = (e) => {
    let previousSelected = e.target.location.pathname.replace(`${this.apiEndpoint}`, '');
    if (previousSelected) {
      if (previousSelected.substring(1)) {
        this.setState({ selected: previousSelected.substring(1).split('/')[0] });
        if (!this.state.compactMode) this.toggleCompactMode();
      }
    } else if (this.state.compactMode) {
      this.toggleCompactMode();
    }
  }

  setFilter = (name, value, shouldUpdate=true) => {
    if (value === false || value === '') {
      this.state.search.delete(name);
    } else {
      this.state.search.set(name, value);
    }

    if (name !== 'page') {
      this.setFilter('page', 1, false);
    }

    this.updateFiltersFromSearch();

    if (shouldUpdate) {
      this.updateURL();
      this.getList();
    }
  }

  checkFilter(filter) {
    if (this.state.search.get(filter)) {
      return this.state.search.get(filter);
    } else {
      return this.state.filter[filter];
    } 
  }

  redirect = (to) => {
    this.props.history.push(to);
  }

  updateURL() {
    window.history.pushState(null, '', `${this.apiEndpoint}?${this.state.search.toString()}`);
  }

  setSelected = (i) => {
    if (i === this.state.selected) {
      this.toggleCompactMode();
    } else {
      this.setState({selected: i});
      this.redirect(`${this.apiEndpoint}/${i}`);

      if (!this.state.compactMode) {
        this.toggleCompactMode();
      }
    }
  }

  toggleCompactMode = () => {
    if (this.state.compactMode) {
      this.setState({compactMode: false, selected: null});
      this.redirect(this.apiEndpoint);
    } else {
      this.setState({compactMode: true});
    }
  }

  collapseSidebar = () => {
    this.setState({ sidebarCollapsed: !this.state.sidebarCollapsed });
  }

  request = () => {
    this.setState({ hasLoaded: false, error: null });
    return fetch(`${API_ROOT}${this.apiEndpoint}?${this.state.search.toString()}`)
            .then(res => res.json());
  }
}

export class FilterSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { show: false }
  }

  toggle = () => {
    this.setState({ show: !this.state.show })
  }

  render() {
    return (
      <Col md={this.props.collapsed ? 2 : 1} className="filter-sidebar">
        <button 
          onClick={this.toggle}
          className="filter-toggle-mobile button-clear text-muted"
        >
          <i className="fas fa-filter"></i>
        </button>
        {this.props.collapsed ? 
        <SimpleBar style={{ height: 'calc(100vh - 92px)' }}
                   className={`sidebar-content ${this.state.show && 'd-block'}`}>
          <h4 className="d-flex">
            FILTERS
              <small className="ml-auto my-auto">
                {this.props.reload &&
                  <FAIcon icon='fas fa-sync' onClick={this.props.reload} />
                }
                {!this.state.show && 
                  <FAIcon icon='fas fa-chevron-left ml-3' onClick={this.props.collapseSidebar} />
                }
              </small>
          </h4>
          {this.props.children}
          <Button 
            variant="secondary" 
            className="filter-apply-button" 
            onClick={this.toggle}
          >
            Apply & Close
          </Button>
        </SimpleBar>
        :
        <div className="sidebar-content" onClick={this.props.collapseSidebar}>
          <FAIcon icon='fas fa-chevron-right fa-2x screen-center'/>
        </div>
        }
        <div className='sidebar-footer' />
      </Col>
    );
  }
}

export class FilterFooter extends React.Component {
  render() {
    return (
      <div className="filter-footer">
        {this.props.children}
      </div>
    )
  }
}

export class FilterInputRangeGroup extends React.Component {
  constructor(props) {
    super(props);

    this.min = React.createRef();
    this.max = React.createRef();
    this.typingTimeout = 0;
  }

  componentDidMount() {
    this.min.current.value = this.props.minValue ? this.props.minValue : '';
    this.max.current.value = this.props.maxValue ? this.props.maxValue : '';
  }

  handleChange = (e, dir) => {
    let value = this.checkTargetValue(e.target.value);

    // while typing
    if (this.typingTimeout) clearTimeout(this.typingTimeout);

    // typing done -> update state
    this.typingTimeout = setTimeout(() => {
      if (value === '') {
        this.props.setFilter(dir + this.props.name, false);
      } else {
        this.props.setFilter(dir + this.props.name, value);
      }
    }, 500);
  }

  checkTargetValue(value) {
    if (this.props.autoCorrect) {
      if (parseInt(value) < parseInt(this.props.min)) {
        return this.props.min;
      } else if (parseInt(value) > parseInt(this.props.max)) {
        return this.props.max;
      }
    }
    return value;
  }

  render() {
    return (
      <InputGroup>
        <InputGroup.Prepend>
          <span className="input-group-text" id={"filter-input-" + this.props.name}>
            {utils.seperateCapitalLetters(this.props.name)}
          </span>
        </InputGroup.Prepend>
        <Form.Control 
          onChange={e => this.handleChange(e, 'min')} 
          ref={this.min}
          type="number" 
          placeholder={this.props.min} />
        <InputGroup.Prepend>
          <span className="input-group-text">-</span>
        </InputGroup.Prepend>
        <Form.Control 
          onChange={e => this.handleChange(e, 'max')} 
          ref={this.max}
          type="number" 
          placeholder={this.props.max} />
      </InputGroup>
    );
  }
}

export class FilterInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = { search: this.props.search ? this.props.search : '' };

    this.typingTimeout = 0;
  }

  handleChange = e => {
    this.setState({search: e.target.value});
  }

  handleKeyUp = e => {
    let search = e.target.value;

    // while typing
    if (this.typingTimeout) clearTimeout(this.typingTimeout);

    // typing done -> update state
    this.typingTimeout = setTimeout(() => {
      this.props.setFilter('search', search);
    }, 500);
  }

  render() {
    return (
      <InputGroup>
        <InputGroup.Prepend>
          <span className="input-group-text" id={"filter-input-" + this.props.name}>
            {this.props.name}
          </span>
        </InputGroup.Prepend>
        <Form.Control 
          onChange={this.handleChange}
          onKeyUp={this.handleKeyUp}
          type="text" 
          value={this.state.search}
          placeholder={this.props.placeholder}
          className="text-left" />
      </InputGroup>
    );
  }
}

export class FilterCheckBox extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.active) {
      this.props.setFilter(this.props.name.replace(/\s/g, ''), false);
    } else {
      this.props.setFilter(this.props.name.replace(/\s/g, ''), true);
    }
  }

  render() {
    return (
      <div>
        <input 
          defaultChecked={this.props.active}
          className="styled-checkbox" 
          id={"filter-checkbox-" + this.props.name} 
          type="checkbox" 
          onClick={this.onClick} />
        <label htmlFor={"filter-checkbox-" + this.props.name}>
          {this.props.displayName ? this.props.displayName : this.props.name}
        </label>
      </div>
    );
  }
}

export class FilterSelector extends React.Component {
  renderName(option) {
    if (option.sDisplayName === 'None' || !option.sDisplayName) return option.sAPBDB;
    return option.sDisplayName;
  }
  
  render() {
    return (
      <Form.Group>
        <Form.Label htmlFor={"filter-selector-" + this.props.name}>
          {this.props.name}
        </Form.Label>
        <Form.Control 
          as='select' 
          id={"filter-selector-" + this.props.name}
          onChange={(e) => this.props.update(e.target.value, this.props.name)}
          value={this.props.selected ? this.props.selected : 'All'} 
        >
          {!this.props.noAll && <option key="All">All</option>}
          {this.props.options.map((option, i) => (
            <option key={i}>{this.renderName(option)}</option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  }
}

export class Pagination extends React.Component {
  constructor(props){
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.onChange = this.onChange.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.textInput = React.createRef();
  }

  // only allow numbers to be typed in pagination input
  onChange(e) {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.props.setFilter('page', e.target.value);
      this.scrollToTop();
    }
  }

  /* update input page on enter */
  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.props.setFilter('page', e.target.value);
    }
  }

  next() {
    if (this.props.itemCount >= 25) {
      this.props.setFilter('page', Number(this.props.page)+1);
      this.textInput.current.value = null;
      this.scrollToTop();
    }
  }

  previous() {
    if (this.props.page > 1) {
      this.props.setFilter('page', Number(this.props.page)-1);
      this.textInput.current.value = null;
      this.scrollToTop();
    }
  }

  scrollToTop() {
    const { scrollbarRef } = this.props;

    if (scrollbarRef) {
      const scrollbar = scrollbarRef.current.getScrollElement();
      scrollbar.scrollTop = 0;
    }
  }

  render() {
    let paginationClass = "pagination float-right";
    if (this.props.compactMode) {
      paginationClass += " pagination-detail";
    }

    return (
      <ul className={paginationClass}>
        <li className="page-item text-center">
          <span 
            className={this.props.page <= 1 ? 'text-muted page-link' : 'page-link'} 
            onClick={this.previous}>
            <i className="fas fa-angle-double-left"></i>
          </span>
        </li>
        <li className="page-item">
          <input 
            type="text" 
            ref={this.textInput}
            placeholder={this.props.page} 
            className="form-control" 
            onChange={this.onChange}
            onKeyDown={this.handleKeyDown} />
        </li>
        <li className="page-item text-center">
          <span 
            className={this.props.itemCount < 25 ? 'text-muted page-link' : 'page-link'} 
            onClick={this.next}>
            <i className="fas fa-angle-double-right"></i>
          </span>
        </li>
      </ul>
    );
  }
}

export class TemplateList extends React.Component {
  render() {
    let content;
    let cols;

    // Message if error or no entries in provided array
    if (this.props.error) {
      content = <ErrorMessage error={this.props.error} />;
    } else if (!this.props.hasLoaded) {
      content = utils.loadingCircle();
    } else if (this.props.list.length > 0) {
      content = this.props.children;
    } else {
      content = (
        <ErrorMessage>
          No results found.
        </ErrorMessage>
      )
    }

    // Column count
    if (!this.props.collapsed && !this.props.compactMode) {
      cols = 11;
    } else if (!this.props.collapsed && this.props.compactMode) {
      cols = 2;
    } else if (this.props.collapsed && this.props.compactMode) {
      cols = 3;
    } else {
      cols = 10;
    }

    return (
      <Col md={cols} className="ml-sm-auto">
        <SimpleBar 
          ref={this.props.scrollbarRef}
          style={{ height: `calc(100vh - ${this.props.children[1] ? '92px' : '56px'})` }}>
          {content[0] ? content[0] : content}
        </SimpleBar>
        {this.props.children[1]}
      </Col>
    )
  }
}
