import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, InputGroup, Image, ListGroup } from 'react-bootstrap';
import { CDN_ROOT } from '../_helpers/constants';

// Utilities
import utils from '../_helpers/utils';
import MetaDecorator from '../_helpers/MetaDecorator';

// Styles
import '../styles/Home.scss';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupsLoaded: true,
      groups: {},
      search: ''
    }
    this.typingTimeout = 0;

    this.request = utils.request.bind(this);
  }

  handleSearchChange = (event) => {
    let search = event.target.value;
    if (search.length < 2) {
      this.setState({ groups: {} }); 
      return;
    }

    // while typing
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
      this.setState({ groupsLoaded: false })
    }

    // typing done -> search for items
    this.typingTimeout = setTimeout(() => {
      if (!search) {
        this.setState({ groups: {}, groupsLoaded: true });
        return;
      }

      this.setState({ search: search });
      this.request(`/search?query=${search}`, 'groups');
    }, 250);
  }

  render() {
    const logo = `${CDN_ROOT}/apbdb/logo.webp`;
    const background = `${CDN_ROOT}/apbdb/background.webp`;

    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }} className="home-component">
        <MetaDecorator title="Home" />
        <Image src={background} 
               className="background-image"
               alt="background" />
        <Container className="text-center">
          <Image fluid src={logo} className="logo" alt="database logo" />
          <InputGroup>
            <input 
              autoFocus
              className="form-control" 
              type="text" 
              placeholder="Search" 
              aria-label="Search"
              onChange={this.handleSearchChange} />
            <InputGroup.Append>
              <button variant="primary" className="btn-search">
                {this.state.groupsLoaded 
                  ? <i className="fas fa-search" /> 
                  : utils.loadingCircle(true)}
              </button>
            </InputGroup.Append>
            <SearchGroup groups={this.state.groups} search={this.state.search} />
          </InputGroup>
          {utils.supportMe()}
        </Container>
      </SimpleBar>
    );
  }
}

const SearchGroup = props => (
  <>
    {Object.entries(props.groups).map((group, i) => {
      if (group[1].length <= 0) return null;

      return (
        <ListGroup key={i} className="text-left">
          <ListGroup.Item className="header">{group[0]}</ListGroup.Item>
          {group[1].slice(0, 5).map((result, i) => {
            let name = result.sDisplayName;  // Items, Roles
            if (!name) name = result.sTitle;  // Contacts
            if (!name) name = result.sMissionTitle;  // Missions
            if (!name) name = result.sAPBDB.replaceAll('_', ' - ');  // Minigames
            
            return (
              <ListGroup.Item key={i} as={NavLink} to={`/${group[0]}/${result.sAPBDB}`}>
                  <span dangerouslySetInnerHTML={{__html: utils.convertColorCode(name)}} />
              </ListGroup.Item>
            )
          })}
          {group[1].length > 5 && group[0] === 'items' && 
            <ListGroup.Item key={5} className='more' as={NavLink} to={`/${group[0]}?search=${props.search}`}>
              show more results...
            </ListGroup.Item>
          }
        </ListGroup>
      )
    })}
  </>
)

// TODO: add often searched items
