import React from 'react';
import { Container, Row, Col, Form, Button, Table,
         Accordion, Card } from 'react-bootstrap';

// Components
import { authService } from '../../../_services/auth';
import { DiscordBot }  from './DiscordBot';

// Utilities
import { BASE_ROOT } from '../../../_helpers/constants';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Dashboard.scss';

export default class Dashboard extends React.Component {
  render() {
    return (
      <Container className="admin-component pt-3">
        <MetaDecorator title="Dashboard" />
        <authService.userContext.Consumer>
          {({user}) => {
            if (!user || user.role !== 'admin') return null
            return (
              <Row>
                <Col md={12}>
                  <h1>
                    Hello {user.name}
                    <small className="text-muted">{user.role}</small>
                  </h1>
                </Col>
                <CurveUpdater />
                <ModifierTypeLinksUpdater />
                <DiscordBot />
              </Row>
            )
          }}
        </authService.userContext.Consumer>
    </Container>
    )
  }
}

class UpdateTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = { }
  }

  upload = e => {
    this.setState({ error: '', success: '' });

    // Create data to send
    var input = document.querySelector(`#${this.input}`);
    var data = new FormData();
    data.append('file', input.files[0]);

    fetch(BASE_ROOT + this.endpoint, {
      method: 'POST',
      body: data,
      headers: new Headers({
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      })
    })
    .then(res => {
      if (res.status === 500 || res.status === 400) {
        this.setState({ error: "Something went wrong... check file." })
      } else {
        return res.text();
      }
    })
    .then(data => this.setState({ success: data }));
  }
}

class CurveUpdater extends UpdateTemplate {
  constructor(props) {
    super(props);

    this.endpoint = '/admin/upload/curves';
    this.input = 'fileCurve';
  }

  render() {
    return (
      <Col className="dashboard-column" md={6}>
        <small className="text-muted">Curve File (.T3D)</small>
        <Form>
          <Form.File id={this.input} label="..." custom />
          <Button variant="primary" onClick={this.upload}>
            Submit
          </Button>
          <span className="text-success">{this.state.success}</span>
          <span className="text-danger">{this.state.error}</span>
        </Form>
      </Col>
    )
  }
}

class ModifierTypeLinksUpdater extends UpdateTemplate {
  constructor(props) {
    super(props);

    this.endpoint = '/admin/upload/modifiers';
    this.input = 'fileModifiers';

    this.state = { modifiers: [] }
    this.loadModifiers = this.loadModifiers.bind(this);
  }

  loadModifiers() {
    authService.authenticatedRequest('/admin/dashboard/modifiers')
      .then(data => this.setState({ modifiers: data }));
  }

  render() {
    return (
      <Col className="dashboard-column" md={6}>
        <small className="text-muted">Modifier Type File</small>
        <Form>
          <Form.File id={this.input} label="..." custom />
          <Button variant="primary" onClick={this.upload}>
            Submit
          </Button>
          <span className="text-success">{this.state.success}</span>
          <span className="text-danger">{this.state.error}</span>
        </Form>

        <Accordion className="pt-3" onSelect={this.loadModifiers}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Modifier List
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Table className="table-lite">
                  <tbody>
                    {this.state.modifiers.map(m => (
                      <tr key={m.eModifierType} className="small">
                        <td>{m.eModifierType}</td>
                        <td>{m.sTable}</td>
                        <td>{m.sVariable}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Col>
    )
  }
}
