const SETTINGS = 'settings';

const DEFAULT = {
  site: {
    debug: false,
    modding: false
  },
  filters: []
}

function checkSettings() {
  if (!localStorage.getItem(SETTINGS)) {
    localStorage.setItem(SETTINGS, JSON.stringify( DEFAULT ));
  }
}

function get(obj=false, key=false) {
  const settings = JSON.parse( localStorage.getItem(SETTINGS) );

  if (!obj && !key) return settings;
  if (!key) return settings[obj];

  try {
    return settings[obj][key];
  } catch {
    return null;
  }
}

function set(obj, key, val) {
  var settings = JSON.parse( localStorage.getItem(SETTINGS) );

  if (!settings[obj]) settings[obj] = {}
  
  settings[obj][key] = val;

  localStorage.setItem(SETTINGS, JSON.stringify( settings ));
}


export const settingsService = {
  checkSettings,
  get,
  set
}