import React from 'react';
import * as ReactDOMServer from 'react-dom/server';

// Components
import { ErrorMessage } from '../../Extras';
import { Image } from 'react-bootstrap';

// Utilities
import * as L from "leaflet";
import utils from '../../../_helpers/utils';
import { CDN_ROOT } from '../../../_helpers/constants';

// Styles
import './Map.scss';
import "leaflet/dist/leaflet.css";

export default class DistrictMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = { debug: true, leaflet: false, once: false}

    this.request = utils.request.bind(this);
    this.createMap = this.createMap.bind(this);
    this.moveTo = this.moveTo.bind(this);
  }

  componentDidMount() {
    this.request(`/districts/${this.props.map}/map`, 'map');

    this.props.moveTo(this.moveTo);
  }

  componentDidUpdate() {
    if (this.state.mapLoaded && !this.state.once) {
      if (!this.state.mapError) this.createMap();
    }
  }

  moveTo(coords) {
    if (coords) this.state.leaflet.flyTo(coords, 5);
  }

  createMap() {
    // Create map layers
    const colored = this.createLayer('colored');
    const minimap = this.createLayer('minimap', false);

    // Delcare map object with layers
    let map = L.map('map', {
      layers: [colored, minimap]
    }).setView([0, 0], 2);


    // Set max bounds
    let southWest = L.latLng(JSON.parse(this.state.map.boundsSouthWest));
    let northEast = L.latLng(JSON.parse(this.state.map.boundsNorthEast));

    map.setMaxBounds(L.latLngBounds(southWest, northEast));

    // Create layer controls
    const baseMaps = {
      "Minimap": minimap,
      "Colored": colored
    };

    const layerControl = L.control.layers(baseMaps).addTo(map);

    // Contacts
    if (this.state.map.Contacts) {
      this.createContacts(map, this.state.map.Contacts);
      this.props.updateContacts(this.state.map.Contacts);
    }

    // District Blocks
    if (this.state.map.Blocks) this.createDistrictBlock(map, this.state.map.Blocks);

    // Debug stuff
    if (this.state.debug) this.debugCoordinatesEvent(map);

    // Set map as state object
    this.setState({ leaflet: map, once: true });
  }

  createLayer(style, attribution=this.state.map.attribution) {
    return L.tileLayer(`${CDN_ROOT}/maps/${this.props.map.toLowerCase()}/${style}/{z}/{x}/{y}.png`, {
      minZoom: 3,
      maxZoom: 5,
      continuousWorld: false,
      noWrap: true,
      attribution: attribution
    })
  }

  debugCoordinatesEvent(map) {
    // Get coordinates on right click
    let lat, lng;

    map.addEventListener('mousemove', function(ev) {
        lat = ev.latlng.lat;
        lng = ev.latlng.lng;
    });

    document.getElementById("map").addEventListener("contextmenu", function (event) {
        // Prevent the browser's context menu from appearing
        event.preventDefault();

        // Add marker
        // L.marker([lat, lng], ....).addTo(map);
        navigator.clipboard.writeText(`[${lat.toFixed(1)}, ${lng.toFixed(1)}]`);

        return false; // To disable default popup.
    });
  }

  createContacts(map, contacts) {
    contacts.forEach(contact => {
      // create a div icon to show on the marker
      let factionColor = 'green';
      switch (contact.detail.eOrganisation.eFaction.eFaction) {
        case 1: factionColor = 'blue'; break;
        case 2: factionColor = 'red'; break;
        default: break;
      }

      // Contact icon html and styling
      const iconHtmlStyle = {
        border: `3px solid ${factionColor}`,
        maxWidth: '48px !important'
      }

      const iconHtml = (
        <div className='text-center'>
          <Image 
            roundedCircle 
            className='contact-img shadow-sm' 
            src={contact.detail.eContactIcon}
            style={iconHtmlStyle} />
        </div>
      )

      const icon = L.divIcon({
        className: 'text-label',
        html: ReactDOMServer.renderToString(iconHtml),
        iconSize: [100, 15],
        iconAnchor: [50, 7.5]
      });

      // Create a marker for the contact
      const marker = L.marker(contact.coordinates, {
        icon: icon,
        interactive: true
      }).addTo(map);

      const popupHtml = (
        <p>
          {contact.detail.sTitle}<br />
          Faction: {contact.detail.eOrganisation.eFaction.sDisplayName}<br />
          Organisation: {contact.detail.eOrganisation.sName}
        </p>
      )
      
      // Create a popup with additional information to show on hover
      const popup = L.popup({
        closeButton: false,
        closeOnClick: false
      }).setContent(ReactDOMServer.renderToString(popupHtml),);

      // Attach a mouse hover event to the contact marker
      marker.on('mouseover', function(e) {
        this.bindPopup(popup).openPopup();
      });

      // Reset the style and close the popup when the mouse leaves the marker
      marker.on('mouseout', function(e) {
        this.closePopup();
      });

      // Attach a mouse click event to the contact marker
      marker.on('click', function(e) {
        window.location.href = `/contacts/${contact.sAPBDB}`;
      });
    })
  }

  createDistrictBlock(map, blocks) {
    blocks.forEach(block =>  {
      // create a red polygon from an array of LatLng points
      let latlngs = block.coordinates;
      let polygon = L.polygon(latlngs, {
          color: 'white',
      }).addTo(map);

      polygon.bindTooltip(block.detail.sDisplayName, {
          permanent: true,
          direction: "center",
      });
    });
  }

  render() {
    if (this.state.mapError) {
      return <ErrorMessage error={this.state.mapError} />
    }

    if (!this.state.mapLoaded) return  utils.loadingCircle();

    const style = {
      width: "100%",
      height: "100%",
      background: this.state.map.backgroundColor
    }

    return (
      <div className='map-component' id='map' style={style} />
    )
  }
}
