import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Components
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Minigames.scss';

export default class Minigames extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/minigames', 'minigames');
  }

  minigameBg(sapbdb) {
    if (sapbdb.startsWith('GunGame')) sapbdb = 'GunGame';
    return `${CDN_ROOT}/apbdb/minigames/${sapbdb}.webp`;
  }

  render() {
    if (this.state.minigamesError) return <ErrorMessage error={this.state.minigamesError} />;

    if (this.state.minigamesLoaded) {
      let minigames = [];
      let filters = ['None', 'Test', 'Main', 'Resources', 'Pure'];

      this.state.minigames.forEach(minigame => {
        if (!filters.some(minigame.sAPBDB.includes.bind(minigame.sAPBDB))) {
          minigames.push(minigame);
        }
      })

      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <MetaDecorator title="Minigames" />
          <Container className="minigames-component">
            <Row>
              {minigames.map((minigame) => (
                <Col key={minigame.id} lg={6} xl={4}>
                  <NavLink as={NavLink} to={`/minigames/${minigame.sAPBDB}`} >
                    <div className="minigame-border">
                      <div 
                        className="content"
                        style={{ backgroundImage: `url(${this.minigameBg(minigame.sAPBDB)})` }}>
                      </div>
                      <h4>{minigame.sAPBDB.replaceAll('_', ' - ')}</h4>
                    </div>
                  </NavLink>
                </Col>
              ))}
            </Row>
          </Container>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}
