import React from 'react';
import { Container, Row, Table } from 'react-bootstrap';

// Components
import { FilterTemplate, FilterSidebar, FilterInput, FilterCheckBox,
         TemplateList } from '../../FilterTemplate';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

export default class Commands extends FilterTemplate {
  constructor(props) {
    super(props);
    this.apiEndpoint = '/commands';

    this.state = {
      search: new URLSearchParams(this.props.location.search),
      sidebarCollapsed: true,
      commands: { console: [], emote: [] },
      filter: {
        search: false,
        hideConsole: false,
        hideEmote: false
      },
    }
  }

  getList = () => {
    this.request()
      .then(
        result => this.setState({ commands: result }),
        error => this.setState({ error })
      )
      .then(() => this.setState({ hasLoaded: true }))
  }

  updateFiltersFromSearch() {
    this.setState({
      filter: {
        search: this.checkFilter('search'),
        hideConsole: this.checkFilter('HideConsole'),
        hideEmote: this.checkFilter('HideEmote')
      }
    });
  }
  
  render() {
    return (
      <Container fluid>
        <MetaDecorator title="Commands" />
        <Row noGutters>
          <FilterSidebar 
            reload={this.getList}
            collapsed={this.state.sidebarCollapsed}
            collapseSidebar={this.collapseSidebar}>
            <FilterInput 
              name="Search" 
              search={this.state.filter.search} 
              setFilter={this.setFilter} />
            <FilterCheckBox 
              name='Hide Console' 
              setFilter={this.setFilter} 
              active={this.state.filter.hideConsole} />
            <FilterCheckBox 
              name='Hide Emote' 
              setFilter={this.setFilter} 
              active={this.state.filter.hideEmote} />
          </FilterSidebar>
          <TemplateList 
            list={this.state.commands.console.length > 0 
              ? this.state.commands.console 
              : this.state.commands.emote}
            hasLoaded={this.state.hasLoaded}
            error={this.state.error}
            collapsed={this.state.sidebarCollapsed}>
            <div>
              <Table className="filter-table">
                {this.state.commands.console.length > 0 && 
                <thead>
                  <tr>
                    <th>Console</th>
                    <th>Command</th>
                    <th>Description</th>
                  </tr>
                </thead>}
                <tbody>
                {this.state.commands.console.map(command => (
                  <tr key={command.sAPBDB}>
                    <td style={{ width: '20%' }} className="align-middle">{command.sDisplayName}</td>
                    <td className="align-middle">{command.sSyntaxExample}</td>
                    <td className="align-middle">{command.sDescription}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <Table className="filter-table">
                {this.state.commands.emote.length > 0 && 
                <thead>
                  <tr>
                    <th>Emote</th>
                    <th>Command</th>
                    <th>Silence Check</th>
                  </tr>
                </thead>}
                <tbody>
                {this.state.commands.emote.map(command => (
                  <tr key={command.sAPBDB}>
                    <td className="align-middle">{command.sDisplayName}</td>
                    <td className="align-middle">{command.sSlashCommand}</td>
                    <td className="align-middle">
                      {utils.displayTrueFalse(command.bSilenceCheckEnabled)}
                    </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </div>
          </TemplateList>
        </Row>
      </Container>
    )
  }
}
