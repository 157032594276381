import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Accordion, Card, Table, Image } from 'react-bootstrap';

// Components
import { ErrorMessage, SmallTable } from '../../Extras';
import { FactionIcon } from '../../Items';
import Map from './Map';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './District.scss';

export default class District extends React.Component {
  constructor(props) {
    super(props);

    this.state = { mapContacts: [] }

    this.request = utils.request.bind(this);
    this.updateContacts = this.updateContacts.bind(this);
  }

  componentDidMount() {
    this.request(`/districts/${this.props.match.params.sapbdb}`, 'district')
  }

  updateContacts(contacts) {
    this.setState({ mapContacts: contacts })
  }

  render() {
    if (this.state.districtError) {
      return <ErrorMessage error={this.state.districtError} />
    }

    if (this.state.districtLoaded) {
      return (
        <div className="district-component">
          <Row noGutters>
            <MetaDecorator title={this.state.district.sDisplayName} />
            <Col md={3}>
              <SimpleBar className="sidebar" style={{ height: 'calc(100vh - 56px)' }}>
                <h1 className="font-weight-light">
                  {this.state.district.sDisplayName}
                </h1>
                <p>{this.state.district.sDescription}</p>
                <MapControls />
                <InfoAccordion 
                  district={this.state.district} 
                  moveTo={(e) => this.moveTo(e)}
                  mapContacts={this.state.mapContacts} />
              </SimpleBar>
            </Col>
            <Col md={9} className="screen-center">
            {this.state.district.sAPBDB === 'Financial' 
              ? <Map 
                  map={this.state.district.sAPBDB} 
                  moveTo={move => this.moveTo = move} 
                  updateContacts={this.updateContacts} /> 
              : <h1 className="display-4">Work In Progress</h1>}
            </Col>
          </Row>
        </div>
      )
    } else {
      return  utils.loadingCircle();
    }
  }
}

class MapControls extends React.Component {
  render() {
    return (
      <div>

      </div>
    );
  }
}


class InfoAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.instance = this.props.district.eDefaultDistrictInstanceType;

    this.properties = {
      'Language': `${this.instance.eDistrictLanguage.sDisplayName} - ${this.instance.eDistrictLanguage.sLanguageShortName}`,
      'Localisation': this.instance.eDistrictLanguage.sLocalisationINI,
      'Minimum Rating': this.instance.eDistrictRating.nMinRating,
      'Maximum Rating': this.instance.eDistrictRating.nMaxRating
    }

    this.ruleset = {
      'Name': this.instance.eDistrictRuleSet.sDisplayName,
      'Reticule Targeting Distance': `${this.instance.eDistrictRuleSet.fReticuleTargetingDistance}m`,
      'Score Unopposed Multiplier': `${this.instance.eDistrictRuleSet.fScoreUnopposedMultiplier}x`,
      'Mission Delay': `${this.instance.eDistrictRuleSet.nMissionDelay}s`,
      'New Mission Cooldown Timer': `${this.instance.eDistrictRuleSet.nNewMissionCooldownTimer}s`,
      'Allow Backup Call': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bAllowBackupCall),
      'Allow Modifications': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bAllowFnMods),
      'Always Access Inventory': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bAlwaysAccessInventory),
      'Bounties Available': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bBountiesAvailable),
      'Can Leave While In Mission': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bCanLeaveWhileInMission),
      'Contact Referrals On': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bContactReferralsOn),
      'Customisation Limiter': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bCustomisationLimiter),
      'Do Building Checks': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bDoBuildingChecks),
      'Drop Weapons': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bDropWeapons),
      'Force Faction Friendly Colours': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bForceFactionFriendlyColours),
      'HUD Show Radar Pings': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bHUDShowRadarPings),
      'Minigames Available': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bMinigamesAvailable),
      'Mission Offers': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bMissionOffers),
      'No Parked Vehicles': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bNoParkedVehicles),
      'No Player Vehicle Spawners': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bNoPlayerVehicleSpawners),
      'No Skill Rating': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bNoSkillRating),
      'Show Threat And Rank': utils.displayTrueFalse(this.instance.eDistrictRuleSet.bShowThreatAndRank),
      'Disallow Advanced Selection': utils.displayTrueFalse(this.instance.bDisallowAdvancedSelection),
      'Premium Only': utils.displayTrueFalse(this.instance.bPremiumOnly)
    }
  }

  render() { 
    return (
      <Accordion defaultActiveKey={'1'}>
        <SmallTableCard index={1} title="PROPERTIES" rows={this.properties} />
        <SmallTableCard index={2} title="RULESET" rows={this.ruleset} />
        <ContactsCard 
          index={3} 
          title="CONTACTS" 
          district={this.props.district} 
          moveTo={this.props.moveTo}
          mapContacts={this.props.mapContacts} />
      </Accordion>
    )
  }
}

const SmallTableCard = props => (
  <Card>
    <Accordion.Collapse eventKey={props.index.toString()}>
      <Card.Body>
        <SmallTable rows={props.rows} />
      </Card.Body>
    </Accordion.Collapse>
    <Accordion.Toggle as={Card.Header} eventKey={props.index.toString()}>
      <h6>{props.title}</h6>
    </Accordion.Toggle>
  </Card>
);

class ContactsCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { contacts: [] }

    this.request = utils.request.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.state.contacts.length > 0) return
    this.request(`/contacts?district=${this.props.district.eDistrict}`, 'contacts');
  }

  render() {
    let contacts;
    if (this.state.contactsError) {
      contacts = <ErrorMessage noVCenter error={this.state.contactsError} />;
    } else if (this.state.contactsLoaded) {
      contacts = (
        <Table size="sm" className="table-lite">
          <tbody>
            {this.state.contacts.map(contact => {
              const mapContact = this.props.mapContacts.find(c => c.sAPBDB === contact.sAPBDB);

              let coords;
              if (mapContact) coords = mapContact.coordinates;

              return (
                <tr key={contact.sAPBDB}>
                  <td className='waypoint-column text-center'>
                    {coords &&
                    <a onClick={() => this.props.moveTo(coords)}>
                      <i className="fas fa-map-marker-alt" />
                    </a>
                    }
                  </td>
                  <td className='contact-column'>
                    <Image 
                      className="contact-icon"
                      src={contact.eContactIcon} 
                      alt={contact.sTitle} />
                  </td>
                  <td>
                    <small>
                      <NavLink to={`/contacts/${contact.sAPBDB}`}>
                        {contact.sTitle}
                      </NavLink>
                    </small>
                  </td>
                  <FactionIcon 
                    textRight
                    crim={contact.eOrganisation.eFaction.sAPBDB === 'Criminal'}
                    enf={contact.eOrganisation.eFaction.sAPBDB === 'Enforcer'} />
                </tr>
              )
            })}
          </tbody>
        </Table>
      )
    } else {
      contacts = utils.loadingCircle(true);
    }

    return (
      <Card>
        <Accordion.Collapse eventKey={this.props.index.toString()}>
          <Card.Body>
            {contacts}
          </Card.Body>
        </Accordion.Collapse>
        <Accordion.Toggle as={Card.Header} 
                          eventKey={this.props.index.toString()} 
                          onClick={this.handleClick}>
          <h6>{this.props.title}</h6>
        </Accordion.Toggle>
      </Card>
    );
  }
} 
