import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import annotationPlugin from 'chartjs-plugin-annotation';

// Utilities
import utils from '../../_helpers/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  annotationPlugin
);

export class PelletDamageChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deathLine: 0
    }

    this.weapon = this.props.weapon_modified ? this.props.weapon_modified : this.props.weapon;
    this.ranged = this.props.ranged_modified ? this.props.ranged_modified : this.props.ranged;

    this.data = {
      labels: [],
      datasets: [{
        label: "Health Damage",
        data: [],
        borderColor: "yellowgreen",
        pointBackgroundColor: "yellowgreen",
        borderWidth: 2,
        lineTension: 0,
        pointRadius: 0,
        fill: false
      },
      {
        label: 'Stamina Damage',
        data: [],
        borderColor: "red",
        pointBackgroundColor: "red",
        borderWidth: 2,
        lineTension: 0,
        pointRadius: 0,
        fill: false
      }, 
      {
        label: 'Hard Damage',
        data: [],
        borderColor: "lightskyblue",
        pointBackgroundColor: "lightskyblue",
        borderWidth: 2,
        lineTension: 0,
        pointRadius: 0,
        fill: false
      }]
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon_modified !== this.props.weapon_modified
        || prevProps.ranged_modified !== this.props.ranged_modified) {
      this.weapon = this.props.weapon_modified;
      this.ranged = this.props.ranged_modified;
      this.update();
    } else if (prevProps.weapon !== this.props.weapon 
               || prevProps.ranged !== this.props.ranged) {
      this.weapon = this.props.weapon;
      this.ranged = this.props.ranged;
      this.update();
    }
  }

  update() {
    // Create deathline
    const deathLine = 1000 / Math.ceil(1000 / utils.calcScaleDamage(
      this.weapon.fHealthDamage, 
      this.ranged.nRaysPerShot, 
      this.ranged.fPerRayDamageScale))

    this.setOptions(deathLine);

    // Create labels
    this.data.labels = [];  // clear labels
    for (let i = 1; i <= this.ranged.nRaysPerShot; i++) {
      this.data.labels.push(i + ` pellet${i > 1 ? 's' : ''}`);
    }

    // Add datasets
    this.addDataset(0, this.weapon.fHealthDamage);
    this.addDataset(1, this.weapon.fStaminaDamage);
    this.addDataset(2, utils.calculateHardDamage(
      this.weapon.fHealthDamage, 
      this.weapon.fHardDamageModifier));
    
    this.forceUpdate();
  }

  setOptions(deathLine) {
    this.options = {
      responsive: true,
      spanGaps: true,  // Skip null data to connect points
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          axis: 'x',
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';
              if (label) label += ': ';
              label += context.parsed.y;
              return label;
            }
          }
        },
        annotation: {
          annotations: {
            deathLine: {
              type: "line",
              yMin: deathLine,
              yMax: deathLine,
              borderColor: 'red',
              borderWidth: .75
            }
          }
        }
      }
    }
  }

  addDataset(dataset, damage) {
    var final_damage = 0;
    this.data.datasets[dataset].data = [];  // clear dataset
    for (let i = 0; i < this.ranged.nRaysPerShot; i++) {
      final_damage += Math.floor(damage * (this.ranged.fPerRayDamageScale ** i));
      this.data.datasets[dataset].data.push(final_damage);
    }
  }

  render() {
    return (
      <div className="mb-3">
        <h6 className="pl-1">PELLET DAMAGE</h6>
        <Line 
          data={this.data} 
          options={this.options} 
          plugins={[utils.hoverLine()]} 
          redraw />
      </div>
    )
  }
}