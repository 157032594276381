import React from 'react';
import { Row, Container, Accordion, Card, Modal, Button } from 'react-bootstrap';

// Components
import { FilterSidebar, FilterFooter, FilterInput, FilterCheckBox, 
  FilterTemplate, Pagination, TemplateList } from '../../FilterTemplate';
import { FAIcon } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import { API_ROOT } from '../../../_helpers/constants';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Tracker.scss';

export default class Tracker extends FilterTemplate {
  constructor(props) {
    super(props);
    this.apiEndpoint = '/tracker';

    this.state = { 
      search: new URLSearchParams(this.props.location.search),
      compactMode: Boolean(this.props.match.params.id),
      selected: this.props.match.params.id,
      sidebarCollapsed: true,
      posts: [],
      filter: {
        page: 1,
        limit: 25,
        search: false,
        hidemods: false
      },
      showDiscordModal: false
    }

    this.scrollbarRef = React.createRef();
  }

  getList = () => {
    this.request()
    .then(
      result =>  this.setState({ posts: result }),
      error => this.setState({ error })
    ).then(() => this.setState({ hasLoaded: true }))
  }

  updateFiltersFromSearch = () => {
    this.setState({
      filter: {
        page: this.checkFilter('page'),
        limit: this.checkFilter('limit'),
        search: this.checkFilter('search'),
        hidemods: this.checkFilter('HideMods') === 'true',
      }
    });
  }

  timeDifference(previous) {
    let current = new Date();
    previous = new Date(previous);

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;

    var elapsed = current - previous;  // Calculate elapsed time
    
    if (elapsed < msPerMinute) {
      elapsed = Math.round(elapsed/1000);
      return ` ${elapsed} ` + (elapsed > 1 ? `seconds ago` : `second ago`);
    }  else if (elapsed < msPerHour) {
      elapsed = Math.round(elapsed/msPerMinute);
      return ` ${elapsed} ` + (elapsed > 1 ? `minutes ago` : `minute ago`);
    }  else if (elapsed < msPerDay ) {
      elapsed = Math.round(elapsed/msPerHour)
      return ` ${elapsed} ` + (elapsed > 1 ? `hours ago` : `hour ago` );
    } else if (elapsed < msPerMonth) {
      elapsed = Math.round(elapsed/msPerDay)
      return ` approximately ${elapsed} ` + (elapsed > 1 ? `days ago` : `day ago`);   
    } else {
      let options = { weekday: 'long', month: 'short', year: 'numeric', day: 'numeric'};
      return ` on ${previous.toLocaleDateString("en-US", options)}`;   
    }
  }

  handleClick = post => {
    if (post === this.state.selected) {
      this.setState({selected: 0});
    } else {
      this.setState({selected: post});
    }
  }

  toggleDiscordModal = () => {
    if (this.state.showDiscordModal) {
      this.setState({ showDiscordModal: false });
    } else {
      this.setState({ showDiscordModal: true });
    }
  }

  render() {
    return (
      <Container fluid className="tracker-component">
        <MetaDecorator title="Forum Tracker" />
        <Row noGutters>
          <FilterSidebar 
            reload={this.getPosts}
            collapsed={this.state.sidebarCollapsed}
            collapseSidebar={this.collapseSidebar}>
            <FilterInput 
              name="Search" 
              search={this.state.filter.search}
              setFilter={this.setFilter} />
            <FilterCheckBox 
              name='Hide Mods' 
              setFilter={this.setFilter}
              active={this.state.filter.hidemods} />
            <DiscordModal 
              show={this.state.showDiscordModal} 
              toggle={this.toggleDiscordModal} />
            <a href={API_ROOT + '/tracker/rss'}>
              <i className="fas fa-rss-square" />
            </a>
            <i className="fab fa-discord" onClick={this.toggleDiscordModal} />
          </FilterSidebar>
          <TemplateList 
            list={this.state.posts} 
            hasLoaded={this.state.hasLoaded} 
            error={this.state.error}
            collapsed={this.state.sidebarCollapsed}
            scrollbarRef={this.scrollbarRef} >
            <Accordion>
              {this.state.posts.map(post => (
              <Card key={post.ID} 
                    className={this.state.selected === post.ID ? 'selected' : null}>
                <Accordion.Toggle 
                  as={Card.Header} 
                  eventKey={post.ID} 
                  onClick={() => this.handleClick(post.ID)}>
                  <a href={post.author.ProfileLink}>
                    <Card.Img 
                      width={40} 
                      height={40} 
                      variant='left'
                      src={post.author.ImageLink}
                      onError={utils.handleImageError} />
                  </a>
                  <Card.Title>
                    {post.ThreadName}
                    <small>
                      <a href={post.PostLink} className="ml-1 text-muted">
                        <FAIcon title='Go to post' icon='fas fa-external-link-alt' />  
                      </a>
                    </small>
                  </Card.Title>
                  <Card.Subtitle className='mb-2 text-muted'>
                    <a href={post.author.ProfileLink} 
                      style={{color: post.author.GroupColor}}>
                      {post.author.Name}
                    </a> posted in <a href={post.ForumLink}>{post.ForumName}</a>
                    {this.timeDifference(post.PubDate)}
                  </Card.Subtitle>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={post.ID}>
                  <Card.Body dangerouslySetInnerHTML={{__html: post.Content}}>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              ))}
            </Accordion>
            <FilterFooter>
              <Pagination 
                itemCount={this.state.posts.length}
                page={this.state.filter.page} 
                setFilter={this.setFilter}
                scrollbarRef={this.scrollbarRef} />
            </FilterFooter>
          </TemplateList>
        </Row>
      </Container>
    )
  }
}


const DiscordModal = props => (
  <Modal show={props.show} onHide={props.toggle}>
    <Modal.Header>
      <Modal.Title>Discord News Feed</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>
        The APB Database Discord bot allows you to see new tracker posts in a channel 
        of your choice. Make sure the bot successfully joined your server before
        continuing.
      </p>
      <p>
        <a href="https://discord.com/api/oauth2/authorize?client_id=232594860119556096&permissions=0&scope=bot%20applications.commands">
          Click to add bot to your server.
        </a>
      </p>

      <h5>Enable feed</h5>
      <p>
        To enable the news feed, execute the following command in the  
        designated channel: <code>/news channel set</code>
      </p>

      <h5>Disable feed</h5>
      <p>
        To disable the news feed, execute the following command anywhere inside
        your server: <code>/news channel remove</code>
      </p>

      <h5>Toggle mod posts</h5>
      <p>
        You can toggle posts from forum Mods by running 
        either <code>/news mods True</code> or <code>/news mods False</code>.
      </p>
      <small className="text-muted">
        Notice: You can only assign the feed to one channel at a time.
      </small>
    </Modal.Body>

    <Modal.Footer>
      <Button variant="secondary" onClick={props.toggle}>Close</Button>
    </Modal.Footer>
  </Modal>
)
