import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Table } from 'react-bootstrap';

// Components
import Statistics from './ItemStatistics';

// Styles
import './Make.scss';

export default function Make(props) {
  const [item] = useState(props.item);
  const [selectedLink, setSelectedLink] = useState(0);
  const [weaponLinks, setWeaponLinks] = useState([]);
  const [weapon, setWeapon] = useState(props.item.detail.eWeaponTypeLink.eWeaponType_0);
  const [originalWeapon, setOriginalWeapon] = useState(props.item.detail.eWeaponTypeLink.eWeaponType_0);

  // Create weapontypelinks array 
  useEffect(() => {
    const weaponLinkCount = 4;

    // If we already have links, skip adding more
    if (weaponLinks.length > 0) return;

    let links = [];
    for (let i = 0; i < weaponLinkCount; i++) {
      if (item.detail.eWeaponTypeLink[`eWeaponType_${i}`].sAPBDB !== 'None') {
        // Check if link already exists in links array
        if (links.find(link => link.sAPBDB === item.detail.eWeaponTypeLink[`eWeaponType_${i}`].sAPBDB)) continue;

        links.push(item.detail.eWeaponTypeLink[`eWeaponType_${i}`]);
      }
    }

    setWeaponLinks(links);
	}, [item.detail.eWeaponTypeLink, weaponLinks]);

  // Update selected weapon if other selected and not none
  useEffect(() => {
    if (selectedLink) {
      setWeapon(weaponLinks.find(link => link.sAPBDB === selectedLink));
      setOriginalWeapon(weaponLinks.find(link => link.sAPBDB === selectedLink));
    }
  }, [selectedLink, weaponLinks]);

  return (
    <Row noGutters className="make-component">
      <Col md={5}>
    	  <WeaponTypeLinks 
          selectedLink={selectedLink} 
          weaponLinks={weaponLinks} 
          setSelectedLink={setSelectedLink} 
        />
        <Statistics 
          cols={12} 
          detail={item.detail}
          weaponLink={weapon}
          category={props.item.category} 
        />
      </Col>
      <Col md={7}>
        <Maker 
          weapon={weapon}
          originalWeapon={originalWeapon}
          setWeapon={setWeapon} />
      </Col>
    </Row>
  );
}

const WeaponTypeLinks = (props) => {
  const handleOnChange = (event) => {
    props.setSelectedLink(event.target.value);
  }

  // Don't display the selector if there is only one link
  if (props.weaponLinks.length <= 1) return null;

  return (
    <div className='text-center'>
      <Form.Group>
        <Form.Label>
          WeaponTypeLink
        </Form.Label>
        <Form.Control 
          as='select'
          value={props.selectedLink}
          onChange={handleOnChange}>
          {props.weaponLinks.map(link => (
            <option key={link.sAPBDB}>{link.sAPBDB}</option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
}

const Maker = ({ weapon, originalWeapon, setWeapon }) => {
  const [editedObject, setEditedObject] = useState(weapon);
  const [openStates, setOpenStates] = useState({});

  // Reset modified values if other link selected in parent
  useEffect(() => {
    setEditedObject(weapon);
  }, [weapon]);

  const handleInputChange = (keyPath, value) => {
    setEditedObject(prevObject => {
      const newObject = { ...prevObject };
      let target = newObject;
  
      // Traverse the object to the second-to-last key
      for (let i = 0; i < keyPath.length - 1; i++) {
        target = target[keyPath[i]];
      }
  
      // Update the last key to the new value
      target[keyPath[keyPath.length - 1]] = value;
  
      return newObject;
    });
  };

  const toggleNestedObject = (keyPath) => {
    setOpenStates(prevOpenStates => {
      const newOpenStates = { ...prevOpenStates };
      newOpenStates[keyPath.join('.')] = !newOpenStates[keyPath.join('.')];
      return newOpenStates;
    });
  };

  const isNestedObjectOpen = (keyPath) => {
    return openStates[keyPath.join('.')] === true;
  };

  const renderValue = (keyPath, value) => {
    // On Enter pressed update Statistics
    const handleInputKeyDown = (e) => {
      if (e.key === 'Enter') {
        setWeapon(editedObject);
      }
    };

    if (typeof value === 'object') {
      // Recurse for nested objects
      return (
        <React.Fragment>
          <tr>
            <td onClick={() => toggleNestedObject(keyPath)}>
              <small>
                {isNestedObjectOpen(keyPath) ? "▶" : "▼"}
              </small>
            </td>
          </tr>
          {isNestedObjectOpen(keyPath) &&
            Object.entries(value).map(([subKey, subValue]) => (
              <tr key={subKey}>
                <td>
                  <small>{subKey}</small>
                </td>
                <td>{renderValue([...keyPath, subKey], subValue)}</td>
              </tr>
            ))}
        </React.Fragment>
      );
    } else {
      return (
        <tr>
          <td>
            <small>
              <input
                id={keyPath.join(".")}
                value={value}
                onKeyDown={(e) => handleInputKeyDown(e, keyPath)}
                onChange={(e) => handleInputChange(keyPath, e.target.value)}
              />
            </small>
          </td>
        </tr>
      );
    }
  };

  return (
    <div>
      <Table size="sm" className="table-lite">
        <tbody>
          {Object.entries(editedObject).map(([key, value]) => (
            <tr key={key}>
              <td><small>{key}</small></td>
              <td>{renderValue([key], value)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}