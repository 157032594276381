import React from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Components
import Statistics from './ItemStatistics';

// Services
import { settingsService } from '../../_services/settings';

// Extras
import utils from '../../_helpers/utils';
import { EMPTY_MOD } from '../../_helpers/constants';
import { ErrorMessage } from '../Extras';

export default class Modding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bypassModding: settingsService.get('site', 'modding'),
      originalDetail: this.props.item.detail,
      detail: this.props.item.detail,
      mods: [],
      presetMods: []
    }

    this.request = utils.request.bind(this);
    this.prevMods = this.prevMods.bind(this);
    this.resetMods = this.resetMods.bind(this);
    this.switchMod = this.switchMod.bind(this);
    this.clickSwitchMod = this.clickSwitchMod.bind(this);
    this.handleModdingClick = this.handleModdingClick.bind(this);
  }

  componentDidMount() {
    this.getMods().then(() => {
      // Set preset mods to ignore for url updates
      let mods = [];

      for (let i = 0; i < (this.props.item.category === 'Vehicle' ? 4 : 3); i++) {
        if (this.modNotNoneOrEmpty(this.state.detail[`eFnMod_${i}`].sAPBDB))
          mods.push(this.state.detail[`eFnMod_${i}`].sAPBDB)
      }

      this.setState({ 
        presetMods: mods, 
        fnmods: this.props.fnmods 
      }, () => this.prevMods());
    })
  }

  getMods() {
    let infracat;
    if (this.props.item.category === 'Weapon') {
      infracat = 'FnModWeapon';
    } else if (this.props.item.category === 'Vehicle') {
      infracat = 'FnModVehicle';
    }

    return this.request(`/items?infracat=${infracat}&limit=500&Detail=true&JokerStore=false`, 'mods', 'items');
  }

  async prevMods() {
    if (!this.state.fnmods) return;

    let mods = this.state.fnmods.split(',');

    for (let m in mods) {
      // search in state mods array for mod
      const mod = this.state.mods.find(mod => mod.sAPBDB === mods[m]);

      // apply to a slot if not null
      if (mod) await this.clickSwitchMod(mod);
    }
  }

  searchModInItem(detail, mod) {
    if (detail.eFnMod_0.sAPBDB === mod || 
        detail.eFnMod_1.sAPBDB === mod || 
        detail.eFnMod_2.sAPBDB === mod) {
      return true;
    }

    // extra mod check
    try {
      if (detail.eFnMod_3.sAPBDB === mod) return true;
    } catch (TypeError) {}
    return false;
  }

  async clickSwitchMod(mod) {
    if (this.checkRestrictions(this.state.detail.eFnMod_0.sAPBDB)) {
      this.switchMod('eFnMod_0', mod);
    } else if (this.checkRestrictions(this.state.detail.eFnMod_1.sAPBDB)) {
      this.switchMod('eFnMod_1', mod);
    } else if (this.checkRestrictions(this.state.detail.eFnMod_2.sAPBDB)) {
      this.switchMod('eFnMod_2', mod);
    } else if (this.props.item.category === 'Vehicle') {
      if (this.checkRestrictions(this.state.detail.eFnMod_3.sAPBDB)) {
        this.switchMod('eFnMod_3', mod);
      }
    }
  }

  switchMod(state, mod) {
    this.setState(prevState => {
      let detail = Object.assign({}, prevState.detail);
      detail[state] = mod.detail;
      return { detail };
    }, () => this.updateUrlMods())
  }

  updateUrlMods() {
    let mods = [];

    for (let i = 0; i < (this.props.item.category === 'Vehicle' ? 4 : 3); i++) {
      let sAPBDB = this.state.detail[`eFnMod_${i}`].sAPBDB;

      if (this.modNotNoneOrEmpty(sAPBDB) && !this.state.presetMods.includes(sAPBDB))
        mods.push(sAPBDB)
    }
    
    this.props.updateUrl('mods', mods.toString());
  }

  checkRestrictions(mod) {
    return mod === EMPTY_MOD || (mod === 'Mod_None' && this.state.bypassModding);
  }

  checkPreset(mod) {
    return mod !== EMPTY_MOD;
  }

  modNotNoneOrEmpty(mod) {
    return mod !== EMPTY_MOD && mod !== 'Mod_None';
  }

  resetMods() {
    this.setState({detail: this.state.originalDetail}, () => this.updateUrlMods());
  }

  handleModdingClick() {
    settingsService.set('site', 'modding', true);
    this.setState({ bypassModding: true });
  }

  render() {
    // Display a warning when modding preset or no open slot items
    if (!this.searchModInItem(this.state.originalDetail, EMPTY_MOD) && !this.state.bypassModding) {
      return (
        <div className="text-center m-5">
          <h4>Modding Restrictions</h4>
          <p>
            You are about to modify an item that has no open modification slots. 
            <br />Are you sure you want to continue?
          </p>
          <Button variant="secondary" onClick={this.handleModdingClick}>
            I know what I am doing!
          </Button>
        </div>
      )
    }

    if (this.state.modsError) {
      return <ErrorMessage noVCenter error={this.state.modsError} />;
    }

    return (
      <Row noGutters className="modding-tab">
        <Col md={6}>
          <Statistics 
            cols={12} 
            detail={this.state.detail}
            category={this.props.item.category} />
        </Col>
        <Col md={6}>
          <DndProvider backend={HTML5Backend}>
            <Row>
              <Col md={12} className="text-right">
                <small className="reset" onClick={this.resetMods}>
                  <i className="fas fa-undo" /> Reset
                </small>
              </Col>
              <Col md={12} className="text-center">
                {this.state.detail.eFnMod_0 && 
                  <FnModDroppable 
                    mod={this.state.detail.eFnMod_0} 
                    isPreset={this.checkPreset(this.state.originalDetail.eFnMod_0.sAPBDB)}
                    order={0} />}
                {this.state.detail.eFnMod_1 && 
                  <FnModDroppable 
                    mod={this.state.detail.eFnMod_1} 
                    isPreset={this.checkPreset(this.state.originalDetail.eFnMod_1.sAPBDB)}
                    order={1} />}
                {this.state.detail.eFnMod_2 && 
                  <FnModDroppable 
                    mod={this.state.detail.eFnMod_2} 
                    isPreset={this.checkPreset(this.state.originalDetail.eFnMod_2.sAPBDB)}
                    order={2} />}
                {this.state.detail.eFnMod_3 && 
                  <FnModDroppable 
                    mod={this.state.detail.eFnMod_3} 
                    isPreset={this.checkPreset(this.state.originalDetail.eFnMod_3.sAPBDB)}
                    order={3} />}
              </Col>
              <Col md={12}>
                {this.state.bypassModding &&
                <p className='text-danger text-center'>
                  Restrictions disabled
                </p>
                }
              </Col>
              <Col md={12}>
                {this.state.modsLoaded ? 
                  <>
                    {this.state.mods.map(mod => (
                      <FnModDraggable 
                        key={mod.sAPBDB} 
                        mod={mod} 
                        switch={this.switchMod}
                        clickSwitchMod={this.clickSwitchMod}
                        bypass={this.state.bypassModding} />
                    ))}
                  </>
                  : utils.loadingCircle(true)
                }
              </Col>
            </Row>
          </DndProvider>
        </Col>
      </Row>
    );
  }
}

const FnModDroppable = props => {
  const style = {
    height: '64px',
    width: '64px',
    marginRight: '1.5rem',
    marginBottom: '3rem',
    display: 'inline-block',
    backgroundColor: 'black',
    backgroundImage: `url(${props.mod.eInventoryItemType.eHUDImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'FnMod',
    drop: () => ({ 
      name: `eFnMod_${props.order}`,  
      sapbdb: props.mod.sAPBDB,
      isPreset: props.isPreset }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  const isActive = canDrop && isOver
  let filter;
  if (!props.isPreset) {
    if (isActive) {
      filter = 'brightness(175%)'
    } else if (canDrop) {
      filter = 'brightness(125%)'
    }
  }
  return (
    <div 
      ref={drop} 
      style={{ ...style, filter }} />
  )
}
  
const FnModDraggable = props => {
  const style = {
    height: '64px',
    width: '64px',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
    backgroundColor: 'black',
    backgroundImage: `url(${props.mod.eHUDImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }

  const [{ isDragging }, drag] = useDrag({
    type: 'FnMod',
    item: { name: props.mod.sDisplayName, type: 'FnMod' },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        if (!dropResult.isPreset || props.bypass) {
          props.switch(dropResult.name, props.mod);
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
  })

  // alter opacity when dragging
  const opacity = isDragging ? 0.4 : 1

  // on mouse up => add into next free mod slot
  const handleMouseUp = () => {
    props.clickSwitchMod(props.mod);
  }

  // TODO: make overlay component external
  // overlay with mod description
  const placement = 'auto';
  const overlay = (
    <Tooltip id={`tooltip-${props.mod.sAPBDB}`}>
      <b>{props.mod.sDisplayName}</b>
      {props.mod.detail.eModifierItem.aModifierEffects.map(effect => (
        <p
          key={`${effect.sAPBDB}-${effect.id}`} 
          className='mb-0' 
          dangerouslySetInnerHTML={{__html: utils.convertColorCode(effect.sDescription)}} />
      ))}
    </Tooltip>
  );

  return (
    <OverlayTrigger key={placement} placement={placement} overlay={overlay}>
      <div 
        ref={drag} 
        onMouseUp={handleMouseUp} 
        style={{ ...style, opacity }} />
    </OverlayTrigger>
  )
}
