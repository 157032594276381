import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Components
import Navigation from './components/Navigation';
import Home from './components/Home';
import AdminDashboard from './components/Admin/Dashboard/Dashboard';
import { Login, Register } from './components/Auth';

import Items from './components/Items';

import { District, Districts, Faction, Organisation }  from './components/SanParo';
import { Achievements, Contact, Contacts, Heat, Progression, Rating, Roles 
       } from './components/Progression';
import { Missions, Minigame, Minigames, Medals } from './components/Missions';
import { About, Changelog, Commands, Privacy, Tracker, Other } from './components/Other';
import { ErrorMessage } from './components/Extras';

// Services
import { authService } from './_services/auth';

// Styles
import 'simplebar-react/dist/simplebar.min.css';

// Seasonal
import { tomfoolery } from './_helpers/tomfoolery'; tomfoolery();

export default class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = { user: false }
  }

  componentDidMount() {
    authService.checkAccessExpired().then(() => {
      this.setState({ user: authService.getUser() });  
    })
  }

  login = () => {
    this.setState({ user: authService.getUser() });
  }

  logout = () => {
    authService.logout();
    this.setState({ user: null });
  }

  render() {
    const value = {
      user: this.state.user,
      login: this.login,
      logout: this.logout
    }

    return (
      <authService.userContext.Provider value={value}>
        <BrowserRouter>
          <Navigation />
          <Switch>
            <Route path='/' component={Home} exact />
            <ProtectedRoute path='/admin' component={AdminDashboard} exact />
            <Route path='/login' component={Login} exact />
            <Route path='/register' component={Register} exact />

            {/* Items */}
            <Route path='/items' component={Items} exact />
            <Route path='/items/:sapbdb' component={Items} exact />
            <Route path='/items/:sapbdb/modding' component={Items} exact />
            <Route path='/items/:sapbdb/compare' component={Items} exact />
            <Route path='/items/:sapbdb/make' component={Items} exact />
            <Route path='/items/:sapbdb/changes' component={Items} exact />

            {/* San Paro */}
            <Route path='/districts' component={Districts} exact />
            <Route path='/districts/:sapbdb' component={District} exact />
            <Route path='/factions/:sapbdb' component={Faction} exact />
            <Route path='/organisations/:sapbdb' component={Organisation} exact />
            
            {/* Progression */}
            <Route path='/progression' component={Progression} exact />
            <Route path='/achievements' component={Achievements} exact />
            <Route path='/achievements/:sapbdb' component={Achievements} />
            <Route path='/contacts' component={Contacts} exact />
            <Route path='/contacts/:sapbdb' component={Contact} />
            <Route path='/heat' component={Heat} exact />
            <Route path='/rating' component={Rating} exact />
            <Route path='/roles' component={Roles} exact />
            <Route path='/roles/:sapbdb' component={Roles} />

            {/* Missions */}
            <Route path='/missions' component={Missions} exact />
            <Route path='/missions/:sapbdb' component={Missions} />
            <Route path='/minigames' component={Minigames} exact />
            <Route path='/minigames/:sapbdb' component={Minigame} />
            <Route path='/medals' component={Medals} exact />

            {/* Other */}
            <Route path='/tracker' component={Tracker} exact />
            <Route path='/tracker/:id' component={Tracker} />
            <Route path='/changelog' component={Changelog} exact />
            <Route path='/changelog/:id' component={Changelog} />
            <Route path='/commands' component={Commands} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/about' component={About} />
            <Route path='/other' component={Other} />

            <Route component={() => <ErrorMessage code={404}>
                                      Nothing here..
                                    </ErrorMessage>} />
          </Switch>
        </BrowserRouter>
      </authService.userContext.Provider>
    );
  }
}


const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    authService.isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)