import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Components
import AmmoModal from './AmmoModal';
import { SmallTable } from '../Extras';
import { DamageDropOffChart } from './ChartDamageDropOff';
import { PelletDamageChart } from './ChartPelletDamage';
import { VehicleTorqueChart } from './ChartVehicleTorque';

// Utilities
import utils from '../../_helpers/utils';
import compare from '../../_helpers/compare';
import WeaponCalculation from '../../_helpers/calc';

// Services
import { settingsService } from '../../_services/settings';

export default class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.cols = this.props.cols ? this.props.cols : 6;

    this.initialize(this.props.detail);
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.detail !== nextProps.detail || this.props.weaponLink !== nextProps.weaponLink) {
      this.initialize(nextProps.detail, nextProps.weaponLink);
      return true;
    }
    return false;
  }

  initialize(detail, weaponLink=false) {
    let modifiers = [];

    // Categories
    switch(this.props.category) {
      case 'Weapon':
        // Either use forced weaponLink id or select default
        if (weaponLink) {
          this.weapon = Object.create(weaponLink);
        } else {
          this.weapon = Object.create(detail.eWeaponTypeLink.eWeaponType_0)
        }
                
        if (this.weapon.eRangedWeaponType !== undefined) {
          this.ranged = Object.create(this.weapon.eRangedWeaponType);

          // Weapon Curve overrides
          if (this.weapon.eWeaponCurve) {
            if (typeof(this.weapon.eWeaponCurve.sEffectiveRange) !== 'string') {
              this.ranged.fRampDistance = this.weapon.eWeaponCurve.sEffectiveRange.start * 10000;
              this.ranged.fMinDamageRange = this.weapon.eWeaponCurve.sEffectiveRange.end * 10000;
              this.ranged.fMinimumDamagePercentage = this.weapon.eWeaponCurve.sEffectiveRange.distribution[this.weapon.eWeaponCurve.sEffectiveRange.distribution.length-1] * 100;
            }
          }
        }
        if (this.weapon.eWeaponProjectile !== 0) {
          this.projectile = Object.create(this.weapon.eWeaponProjectile);
        }

        // Modifier Effects from FnMods
        modifiers = this.combineModifierEffects(detail);
        if (modifiers.length > 0) {
          this.weapon_modified = this.calculateModifiedStats(
            Object.create(this.weapon), modifiers);

          if (this.ranged) {
            this.ranged_modified = this.calculateModifiedStats(
              Object.create(this.ranged), modifiers);
          }

          if (this.projectile) {
            this.projectile_modified = this.calculateModifiedStats(
              Object.create(this.projectile), modifiers);
          }
        }

        // Difference objects
        if (this.props.diff) {
          this.weapon_diff = this.props.diff.eWeaponTypeLink.eWeaponType_0;
          this.ranged_diff = this.weapon_diff.eRangedWeaponType;
          this.projectile_diff = this.weapon_diff.eWeaponProjectile;
          
          // Modifier Effects for difference object
          let modifiers_diff = this.combineModifierEffects(this.props.diff);
          if (modifiers_diff.length > 0) {
            this.weapon_diff = this.calculateModifiedStats(
              Object.create(this.weapon_diff), modifiers_diff);

            if (this.ranged_diff) {
              this.ranged_diff = this.calculateModifiedStats(
                Object.create(this.ranged_diff), modifiers_diff);
            }

            if (this.projectile_diff) {
              this.projectile_diff = this.calculateModifiedStats(
                Object.create(this.projectile_diff), modifiers_diff);
            }
          }
        }
        
        // Projectiles / Explosions objects
        if (this.projectile) {
          if (this.projectile.eExplosion) {
            this.explosion = this.projectile.eExplosion;
            
            if (this.projectile_diff) {
              this.explosion_diff = this.projectile_diff.eExplosion;
            }
          }
        }
        break;
      case 'Vehicle':
        this.vehicle = Object.create(detail.eVehicle);
        this.explosion = Object.create(this.vehicle.eExplosionType);

        // Modifier Effects from FnMods
        modifiers = this.combineModifierEffects(detail);
        if (modifiers.length > 0) {
          this.vehicle_modified = this.calculateModifiedStats(
            Object.create(this.vehicle), modifiers);

          this.explosion_modified = this.calculateModifiedStats(
            Object.create(this.explosion), modifiers);
        }

        // Difference objects
        if (this.props.diff) {
          this.vehicle_diff = this.props.diff.eVehicle;
          this.explosion_diff = this.vehicle_diff.eExplosionType;

          let modifiers_diff = this.combineModifierEffects(this.props.diff);
          if (modifiers_diff.length > 0) {
            this.vehicle_diff = this.calculateModifiedStats(
              Object.create(this.vehicle_diff), modifiers_diff);

            this.explosion_diff = this.calculateModifiedStats(
              Object.create(this.explosion_diff), modifiers_diff);
          }
        }
        break;
      case 'UsableToken':
      case 'FnMod':
        this.effects = detail.eModifierItem.aModifierEffects;
        break;
      default:
        break;
    }
  }

  combineModifierEffects(detail) {
    let effects = [];

    Object.entries(detail).forEach(([key, value]) => {
      // Check if key is FnMod
      if (key.startsWith('eFnMod_')) {
        let fnmod = detail[key];
        // Check if ModifierEffects is not empty
        if (fnmod.eModifierItem.aModifierEffects.length > 0) {
          // combine arrays
          effects = effects.concat(fnmod.eModifierItem.aModifierEffects);  
        }
      }
    })

    return effects;
  }

  roundNumber(number, decimals) {
    var newnumber = Number(number+'').toFixed(parseInt(decimals));
    return parseFloat(newnumber); 
  }

  calculateModifiedStats(obj, modifiers) {
    this.props.detail.aModifierTypeLinks.forEach(link => {
      // get correct variable name
      let variable;
      let variable_MarksmanshipOnly;

      for (var name in obj) {
        if (name.endsWith(link.sVariable)) variable = name;

        // Check if _MarksmanshipOnly 
        if (link.sVariable.endsWith('_MarksmanshipOnly')) {

          if (name.endsWith(link.sVariable.replace('_MarksmanshipOnly', ''))) {
            variable = name;
            variable_MarksmanshipOnly = link.sVariable;
          // Workaround for "FiringState" and "FireState" - thanks devs
          } else if (link.sVariable.startsWith('FireState') && name.endsWith('FiringState')) {
            variable = name;
            variable_MarksmanshipOnly = link.sVariable;
          }
        }
      }

      // Check if variable exists and apply effects to obj
      if (variable) {
        let effects = modifiers.filter(x => x.eEffectType === link.eModifierType);

        if (effects.length) {
          effects.forEach(effect => {
            // Workaround for fMaxSpeed addition too high
            if (variable === 'fMaxSpeed') effect.fAddToResult /= 10;

            obj[variable_MarksmanshipOnly ? variable_MarksmanshipOnly : variable] = this.roundNumber(
              ((obj[variable] * effect.fEffectMultiplier) + effect.fAddToResult), 12);
          })
        }
      }
    })

    // Modify certain variables after calculation
    Object.entries(obj).forEach(([key, value]) => {
      if (key === 'nMagazineCapacity' || key === 'nAmmoPoolCapacity') {
        // Round ammo vars to no decimals
        obj[key] = this.roundNumber(value, 0);
      }
      // TODO: Range check not higher than 100 not lower than 0

      // Any value below zero = 0
      if (value < 0) obj[key] = 0;
    })

    return obj;
  }

  debugObject(obj) {
    let newObject = {};

    for (let key in obj) {
      if (typeof obj[key] !== 'object') {
        newObject[key] = obj[key]
      }
    }

    return newObject;
  }

  render() {
    return (
      <Row>
        <Col md={12} lg={this.cols}>
        {this.weapon && 
          <StatsCalculated 
            weapon={this.weapon} 
            weapon_diff={this.weapon_diff}
            weapon_modified={this.weapon_modified} />
        }
        {this.ranged && this.weapon &&
        <>
          <StatsDamage 
            weapon={this.weapon} 
            ranged={this.ranged}
            weapon_diff={this.weapon_diff}
            ranged_diff={this.ranged_diff}
            weapon_modified={this.weapon_modified}
            ranged_modified={this.ranged_modified} />
          <StatsOther
            weapon={this.weapon} 
            diffs={this.weapon_diff}
            modified={this.weapon_modified} />
        </>
        }
        {this.vehicle &&
        <>
          <StatsVehicle 
            vehicle={this.vehicle} 
            vehicle_diff={this.vehicle_diff}
            vehicle_modified={this.vehicle_modified} />
          <StatsVehicleSteering 
            vehicle={this.vehicle} 
            vehicle_diff={this.vehicle_diff}
            vehicle_modified={this.vehicle_modified} />
        </>
        }
        {this.explosion && 
          <StatsExplosion 
            explosion={this.explosion}
            explosion_diff={this.explosion_diff}
            explosion_modified={this.explosion_modified} />
        }
        {!!this.projectile && 
          <StatsProjectile 
            weapon={this.weapon} 
            weapon_diff={this.weapon_diff}
            weapon_modified={this.weapon_modified}
            projectile={this.projectile}
            projectile_diff={this.projectile_diff}
            projectile_modified={this.projectile_modified} />
        }
        </Col>
        <Col sm={12} lg={this.cols}>
        {this.ranged && 
          <StatsAccuracy 
            ranged={this.ranged} 
            ranged_diff={this.ranged_diff}
            ranged_modified={this.ranged_modified} />
        }
        {this.weapon && 
        <>
          <StatsMovement 
            weapon={this.weapon}
            weapon_diff={this.weapon_diff}
            weapon_modified={this.weapon_modified} />
          <StatsAmmo
            weapon={this.weapon} 
            diffs={this.weapon_diff}
            modified={this.weapon_modified} />
        </>
        }
        {this.ranged && 
          <StatsPenetration 
            ranged={this.ranged} 
            ranged_diff={this.ranged_diff}
            ranged_modified={this.ranged_modified} />
        }
        {this.vehicle && 
        <>
          <StatsVehicleCategory vehicle={this.vehicle} />
          <VehicleTorqueChart vehicle={this.vehicle} />
        </>
        }
        </Col>
        {settingsService.get('site', 'debug') && 
        <Col as={Row} sm={12}>
          {this.weapon &&
          <>
            <Col sm={12} lg={this.cols}>
              <SmallTable 
                color="text-warning" 
                title="WEAPONITEMTYPE" 
                rows={this.debugObject(this.weapon)} />
            </Col>
          {!!this.weapon.eWeaponCurve &&
            <Col sm={12} lg={this.cols}>
              <SmallTable 
                reduced
                color="text-warning" 
                title="WEAPONCURVE" 
                rows={this.debugObject(this.weapon.eWeaponCurve)} />
            </Col>
            }
          </>
          }
          {this.ranged &&
          <Col sm={12} lg={this.cols}>
            <SmallTable 
              color="text-warning" 
              title="RANGEDWEAPONTYPE" 
              rows={this.debugObject(this.ranged)} />
          </Col>
          }
          {this.projectile &&
          <Col sm={12} lg={this.cols}>
            <SmallTable 
              color="text-warning" 
              title="PROJECTILE" 
              rows={this.debugObject(this.projectile)} />
          </Col>
          }
          {this.explosion &&
          <Col sm={12} lg={this.cols}>
            <SmallTable 
              color="text-warning" 
              title="EXPLOSIONS" 
              rows={this.debugObject(this.explosion)} />
          </Col>
          }
          {this.vehicle &&
          <Col sm={12} lg={this.cols}>
            <SmallTable 
              color="text-warning" 
              title="VEHICLEITEMTYPE" 
              rows={this.debugObject(this.vehicle)} />
          </Col>
          }
        </Col>
        }
      </Row>
    )
  }
}
  
class StatsCalculated extends React.Component{
  constructor(props) {
    super(props);

    this.calculated = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon !== this.props.weapon 
        || prevProps.weapon_modified !== this.props.weapon_modified) {
      this.update();
    }
  }

  update() {
    this.calculated = this.createCalculated(new WeaponCalculation(this.props.weapon), this.props.weapon);

    // Modified object
    if (this.props.weapon_modified) {
      this.modified = this.createCalculated(new WeaponCalculation(this.props.weapon_modified), this.props.weapon_modified);
    }

    // Compare differences
    if (this.props.weapon_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.calculated, 
        this.createCalculated(new WeaponCalculation(this.props.weapon_diff), this.props.weapon_diff));
    }

    this.forceUpdate();
  }  

  createCalculated(calc, weapon) {
    let calculated = {
      'Time To Kill': `${utils.fixDecimals(calc.healthTtk)} sec`,
      'Shots To Kill': `${calc.healthStk}`
    }

    // Check if weapon is less than lethal (can stun players)
    if (weapon.bLessLethal) {
      calculated['Time To Stun'] = `${utils.fixDecimals(calc.staminaTtk)} sec`;
      calculated['Shots To Stun'] = `${calc.staminaStk}`;
    }

    return calculated;
  }

  render() {
    return (
      <SmallTable 
        title='CALCULATED' 
        rows={this.calculated} 
        diffs={this.diffs}
        modified={this.modified} />
    );
  }
}
  
class StatsDamage extends React.Component {
  constructor(props) {
    super(props);

    this.damage = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon !== this.props.weapon 
        || prevProps.ranged !== this.props.ranged
        || prevProps.weapon_modified !== this.props.weapon_modified
        || prevProps.ranged_modified !== this.props.ranged_modified) {
      this.update();
    }
  }

  update() {
    this.damage = this.createDamage(this.props.weapon, this.props.ranged);

    // Modified object
    if (this.props.weapon_modified) {
      this.modified = this.createDamage(this.props.weapon_modified, this.props.ranged_modified);
    }

    // Compare differences
    if (this.props.weapon_diff && this.props.ranged_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.damage, 
        this.createDamage(this.props.weapon_diff, this.props.ranged_diff));
    }

    this.forceUpdate();
  }

  createDamage(weapon, ranged) {
    // Damage
    let damage = {
      'Health Damage': `${weapon.fHealthDamage}`,
      'Stamina Damage': `${weapon.fStaminaDamage}`,
      'Hard Damage': `${utils.calculateHardDamage(weapon.fHealthDamage, weapon.fHardDamageModifier)}`,
    }

    // Shotgun values
    if (ranged.nRaysPerShot > 1) {
      damage['Per Ray Damage Scale'] = ranged.fPerRayDamageScale;
      damage['Max Health Damage'] = utils.calcScaleDamage(
        weapon.fHealthDamage, 
        ranged.nRaysPerShot, 
        ranged.fPerRayDamageScale);
      damage['Max Stamina Damage'] = utils.calcScaleDamage(
        weapon.fStaminaDamage, 
        ranged.nRaysPerShot, 
        ranged.fPerRayDamageScale);
      damage['Max Hard Damage'] = utils.calcScaleDamage(
        utils.calculateHardDamage(
          weapon.fHealthDamage, 
          weapon.fHardDamageModifier), 
        ranged.nRaysPerShot, 
        ranged.fPerRayDamageScale);
    }

    // Range values
    let dropoffRange = ranged.fRampDistance / 100;
    let minDamageRange = (ranged.fMinDamageRange + this.props.ranged.fRampDistance) / 100;

    // Workaround for weapon curves
    if (weapon.eWeaponCurve.sEffectiveRange && typeof(weapon.eWeaponCurve.sEffectiveRange) !== 'string') {
      minDamageRange = ranged.fMinDamageRange / 100;
    }

    // Check if range values exceed MaxRange
    if (dropoffRange > ranged.fMaxRange / 100) dropoffRange = ranged.fMaxRange / 100;
    if (minDamageRange > ranged.fMaxRange / 100) minDamageRange = ranged.fMaxRange / 100;

    damage['Dropoff Range'] = `${dropoffRange} m`;
    damage['Min Damage Range'] = `${minDamageRange} m`;
    damage['Max Range'] = `${ranged.fMaxRange / 100} m`;
    damage['Minimum Damage %'] = `${ranged.fMinimumDamagePercentage} %`;

    // Add burst shots / shotgun rays
    if (weapon.nBurstShots > 1 && weapon.eWeaponFiringState === 3) {
      let burstShots = ` x ${weapon.nBurstShots}`;
      damage['Health Damage'] += burstShots;
      damage['Stamina Damage'] += burstShots;
      damage['Hard Damage'] += burstShots;
    } else if (ranged.nRaysPerShot > 1) {
      let rays = ` x ${ranged.nRaysPerShot}`;
      damage['Health Damage'] += rays;
      damage['Stamina Damage'] += rays;
      damage['Hard Damage'] += rays;
    }

    // Marksmanship only 
    if (weapon.nBurstShots > 1 && weapon.FireState_MarksmanshipOnly === 3) {
      damage['Marksman Health Damage'] = `${damage['Health Damage']} x ${weapon.nBurstShots}`;
      damage['Marksman Stamina Damage'] = `${damage['Stamina Damage']} x ${weapon.nBurstShots}`;
      damage['Marksman Hard Damage'] = `${damage['Hard Damage']} x ${weapon.nBurstShots}`;
    }

    return damage;
  }

  render() {
    return (
      <>
        <SmallTable 
          title='DAMAGE' 
          rows={this.damage} 
          diffs={this.diffs} 
          modified={this.modified} />
        <DamageDropOffChart 
          weapon={this.props.weapon} 
          ranged={this.props.ranged}
          weapon_modified={this.props.weapon_modified}
          ranged_modified={this.props.ranged_modified} />
        {this.props.ranged.nRaysPerShot > 1 && 
          <PelletDamageChart
            weapon={this.props.weapon} 
            ranged={this.props.ranged} 
            weapon_modified={this.props.weapon_modified}
            ranged_modified={this.props.ranged_modified} />}
      </>
    )
  }
}

class StatsPenetration extends React.Component {
  constructor(props) {
    super(props);

    this.penetration = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ranged !== this.props.ranged 
        || prevProps.ranged_modified !== this.props.ranged_modified) {
      this.update();
    }
  }

  update() {
    this.penetration = this.createPenetration(this.props.ranged);

    // Modified object
    if (this.props.ranged_modified) {
      this.modified = this.createPenetration(this.props.ranged_modified);
    }

    // Compare differences
    if (this.props.ranged_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.penetration, 
        this.createPenetration(this.props.ranged_diff));
    }

    this.forceUpdate();
  }

  createPenetration(ranged) {
    let penetration = {
      'Max Pierce Count': ranged.nMaxPierceCount,
      'Pierce Damage Reduction': ranged.fPierceDamageReduction,
      'Pierce Damage Scale': ranged.fPierceDamageScale
    }

    return penetration;
  }

  render() {
    return (
      <SmallTable 
        title='PENETRATION' 
        rows={this.penetration} 
        diffs={this.diffs}
        modified={this.modified} />
    )
  }
}

class StatsVehicle extends React.Component {
  constructor(props) {
    super(props);

    this.vehicle = this.createVehicle(this.props.vehicle);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle_modified !== this.props.vehicle_modified) {
      this.update();
    }
  }

  update() {
    // Modified object
    if (this.props.vehicle_modified) {
      this.modified = this.createVehicle(this.props.vehicle_modified);
    }

    // Compare differences
    if (this.props.vehicle_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.vehicle, 
        this.createVehicle(this.props.vehicle_diff));
    }
    this.forceUpdate();
  }

  createVehicle(vehicle) {
    return {
      'Max Health': vehicle.nMaxHealth,
      'Max Speed': `${vehicle.fMaxSpeed} m/s`,
      'Max Reverse Speed': `${vehicle.fMaxReverseSpeed} m/s`,
      'Max Repair Time': `${vehicle.fMaxRepairTimeSecs} sec`,
      'Drive Type': this.getDriveType(vehicle.eDriveType),
      'Seats': vehicle.bHasRearSeats ? 4 : 2,
      'Spawn Cost': `$${vehicle.nSpawnCost}`,
      'Cargo Capacity': vehicle.nMainCargoPipCapacity,
      'Cargo Torque Reduction Factor': vehicle.fCargoTorqueReductionFactor,
      'Chassis Torque Factor': vehicle.fChassisTorqueFactor,
      'Engine Braking Factor': vehicle.fEngineBrakingFactor,
      'Ramming Multiplier': vehicle.fRamraidDamageMultiplier,
      'Collision Multiplier': vehicle.fCollisionDamage,
      'Idle RPM': vehicle.fIdleRPM,
      'Redline RPM': vehicle.fRedlineRPM
    }
  }

  getDriveType(type) {
    switch(type) {
      case 0:
        return 'RWD';
      case 1:
        return 'FWD';
      case 2:
        return 'AWD'
      default:
        return type;
    }
  }

  render() {
    return (
      <SmallTable title='VEHICLE' 
                  rows={this.vehicle} 
                  diffs={this.diffs}
                  modified={this.modified} />
    );
  }
}

class StatsVehicleCategory extends React.Component {
  constructor(props) {
    super(props);

    this.category = this.createCategory(this.props.vehicle.eVehicleCategory);
  }

  createCategory(category) {
    return {
      'Name': category.sAPBDB,
      'Length': `${category.fMaxLength} cm`,
      'Width': `${category.fMaxWidth} cm`,
      'Height': `${category.fMaxHeight} cm`
    }
  }

  render() {
    return (
      <SmallTable title='VEHICLE CATEGORY' 
                  rows={this.category} 
                  diffs={this.diffs}
                  modified={this.modified} />
    );
  }
}

class StatsVehicleSteering extends React.Component {
  constructor(props) {
    super(props);

    this.steering = this.createSteering(this.props.vehicle);
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicle_modified !== this.props.vehicle_modified) {
      this.update();
    }
  }

  update() {
    // Modified object
    if (this.props.vehicle_modified) {
      this.modified = this.createSteering(this.props.vehicle_modified);
    }

    // Compare differences
    if (this.props.vehicle_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.steering, 
        this.createSteering(this.props.vehicle_diff));
    }
    this.forceUpdate();
  }

  createSteering(vehicle) {
    return {
      'Steer Speed': vehicle.fSteerSpeed,
      'Steer Acceleration': vehicle.fSteerAccel,
      '0 m/s Steer Angle': `${vehicle.f0msSteerAngle} °`,
      '12 m/s Steer Angle': `${vehicle.f12msSteerAngle} °`,
      '22 m/s Steer Angle': `${vehicle.f22msSteerAngle} °`
    }
  }

  render() {
    return (
      <SmallTable title='STEERING' 
                  rows={this.steering} 
                  diffs={this.diffs}
                  modified={this.modified} />
    );
  }
}
  
export class StatsExplosion extends React.Component {
  constructor(props) {
    super(props);

    this.explosion = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.explosion !== this.props.explosion 
        || prevProps.explosion_modified !== this.props.explosion_modified) {
      this.update();
    }
  }

  update() {
    this.explosion = this.createExplosion(this.props.explosion);

    // Modified object
    if (this.props.explosion_modified) {
      this.modified = this.createExplosion(this.props.explosion_modified);
    }

    // Compare differences
    if (this.props.explosion_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.explosion, 
        this.createExplosion(this.props.explosion_diff));
    }

    this.forceUpdate();
  }

  createExplosion(explosion) {
    return {
      'Max Health Damage': explosion.nDamage,
      'Max Stamina Damage': explosion.nStunDamage,
      'Max Hard Damage': utils.calculateHardDamage(explosion.nDamage, explosion.fHardDamageModifier),
      'Max Damage Radius': `${explosion.fGroundZeroRadius} cm`,
      'Radius': `${explosion.fExplosionRadius} cm`,
    }
  }

  render() {
    return (
      <SmallTable 
        title='EXPLOSION' 
        rows={this.explosion} 
        diffs={this.diffs}
        modified={this.modified} />
    );
  }
}

class StatsProjectile extends React.Component{
  constructor(props) {
    super(props);

    this.projectile = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectile !== this.props.projectile
        || prevProps.weapon !== this.props.weapon
        || prevProps.projectile_modified !== this.props.projectile_modified) {
      this.update();
    }
  }

  update() {
    this.projectile = this.createProjectile(this.props.projectile, this.props.weapon);

    // Modified object
    if (this.props.projectile_modified) {
      this.modified = this.createProjectile(this.props.projectile_modified, this.props.weapon_modified);
    }

    // Compare differences
    if (this.props.projectile_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.projectile, 
        this.createProjectile(this.props.projectile_diff, this.props.weapon_diff));
    }

    this.forceUpdate();
  }

  createProjectile(projectile, weapon) {
    let p = {
      'Arming Timer': `${projectile.fArmingTimer} sec`,
      'Detonated On Impact': utils.displayTrueFalse(projectile.bImpactDetonated),
      'Fuse Delay': `${projectile.fFuseDelay} sec`,
      'Gravity Multiplier': projectile.fGravityMultiplier,
    }

    if (weapon.GrenadeWeaponType) {
      p['Speed'] = `${weapon.GrenadeWeaponType.fFiringSpeed / 100} m/s`;
      p['Affected By Gravity'] = utils.displayTrueFalse(weapon.GrenadeWeaponType.bAffectedByGravity);
    }

    return p;
  }

  render() {
    return (
      <SmallTable 
        title='PROJECTILE' 
        rows={this.projectile} 
        diffs={this.diffs}
        modified={this.modified} />
    );
  }
}
  
class StatsAccuracy extends React.Component {
  constructor(props) {
    super(props);

    this.accuracy = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.ranged !== this.props.ranged 
        || prevProps.ranged_modified !== this.props.ranged_modified) {
      this.update();
    }
  }

  update() {
    this.accuracy = this.createAccuracy(this.props.ranged);

    // Modified object
    if (this.props.ranged_modified) {
      this.modified = this.createAccuracy(this.props.ranged_modified);
    }

    // Compare differences
    if (this.props.ranged_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.accuracy, 
        this.createAccuracy(this.props.ranged_diff));
    }
    this.forceUpdate();
  }

  createAccuracy(ranged) {
    let accuracy = {
      'Accuracy Radius at 10m': `${ranged.fRadiusAtTenMetres} cm`,
      'Spread at 10m': `${ranged.fRaySpreadAtTenMetres} cm`,
      'Marksman Spread at 10m': `${ranged.RaySpreadAtTenMetres_MarksmanshipOnly} cm`,
      'Per Shot Modifier': ranged.fPerShotModifier,
      'Marksman Per Shot Modifier': ranged.PerShotModifier_MarksmanshipOnly,
      'Shot Modifier Cap': ranged.fOverallShotModifierCap,
      'Recovery Delay': `${ranged.fRecoveryDelay} sec`,
      'Marksman Recovery Delay': `${ranged.RecoveryDelay_MarksmanshipOnly} sec`,
      'Recovery Per Second': ranged.fRecoveryPerSecond,
      'Marksman Recovery Per Second': ranged.RecoveryPerSecond_MarksmanshipOnly,
      'Crouch Modifier': ranged.fCrouchModifier,
      'Walk Modifier': ranged.fWalkModifier,
      'Run Modifier': ranged.fRunModifier,
      'Sprint Modifier': ranged.fSprintModifier,
      'Jump Modifier': ranged.fJumpModifier,
      'Lean Modifier': ranged.fLeanModifier,
      'Marksman Modifier': ranged.fMarksmanshipModifier,
      'In Vehicle Modifier': ranged.fInVehicleModifier,
      'Marksman FoV 16:9': ranged.fMarksmanshipFOV16_9,
      'Marksman FoV 4:3': ranged.fMarksmanshipFOV4_3,
    }

    // Remove RaySpread if 0
    if (!ranged.fRaySpreadAtTenMetres) delete accuracy['Spread at 10m'];
    if (!ranged.RaySpreadAtTenMetres_MarksmanshipOnly) delete accuracy['Marksman Spread at 10m'];
    if (!ranged.PerShotModifier_MarksmanshipOnly) delete accuracy['Marksman Per Shot Modifier'];
    if (!ranged.RecoveryDelay_MarksmanshipOnly) delete accuracy['Marksman Recovery Delay'];
    if (!ranged.RecoveryPerSecond_MarksmanshipOnly) delete accuracy['Marksman Recovery Per Second'];

    return accuracy;
  }

  render() {
    return (
      <SmallTable title='ACCURACY' 
                  rows={this.accuracy} 
                  diffs={this.diffs}
                  modified={this.modified} />
    );
  }
}

class StatsOther extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      other: {},
      diffs: null,
      modified: null
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon !== this.props.weapon 
        || prevProps.modified !== this.props.modified) {
      this.update();
    }
  }

  update() {
    this.setState({ other: this.createOther(this.props.weapon) });

    // Modified object
    if (this.props.modified) {
      this.setState({ modified: this.createOther(this.props.modified) });
    }

    // Compare differences
    if (this.props.diffs) {
      this.setState({
        diffs: compare.diff(
          this.state.modified ? this.state.modified : this.state.other, 
          this.createOther(this.props.diffs))
      })
    }
  }

  createOther(weapon) {
    let other = {
      'Equip Time': `${weapon.fEquipTime} sec`,
      'Fire Interval': `${weapon.fFireInterval} sec`,
      'Marksman Fire Interval': `${weapon.FireInterval_MarksmanshipOnly} sec`,
      'Burst Interval': `${weapon.fBurstInterval} sec`,
      'Reload Time': `${weapon.fReloadTime} sec`,
      'Reload End Time': `${weapon.fReloadEndAnimDuration} sec`,
      'Resupply Delay': `${weapon.fResupplyDelaySecs} sec`,
      'Wind-Up Time': `${weapon.fWindUpTime} sec`
    }

    // Remove Burst Interval when not burst weapon and Wind-Up & Reload End Animation if zero
    if (weapon.eWeaponFiringState !== 3) delete other['Burst Interval'];
    if (weapon.fWindUpTime === 0) delete other['Wind-Up Time'];
    if (weapon.bHasReloadEndAnimation === 0) delete other['Reload End Time'];
    if (!weapon.FireInterval_MarksmanshipOnly) delete other['Marksman Fire Interval'];

    return other;
  }

  render() {
    return  (
      <SmallTable 
        title='OTHER' 
        rows={this.state.other} 
        diffs={this.state.diffs} 
        modified={this.state.modified} />
    )
  }
}
  
class StatsAmmo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAmmoModal: false,
      ammo: {},
      diffs: null,
      modified: null
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon !== this.props.weapon 
        || prevProps.modified !== this.props.modified) {
      this.update();
    }
  }

  update() {
    this.setState({ ammo: this.createAmmo(this.props.weapon) });

    // Modified object
    if (this.props.modified) {
      this.setState({ modified: this.createAmmo(this.props.modified) });
    }

    // Compare differences
    if (this.props.diffs) {
      this.setState({ 
        diffs: compare.diff(
          this.state.modified ? this.state.modified : this.state.ammo, 
          this.createAmmo(this.props.diffs)
        ) 
      })
    }
  }

  createAmmo(weapon) {
    let ammo = {
      'Ammo Category': weapon.eAmmoCategory.sName,
      'Ammo Capacity': weapon.nAmmoPoolCapacity,
      'Magazine Capacity': weapon.nMagazineCapacity
    }

    return ammo;
  }

  toggleAmmoModal = () => {
    if (this.state.showAmmoModal) {
      this.setState({ showAmmoModal: false });
    } else {
      this.setState({ showAmmoModal: true });
    }
  }

  render() {
    return (
      <>
        <SmallTable 
          className="mb-0"
          title='AMMO'
          rows={this.state.ammo} 
          diffs={this.state.diffs} 
          modified={this.state.modified} />
        <p className="mb-3 text-right" onClick={this.toggleAmmoModal}>
          <small className="fake-link">Show Ammunition Categories {' > '}</small>
        </p>
        <AmmoModal 
          show={this.state.showAmmoModal} 
          toggle={this.toggleAmmoModal} />
      </>
    );
  }
}
  
class StatsMovement extends React.Component {
  constructor(props) {
    super(props);

    this.movement = {};
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon !== this.props.weapon 
        || prevProps.weapon_modified !== this.props.weapon_modified) {
      this.update();
    }
  }
  
  update() {
    this.movement = this.createMovement(this.props.weapon);

    // Modified object
    if (this.props.weapon_modified) {
      this.modified = this.createMovement(this.props.weapon_modified);
    }

    // Compare differences
    if (this.props.weapon_diff) {
      this.diffs = compare.diff(
        this.modified ? this.modified : this.movement, 
        this.createMovement(this.props.weapon_diff));
    }

    this.forceUpdate();
  }

  createMovement(weapon) {
    let movement = {
      'Jump Height': `${weapon.fJumpZ} cm`,
      'Crouched Speed': `${weapon.fCrouchSpeed} cm/s`,
      'Walk Speed': `${weapon.fWalkSpeed} cm/s`,
      'Marksman Speed': `${weapon.fMarksmanshipSpeed} cm/s`,
      'Run Speed': `${weapon.fRunSpeed} cm/s`,
      'Sprint Speed': `${weapon.fSprintSpeed} cm/s`,
      'Sprint Delay': `${weapon.fSprintDelay} sec`,
      'Marksman Sprint Delay': `${weapon.SprintDelay_MarksmanshipOnly} sec`
    }

    if (!weapon.SprintDelay_MarksmanshipOnly) delete movement['Marksman Sprint Delay'];

    return movement;
  }

  render() {
    return (
      <SmallTable 
        title='MOVEMENT' 
        rows={this.movement} 
        diffs={this.diffs}
        modified={this.modified} />
    );
  }
}