import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, ListGroup, Table, Accordion, Card } from 'react-bootstrap';

// Components
import { WideTemplate, WideHeader, WideDescription } from '../../WideTemplate';
import { ErrorMessage, SmallTable } from '../../Extras';
import { FactionIcon } from '../../Items';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Minigame.scss';

export default class Minigame extends WideTemplate {
  constructor(props) {
    super(props);

    this.state = {}

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/minigames/${this.props.match.params.sapbdb}`, 'minigame');
  }

  render() {
    if (this.state.minigameError) {
      return <ErrorMessage error={this.state.minigameError} />;
    }

    if (this.state.minigameLoaded) {
      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <MetaDecorator title={this.state.minigame.sAPBDB.replaceAll('_', ' - ')} />
          <Row noGutters className="minigame-component">
            <WideHeader 
              title={this.state.minigame.sAPBDB.replaceAll('_', ' - ')} />
            <WideDescription>
              <Description minigame={this.state.minigame} />
              <TypeSpecific minigame={this.state.minigame} />
              <TeamSetup teams={this.state.minigame.eTeamSetup} />
            </WideDescription>
            <Container>
              <Row noGutters>
                {this.state.minigame.eType === 8 &&
                  <GunGameLevels setups={this.state.minigame.detail.eWeaponSetup} />
                }
                <Rewards rewards={this.state.minigame.rewards} />
                <Locations locations={this.state.minigame.locations} />
              </Row>
            </Container>
          </Row>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class Description extends React.Component {
  constructor(props) {
    super(props);

    this.rows = {
      'Introduction Time': `${this.props.minigame.fIntroductionTime} s`,
      'Abandon Time': `${this.props.minigame.fAbandonTimeout} s`,
      'Minimum Player Count': this.props.minigame.nMinimumPlayerCount_0,
      'Score Events for Participation': this.props.minigame.nScoreEventsForParticipation,
      'Minimum Score for Participation': this.props.minigame.nScoreMinimumForParticipation,
      'Hide Default Scoreboard': utils.displayTrueFalse(this.props.minigame.bHideDefaultScoreboard),
      'Teleport To Spawn': utils.displayTrueFalse(this.props.minigame.bTeleportToSpawnOnAdd),
      'Max Bonus Cash': `$${this.props.minigame.constants.DefaultMaxBonusCash.toLocaleString()}`,
      'Cash Reward Per Score Multiplier': `x${this.props.minigame.constants.CashRewardPerScore}`,
      'Max Bonus Standing': this.props.minigame.constants.DefaultMaxBonusStanding.toLocaleString(),
      'Standing Reward Per Score Multiplier': `x${this.props.minigame.constants.StandingRewardPerScore}`,
    }
  }

  render() {
    return (
      <Col md={4}>
        <SmallTable title="GENERAL" rows={this.rows} />
      </Col>
    )
  }
}

const TeamSetup = props => (
  <Col md={4}>
    <h6>TEAMS</h6>
    <ListGroup>
      {props.teams.filter((i) => i.eName.sAPBDB !== "None" && i.eName.sAPBDB !== "Faction").map(team => (
        <ListGroup.Item key={team.eName.sAPBDB}>
          <img 
            className="team-icon"
            src={team.eName.sHeader} 
            alt={team.eName.sDisplayName} 
            onError={utils.handleImageError} />
          <span 
            className="ml-3"
            dangerouslySetInnerHTML={{__html: utils.convertColorCode(team.eName.sDisplayName)}} />
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Col>
)


class TypeSpecific extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }

  componentDidMount() {
    if (this.props.minigame.detail) this.createTS();
  }

  createTS = () => {
    let detail = this.props.minigame.detail;

    switch(this.props.minigame.eType) {
      case 1:  // Block FDM
        this.setState({
          'Min Num Lives': detail.nMinNumLives,
          'Number Of Lives Per Player': detail.nNumberOfLivesPerPlayer,
          'Low Team Count Scale': detail.fLowTeamCountScale,
          'Low Team Initial Timer': `${detail.fLowTeamInitialTimer} s`,
          'Low Team Initial Startup Timer': `${detail.fLowTeamInitialStartupTimer} s`,
          'Out Of Bounds Time': `${detail.fOutOfBoundsTime} s`,
          'Out Of Bounds Participation Timer': `${detail.fOOBParticipationTimer} s`,
          'Count Arrests': utils.displayTrueFalse(detail.bCountArrests),
          'Ignore All Suicides': utils.displayTrueFalse(detail.bIgnoreAllSuicides),
          'Ignore Friendly Kills': utils.displayTrueFalse(detail.bIgnoreFriendlyKills),
          'Ignore GM Suicides': utils.displayTrueFalse(detail.bIgnoreGMSuicides),
          'Ignore Kills Both Out Of Bounds': utils.displayTrueFalse(detail.bIgnoreKillsBothOOB),
          'Ignore Kills By Weapons Other Than Loadout': utils.displayTrueFalse(detail.bIgnoreKillsByWeaponsOtherThanLoadout),
          'Ignore Kills Killed Out Of Bounds': utils.displayTrueFalse(detail.bIgnoreKillsKilledOOB),
          'Ignore Kills Killer Not In Minigame': utils.displayTrueFalse(detail.bIgnoreKillsKillerNotInMinigame),
          'Ignore Kills Killer Out Of Bounds': utils.displayTrueFalse(detail.bIgnoreKillsKillerOOB),
          'Ignore Non-Weapon Explosions': utils.displayTrueFalse(detail.bIgnoreNonWeaponExplosions),
          'Ignore Road Kills': utils.displayTrueFalse(detail.bIgnoreRoadKills),
          'Ignore Suicide Out Of Bounds': utils.displayTrueFalse(detail.bIgnoreSuicideOOB),
          'Ignore Suicide Without Assist': utils.displayTrueFalse(detail.bIgnoreSuicideWithoutAssist),
          'Ignore Suicide Without Enemy Assist': utils.displayTrueFalse(detail.bIgnoreSuicideWithoutEnemyAssist),
          'Ignore Suicide Without Friendly Assist': utils.displayTrueFalse(detail.bIgnoreSuicideWithoutFriendlyAssist)
        })
        break;
      case 3:  // Mug
        this.setState({
          'Total Minigame Timeout Time': `${detail.fTotalMinigameTimeoutTime.toLocaleString()} s`,
          'Inactivity Timeout Time': `${detail.fInactivityTimeoutTime.toLocaleString()} s`,
          'NPC Count': detail.nNPCCount,
          'Max NPC Time': `${detail.fMaxNPCTime.toLocaleString()} s`,
          'NPC Markers After First Mug': utils.displayTrueFalse(detail.bNPCMarkersAfterFirstMug),
          'NPC Mug Uses Immunity Timeout': utils.displayTrueFalse(detail.bNPCMugUsesImmunityTimeout),
          'Kill Mugged Players': utils.displayTrueFalse(detail.bKillMuggedPlayers),
          'Kill NPC When Done': utils.displayTrueFalse(detail.bKillNPCWhenDone),
          'Multi Mug': utils.displayTrueFalse(detail.bMultiMug),
          'Can Mug Players': utils.displayTrueFalse(detail.bCanMugPlayers),
          'Can Drop Off Items': utils.displayTrueFalse(detail.bCanDropOffItems),
          'Oppose All Carriers': utils.displayTrueFalse(detail.bOpposeAllCarriers),
          'Show Carriers To All': utils.displayTrueFalse(detail.bShowCarriersToAll),
          'Hide Player Radar Markers': utils.displayTrueFalse(detail.bHidePlayerRadarMarkers),
        })
        break;
      case 4:  // Golden Gun
        this.setState({
          'Kill Target': detail.nKillTarget,
          'Max Pickup Time': `${detail.fMaxPickupTime.toLocaleString()} s`,
          'Total Minigame Timeout Time': `${detail.fTotalMinigameTimeoutTime.toLocaleString()} s`,
          'Requires Drop Off': utils.displayTrueFalse(detail.bRequiresDropOff)
        })
        break;
      case 7:  // Infection
        this.setState({
          'Total Game Timeout': `${detail.fTotalGameTimeout.toLocaleString()} s`,
          'Infected Majority Timer': `${detail.fInfectedMajorityTimer} s`,
          'Infected Score Multiplier Max': `${detail.fInfectedScoreMultiplierMax}x`,
          'Infected Score Multiplier Min': `${detail.fInfectedScoreMultiplierMin}x`,
          'Protagonist Kill Score Multiplier': `${detail.fProtagonistKillScoreMultiplier}x`,
          'Protagonist Score Multiplier Max': `${detail.fProtagonistScoreMultiplierMax}x`,
          'Protagonist Score Multiplier Min': `${detail.fProtagonistScoreMultiplierMin}x`,
          'Survivor Majority Ratio': `x${detail.fSurvivorMajorityRatio}`,
          'Survivor Ping Duration': `${detail.fSurvivorPingDuration} s`,
          'Survivor Ping Interval': `${detail.fSurvivorPingInterval} s`,
          'Survivor Score Multiplier Max': `${detail.fSurvivorScoreMultiplierMax}x`,
          'Survivor Score Multiplier Min': `${detail.fSurvivorScoreMultiplierMin}x`,
          'Kill All Infected At Stop': utils.displayTrueFalse(detail.bKillAllInfectedAtStop),
          'Kill All Players At Stop': utils.displayTrueFalse(detail.bKillAllPlayersAtStop),
          'Kill Player Becoming Protagonist': utils.displayTrueFalse(detail.bKillPlayerBecomingProtagonist),
          'Leader Becomes Survivor When Killed': utils.displayTrueFalse(detail.bLeaderBecomesSurvivorWhenKilled),
          'Protagonist Pings With Survivors': utils.displayTrueFalse(detail.bProtagonistPingsWithSurvivors),
          'Protagonist Visible During Survivor Majority': utils.displayTrueFalse(detail.bProtagonistVisibleDuringSurvivorMajority),
          'Survivor Becomes Infected On Suicide': utils.displayTrueFalse(detail.bSurvivorBecomesInfectedOnSuicide)
        })
        break;
      case 8:  // Gun Game
        this.setState({
          'Score Limit': `${detail.nScoreLimit.toLocaleString()}`,
          'Total Game Timeout': `${detail.fTotalGameTimeout.toLocaleString()} s`,
          'Out Of Bounds Time': `${detail.fOutOfBoundsTime} s`,
          'Out Of Bounds Participation Timer': `${detail.fOOBParticipationTimer} s`,
          'Dead Participation Timer': `${detail.fDeadParticipationTimer} s`,
          'Min Respawn Distance': `${detail.fMinRespawnDistance} cm`,
          'Allow Score Level Up': utils.displayTrueFalse(detail.bAllowScoreLevelUp),
          'Count Arrests': utils.displayTrueFalse(detail.bCountArrests),
          'Count Assigned Suicides': utils.displayTrueFalse(detail.bCountAssignedSuicides),
          'Count Non-Weapon Kills': utils.displayTrueFalse(detail.bCountNonWeaponKills),
          'Disable Positive Medal Score': utils.displayTrueFalse(detail.bDisablePostitiveMedalScore),
          'Force Last Weapon On Score Limit': utils.displayTrueFalse(detail.bForceLastWeaponOnScoreLimit),
          'Lock Out Of Area': utils.displayTrueFalse(detail.bLockOutOfArea),
          'Multiply Acc Score Changes': utils.displayTrueFalse(detail.bMultiplyAccScoreChanges),
          'Reduce Level On Negative Acc Kills': utils.displayTrueFalse(detail.bReduceLevelOnNegativeAccKills),
          'Require Kill With Final Weapon': utils.displayTrueFalse(detail.bRequireKillWithFinalWeapon),
          'Score Non-Weapon Kills': utils.displayTrueFalse(detail.bScoreNonWeaponKills),
          'Spawn Completely Random': utils.displayTrueFalse(detail.bSpawnCompletelyRandom)
        })
        break;
      case 9:  // Find Target
        this.setState({
          'Initial Search Time': `${detail.fInitialSearchTime} s`,
          'Remain Time': `${detail.fRemainTime} s`,
          'Max Find Players': detail.nMaxFindPlayers,
          'Min Find Players': detail.nMinFindPlayers,
          'NPC Target Max': detail.nNPCTargetMax,
          'NPC Target Min': detail.nNPCTargetMin,
          'Pickup Target Max': detail.nPickupTargetMax,
          'Pickup Target Min': detail.nPickupTargetMin,
          'Find Players Replace Other Types': utils.displayTrueFalse(detail.bFindPlayersReplaceOtherTypes)
        })
        break;
      case 10:  // Weapon Drop
        this.setState({
          'Max Weapon Pickup Count': detail.nMaxWeaponPickupCount,
          'Weapon Pickup Lifetime': `${detail.fWeaponPickupLifetime} s`,
          'Min Num Lives': detail.nMinNumLives,
          'Num Lives Per Player': detail.nNumLivesPerPlayer,
          'Regular Player Death Cost': detail.nRegularPlayerDeathCost,
          'Alive Score': detail.nAliveScore,
          'Alive Score Interval': `${detail.fAliveScoreInterval} s`,
          'Alive Score Min Time': `${detail.fAliveScoreMinTime} s`,
          'Alive Score Multiplier Interval': `${detail.fAliveScoreMultiplierInterval} s`,
          'Alive Score Multiplier Increment': `${detail.fAliveScoreMultiplierIncrement}x`,
          'Alive Score Multiplier Max': `${detail.fAliveScoreMultiplierMax}x`,
          'High Score Minimum': detail.nMinimumHighScore,
          'High Score Pct': `${detail.fHighScorePct*100} %`,
          'High Scorer Kill Score': detail.nHighScorerKillScore,
          'High Scorer Death Lives Lost': detail.nHighScorerDeathLivesLost,
          'High Scorer\'s Ping Duration': `${detail.fHighScorersPingDuration} s`,
          'High Scorer\'s Ping Interval': `${detail.fHighScorersPingInteval} s`,
          'High Score Per Live': utils.displayTrueFalse(detail.bHighScorePerLive),
          'High Scorer\'s VIP': utils.displayTrueFalse(detail.bHighScorersVIP),
          'Protagonist Minimum Score': detail.nMinimumProtagonistScore,
          'Protagonist Kill Score': detail.nProtagonistKillScore,
          'Protagonist Death Live Cost': detail.nProtagonistDeathLiveCost,
          'Count Arrests': utils.displayTrueFalse(detail.bCountArrests),
          'Faction Locked Teams': utils.displayTrueFalse(detail.bFactionLockedTeams),
          'Hide Team Mates': utils.displayTrueFalse(detail.bHideTeamMates),
          'Keep Groups Together': utils.displayTrueFalse(detail.bKeepGroupsTogether),
          'Matchmade Teams': utils.displayTrueFalse(detail.bMatchmadeTeams)
        })
        break;
      case 13:  // Epidemic
        this.setState({
          'Target Team Score Max': detail.fTargetTeamScoreMax,
          'Target Team Score Min': detail.fTargetTeamScoreMin,
          'Team Score Per Kill': detail.fTeamScorePerKill,
          'Team Base Zones': detail.nBaseZones,
          'End Zones': detail.nEndZones,
          'Team Count': detail.nTeamCount,
          'Team Score Max Players': detail.nTeamScoreMaxPlayers,
          'Team Score Min Players': detail.nTeamScoreMinPlayers,
          'Allow Unfinished End Zone Capture': utils.displayTrueFalse(detail.bAllowUnfinishedEndZoneCapture),
          'Corrosion Effects MaxHealth': utils.displayTrueFalse(detail.bCorrosionEffectsMaxHealth),
          'Keep Groups Together': utils.displayTrueFalse(detail.bKeepGroupsTogether),
          'Prevent Task Item Friendly Fire': utils.displayTrueFalse(detail.bPreventTaskItemFriendlyFire),
          'Show Opposed Pickup Markers': utils.displayTrueFalse(detail.bShowOpposedPickupMarkers),
          'Spawn At Objectives Only': utils.displayTrueFalse(detail.bSpawnAtObjectivesOnly),
          'Use Matchmaking': utils.displayTrueFalse(detail.bUseMatchmaking),
        })
        break;
      case 14:
        this.setState({
          'Checkpoints Per Round': detail.fCheckpointsPerRound,
          'Checkpoints Per Team': detail.fCheckpointsPerTeam,
          'Minimum Stage 1 Checkpoints': detail.nMinimumStage1Checkpoints,
          'Minimum Stage 2 Checkpoints': detail.nMinimumStage2Checkpoints,
          'Initial Prepare Time': `${detail.fInitialPrepareTime} s`,
          'Between Round Time': `${detail.fBetweenRoundTime} s`,
          'Between Stage Time': `${detail.fBetweenStageTime} s`,
          'Max Players': detail.nMaxPlayers,
          'Max Players Initial Points': detail.fMaxPlayersInitialPoints,
          'Max Players Stage One Round Time': `${detail.fMaxPlayersStageOneRoundTime} s`,
          'Max Players Stage Two Round Time': `${detail.fMaxPlayersStage2RoundTime} s`,
          'Min Players': detail.nMinPlayers,
          'Min Players Initial Points': detail.fMinPlayersInitialPoints,
          'Min Players Stage One Round Time': `${detail.fMinPlayersStageOneRoundTime} s`,
          'Min Players Stage Two Round Time': `${detail.fMinPlayersStageTwoRoundTime} s`
        })
        break;
      default:
        break;
    }
  }

  render() {
    if (Object.keys(this.state).length === 0) return null;

    return (
      <Col md={4}>
        <SmallTable title="MINIGAME" rows={this.state} />
      </Col>
    )
  }
}

class Rewards extends React.Component {
  render() {
    if (this.props.rewards.length === 0) return null;

    return (
      <Col md={12} className="rewards mb-1">
        <Accordion defaultActiveKey="0" className="flipped-accordion">
          <Card>
            <Accordion.Collapse eventKey="0" className="flipped-collapse">
              <Accordion>
                {this.props.rewards.map(reward => {
                  let items = utils.combineRewardPackageItems(reward.eReward.eReward);

                  return (
                    <Card key={reward.sAPBDB}>
                      <Accordion.Toggle as={Card.Header} eventKey={reward.sAPBDB}>
                        REWARD LEVEL {reward.nRewardLevel} - 
                        CASH ${reward.eMissionReward.nBaseCash_0}
                        <span style={{ color: 'yellow' }}> 
                          &nbsp;+ PREMIUM ${reward.eMissionReward.nBaseCash_1-reward.eMissionReward.nBaseCash_0}
                        </span>
                        {reward.eReward.eReward.nRewardTokens_0 > 0 &&
                        <span style={{ color: 'red' }}>
                          &nbsp;+ JT{reward.eReward.eReward.nRewardTokens_0}
                        </span>}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={reward.sAPBDB}>
                        {items.length > 0
                          ? (
                            <Table striped responsive variant="dark">
                              <thead>
                                <tr>
                                  <th></th>
                                  <th>Item</th>
                                  <th>Rating</th>
                                  <th>Faction</th>
                                </tr>
                              </thead>
                              <tbody>
                                {items.map(item => (
                                  <tr key={item.sAPBDB}>
                                    <td className="align-middle text-center">
                                      <img 
                                        src={item.eHUDImage} 
                                        alt={item.sDisplayName}
                                        onError={utils.handleImageError} />
                                    </td>
                                    <td className="align-middle">
                                      <NavLink to={`/items/${item.sAPBDB}`}>
                                        {item.sDisplayName}
                                      </NavLink>
                                    </td>
                                    <td className="align-middle">
                                      R {item.nMinRating}
                                    </td>
                                    <FactionIcon 
                                      enf={item.bEnforcer} 
                                      crim={item.bCriminal} />
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )
                          : <p className="p-4 m-0">
                              No item rewards for this level.
                            </p>
                        }
                        
                      </Accordion.Collapse>
                    </Card>
                  )
                })}
              </Accordion>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="flipped-header p-0">
              <h4 className="heading">
                <i className="fas fa-award" /> REWARDS
              </h4>
            </Accordion.Toggle>
          </Card>
        </Accordion>
      </Col>
    )
  }
}

class Locations extends React.Component {
  render() {
    if (this.props.locations.length === 0) return null;

    return (
      <Col md={12} className="locations mb-1">
        <Accordion className="flipped-accordion">
          <Card>
            <Accordion.Collapse eventKey="0" className="flipped-collapse p-0">
              <Table striped responsive variant="dark">
                <thead>
                  <tr>
                    <th>District</th>
                    <th>Block</th>
                    <th>Weight</th>
                    <th>Allow Reuse</th>
                    <th>Entire District</th>
                    <th>ID</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.locations.map(location => (
                    <tr key={location.sAPBDB}>
                      <td>{location.eDistrictBlock.eDistrict_0.sDisplayName}</td>
                      <td>{location.eDistrictBlock.sDisplayName}</td>
                      <td>{location.nWeight}</td>
                      <td>{utils.displayTrueFalse(location.bAllowLocationReuse)}</td>
                      <td>{utils.displayTrueFalse(location.bEntireDistrict)}</td>
                      <td>{location.sAPBDB}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="flipped-header p-0">
              <h4 className="heading">
                <i className="fas fa-map-marked" /> LOCATIONS
              </h4>
            </Accordion.Toggle>
          </Card>
        </Accordion>
      </Col>
    )
  }
}

class GunGameLevels extends React.Component {
  render() {
    return (
      <Col md={12} className="mb-1">
        <Accordion defaultActiveKey="0" className="flipped-accordion">
          <Card>
            <Accordion.Collapse eventKey="0" className="flipped-collapse p-0">
              <ListGroup className="text-center">
                {this.props.setups.sort((a, b) => (a.nLevel > b.nLevel) ? 1 : -1).map(setup => {
                  let weapon;
                  let requirement;

                  // Select available weapon
                  if (setup.eWeaponLoadout.eWeaponToCreate_0) 
                    weapon = setup.eWeaponLoadout.eWeaponToCreate_0;
                  if (setup.eWeaponLoadout.eWeaponToCreate_1) 
                    weapon = setup.eWeaponLoadout.eWeaponToCreate_1;
                  if (setup.eWeaponLoadout.eWeaponToCreate_2) 
                    weapon = setup.eWeaponLoadout.eWeaponToCreate_2;
                  if (setup.eWeaponLoadout.eWeaponToCreate_3) 
                    weapon = setup.eWeaponLoadout.eWeaponToCreate_3;

                  // Requirements for next level
                  if (setup.nNextLevelRequiredKills > 0 && setup.nNextLevelRequiredScore > 0) {
                    requirement = `${setup.nNextLevelRequiredKills} kill(s) and ${setup.nNextLevelRequiredScore} score`;
                  } else if (setup.nNextLevelRequiredKills > 0) {
                    requirement = `${setup.nNextLevelRequiredKills} kill(s)`;
                  } else if (setup.nNextLevelRequiredScore > 0) {
                    requirement = `${setup.nNextLevelRequiredScore} score`;
                  } else {
                    requirement = `?`;
                  }

                  return (
                    <ListGroup.Item key={setup.nLevel} className="gungame-level">
                      <WeaponToCreate level={setup.nLevel} weapon={weapon} />
                      <div className="gungame-next-level">
                        {setup.nLevel === this.props.setups.length
                          ? (
                            <>
                              <i className="fas fa-trophy" />&nbsp;
                              {requirement} to win&nbsp;
                              <i className="fas fa-trophy" />
                            </>
                          )
                          : (
                            <>
                              <i className="fas fa-arrow-down" />&nbsp;
                              {requirement}&nbsp;
                              <i className="fas fa-arrow-down" />
                            </>
                          )
                        }
                      </div>
                    </ListGroup.Item>
                  )
                })}
              </ListGroup>
            </Accordion.Collapse>
            <Accordion.Toggle as={Card.Header} eventKey="0" className="flipped-header p-0">
              <h4 className="heading">GUN GAME LEVELS</h4>
            </Accordion.Toggle>
          </Card>
        </Accordion>
      </Col>
    )
  }
}

const WeaponToCreate = props => (
  <Row className="gungame-level-header">         
    <Col md={6} className="my-auto">
      <h6>LEVEL {props.level}</h6>
      <NavLink to={`/items/${props.weapon.sAPBDB}`}>
        <b>{props.weapon.sDisplayName}</b>
      </NavLink>
    </Col>
    <Col md={6} className="my-auto">
      <NavLink to={`/items/${props.weapon.sAPBDB}`}>
        <img 
          className="item-icon"
          src={props.weapon.eHUDImage}
          alt={props.weapon.sDisplayName}
          onError={utils.handleImageError} />
      </NavLink>
      {props.weapon.detail && 
        <>
          <ItemFnMod mod={props.weapon.detail.eFnMod_0.eInventoryItemType} />
          <ItemFnMod mod={props.weapon.detail.eFnMod_1.eInventoryItemType} />
          <ItemFnMod mod={props.weapon.detail.eFnMod_2.eInventoryItemType} />
        </>
      }
    </Col>
  </Row>
)


const ItemFnMod = props => (
  <NavLink to={`/items/${props.mod.sAPBDB}`}>
    <img 
      className="fnmod" 
      src={props.mod.eHUDImage}
      alt={props.mod.sDisplayName}
      onError={utils.handleImageError} />
  </NavLink>
)