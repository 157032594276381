import React from 'react';
import SimpleBar from 'simplebar-react';
import { Redirect } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';

// Utilities
import utils from '../../_helpers/utils';
import { authService } from '../../_services/auth';

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      username: null,
      password: null
    }
  }

  changeUsername = (e) => {
    this.setState({ username: e.target.value, error: false });
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value, error: false });
  }

  handleLogin = (e, login) => {
    e.preventDefault();
    this.setState({ loading: true });

    authService.login(this.state.username, this.state.password)
      .then(
        user => {
          login();
          this.setState({ redirect: true, loading: false });
        },
        error => {
          if (error.toString().startsWith('TypeError')) {
            this.setState({ error: 'Connection error', loading: false });
          } else {
            this.setState({ error: 'Wrong username or password', loading: false });
          }
        }
      )
  }

  render() {
    if (this.state.redirect) return <Redirect to="/" />

    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
        <Container className="screen-center">
        <authService.userContext.Consumer>
        {({user, login, logout}) => {
          if (user) {
            return (
              <p className="text-center">
                You are already logged in, {user.name}! <br />
                <Button variant="primary" onClick={logout}>
                  Logout?
                </Button>
              </p>
            )
          }
          
          return (
            <Form onSubmit={(e) => this.handleLogin(e, login)}>
              <h2>Login</h2>
              <Form.Group controlId="formLoginUsername">
                <Form.Control 
                  required
                  placeholder="username" 
                  onChange={this.changeUsername} />
              </Form.Group>
              <Form.Group controlId="formLoginPassword">
                <Form.Control 
                  required
                  type="password" 
                  placeholder="password" 
                  onChange={this.changePassword} />
              </Form.Group>
              {this.state.error && 
                <p className="text-danger">{this.state.error}</p>
              }
              <Button variant="primary" type="submit">
                {this.state.loading ? utils.loadingCircle(true) : 'Login'}
              </Button>
            </Form>
          )}}
          </authService.userContext.Consumer>
        </Container>
      </SimpleBar>
    )
  }
}