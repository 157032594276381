import React from 'react';
import SimpleBar from 'simplebar-react';
import { Image, Button, Table, Modal } from 'react-bootstrap';

// Components
import { ErrorMessage } from '../Extras';

// Extras
import utils from '../../_helpers/utils';

export default class AmmoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ammoError: false
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/items/ammo', 'ammo');
  }

  render() {
    if (this.state.ammoError) {
      return <ErrorMessage error={this.state.ammoError} />;
    }

    if (this.state.ammoLoaded) {
      return (
        <Modal 
          centered size="xl" scrollable
          show={this.props.show} 
          onHide={this.props.toggle}>
          <Modal.Header>
            <Modal.Title>Ammo Categories</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <SimpleBar style={{ height: '100%' }}>
              <Table hover className="table-lite">
                <thead>
                  <tr>
                    <th></th>
                    <th>Ammo Type</th>
                    <th>Max Capacity</th>
                    <th>Box Size</th>
                    <th>Box Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.ammo.map(cat => (
                    <tr key={cat.sAPBDB}>
                      <td className="text-center align-middle">
                        <Image 
                          width={64} height={64}
                          src={cat.sHUDImage_Bullet} 
                          alt={cat.sName === 'None' ? cat.sAPBDB : cat.sName}
                        />
                      </td>
                      <td className="align-middle">{cat.sName === 'None' ? cat.sAPBDB : cat.sName}</td>
                      <td className="align-middle">{cat.nCapacity}</td>
                      <td className="align-middle">{cat.nBoxSize}</td>
                      <td className="align-middle">${cat.nBoxCost}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </SimpleBar>
          </Modal.Body>
  
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.toggle}>Close</Button>
          </Modal.Footer>
        </Modal>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}
