export const tomfoolery = () => {
  const body = document.querySelector('body');
  const today = new Date();
  const isAprilFoolsDay = today.getMonth() === 3 && today.getDate() === 1; // check if today is April 1st

  if (!isAprilFoolsDay) return;

  body.addEventListener('click', () => {
    rotateImages();
    jumpingText(body);
    toggleImageSize();
    randomFont(body);
  });

  body.addEventListener('keydown', () => {
    rotateImages();
    jumpingText(body);
    toggleImageSize();
    randomFont(body);
  });
  
  document.addEventListener("click", explode);
}

const rotateImages = () => {
  const imgs = document.querySelectorAll('img');
  imgs.forEach((img) => {
    if (img.className === 'background-image') return;
    if (Math.random() >= 0.2) return;
    img.style.transform = 'rotate(0deg)'; // reset the rotation to 0 degrees
    img.style.transition = 'none'; // remove the transition animation

    // set the final rotation to 360 degrees after a short delay to allow the transition to take effect
    setTimeout(() => {
      img.style.transition = 'transform 1s ease-in-out'; // set the transition animation
      img.style.transform = 'rotate(360deg)';
    }, 50);
  });
};

const jumpingText = body => {
  // Get all the small and button elements within the body element
  const smallButtonElems = body.querySelectorAll('td, small, button, p');

  // Loop through all the small and button elements and animate each one
  smallButtonElems.forEach((elem) => {
    if (Math.random() >= 0.1) return;
    // Generate a random number between -10 and 10 for the animation distance
    const distance = Math.floor(Math.random() * 51) - 50;
    // Create a keyframe animation for the element
    const animation = elem.animate(
      [
        { transform: 'translateY(0)' },
        { transform: `translateY(${distance}px)` },
        { transform: 'translateY(0)' }
      ],
      {
        duration: 1000,
        iterations: 1,
        easing: 'ease-out'
      }
    );
    // Play the animation
    animation.play();
  });
};

const toggleImageSize = () => {
  if (Math.random() >= 0.1) return;
  let isBig = false;
  const images = document.querySelectorAll('img');
  if (!isBig) {
    images.forEach(image => {
      image.style.transition = 'transform 2s';
      image.style.transform = 'scale(2)';
    });
    isBig = true;
  } else {
    images.forEach(image => {
      image.style.transition = 'transform 2s';
      image.style.transform = 'scale(1)';
    });
    isBig = false;
  }
};

const randomFont = body => {
  const elements = body.querySelectorAll('td, small, button, p, h6');
  const fontFamilies = ["Arial", "Times New Roman", "Verdana", "Helvetica", "Georgia", "Courier New", "Impact", "Comic Sans MS", "Trebuchet MS", "Lucida Console"];

  for (let i = 0; i < elements.length; i++) {
    elements[i].style.fontFamily = fontFamilies[Math.floor(Math.random() * fontFamilies.length)];
  }
}

function explode(event) {
  const target = event.target;
  if (target.tagName === "H6" || target.tagName === "SMALL" || target.tagName === "P" || target.tagName === "H1" || target.tagName === "SPAN" || target.tagName === "TD") {
    const text = target.innerText;
    target.innerHTML = text.split("").map(char => `<span>${char}</span>`).join("");
    const spans = target.querySelectorAll("span");
    spans.forEach(span => {
      span.style.display = "inline-block";
      span.style.position = "relative";
      span.style.animation = `explode-${Math.floor(Math.random() * 3)} 1s ease-in forwards`;
    });

    const style = document.createElement("style");
    style.innerHTML = `
      @keyframes explode-0 {
        0% {
          opacity: 1;
          transform: none;
        }
        100% {
          opacity: 0;
          transform: translate(${Math.floor(Math.random() * 1000 - 500)}px, ${Math.floor(Math.random() * 1000 - 500)}px) rotate(${Math.floor(Math.random() * 720)}deg);
        }
      }

      @keyframes explode-1 {
        0% {
          opacity: 1;
          transform: none;
        }
        100% {
          opacity: 0;
          transform: translate(${Math.floor(Math.random() * 1000 - 500)}px, ${Math.floor(Math.random() * 1000 - 500)}px) rotate(${Math.floor(Math.random() * 720)}deg);
        }
      }

      @keyframes explode-2 {
        0% {
          opacity: 1;
          transform: none;
        }
        100% {
          opacity: 0;
          transform: translate(${Math.floor(Math.random() * 1000 - 500)}px, ${Math.floor(Math.random() * 1000 - 500)}px) rotate(${Math.floor(Math.random() * 720)}deg);
        }
      }
    `;
    document.head.appendChild(style);

    setTimeout(() => {
      spans.forEach(span => {
        span.style.animation = "none";
        span.style.display = "inline";
      });
      target.innerHTML = text;
      document.head.removeChild(style);
    }, 5000);
  }
}
