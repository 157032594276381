import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Tabs, Tab, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';

// Components
import { Header } from '../DetailTemplate';
import Statistics from './ItemStatistics';
import Modding from './ItemModding';
import Compare from './ItemCompare';
import Make from './Make';
import Changes from './ItemChanges';
import { StatsExplosion } from './ItemStatistics';
import { SmallTable, ErrorMessage, FnModOverlay } from '../Extras';

// Utilities
import utils from '../../_helpers/utils';
import MetaDecorator from '../../_helpers/MetaDecorator';
import { EMPTY_MOD } from '../../_helpers/constants';

// Services
import { settingsService } from '../../_services/settings';

// Styles
import './Detail.scss';

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: ''
    }

    this.request = utils.request.bind(this);
    this.switchTab = this.switchTab.bind(this);
  }

  componentDidMount() {
    this.getItem();

    let tab = this.props.history.location.pathname.substring(1).split('/').pop();
    if (tab && tab !== this.props.sapbdb) {
      this.setState({ selectedTab: tab });

      if (tab === 'compare') {
        this.setState({ 
          compare: new URLSearchParams(this.props.history.location.search).get('item') 
        });
      } else if (tab === 'modding') {
        this.setState({ 
          fnmods: new URLSearchParams(this.props.history.location.search).get('mods') 
        });
      }
    }
    
    window.addEventListener("popstate", this.handlePopState);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.handlePopState);
  }

  handlePopState = (e) => {
    let tab = e.target.location.pathname.substring(1).split('/')[2];
    this.setState({ selectedTab: tab || '' });
  }

  componentDidUpdate(prevProps) {
    if (this.props.sapbdb !== prevProps.sapbdb) {
      this.getItem();
      this.setState({ selectedTab: '', fnmods: null });
    }
  }

  getItem() {
    this.request(`/items/${this.props.sapbdb}`, 'item');
  }

  switchTab = (key) => {
    this.setState({ selectedTab: key });

    let location = this.props.history.location.pathname;
    
    // Remove any trailing slashes to prevent issues
    if (location.endsWith('/')) location = location.slice(0, -1);

    let current = location.substring(1).split('/').pop();

    if (current !== this.props.sapbdb && current) {
      this.props.history.push(
        `${location.replace(`/${current}`, '')}${key ? `/${key}` : ''}`
      )
    } else {
      this.props.history.push(
        `${location}/${key}`
      )
    }
  }

  render() {
    let content = null;

    if (this.state.itemError) {
      content = <ErrorMessage error={this.state.itemError} />;
    } else if (this.state.itemLoaded) {
      content = (
        <>
          <MetaDecorator 
            title={this.state.item.sDisplayName}
            ogTitle={this.state.item.sDisplayName}
            ogDescription={this.state.detail && this.state.item.detail.sDescription}
            ogUrl={`/items/${this.state.item.sAPBDB}`}
            ogImage={this.state.item.eHUDImage} />
          <Header toggleCompactMode={this.props.toggleCompactMode}>
            <img 
              src={this.state.item.eHUDImage} 
              alt={this.state.item.sDisplayName}
              onError={utils.handleImageError} />
            <h4 
              className="d-inline align-middle" 
              dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.state.item.sDisplayName)}}>
            </h4>
            {settingsService.get('site', 'debug') && 
              <span className="ml-3 debug">
                {this.state.item.sAPBDB}
              </span>
            }
          </Header>
          <Description 
            item={this.state.item} 
            setSelected={this.props.setSelected}
            switchTab={this.switchTab} />
          {this.state.item.detail && 
            <ItemDetailTabs 
              item={this.state.item} 
              compare={this.state.compare}
              fnmods={this.state.fnmods}
              selectedTab={this.state.selectedTab}
              switchTab={this.switchTab} 
              history={this.props.history} />}
        </>
      )
    } else {
      content = utils.loadingCircle();
    }
    
    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }} 
                 className="detail-component">
        {content}
      </SimpleBar>
    );
  }
}

class Description extends React.Component {
  constructor(props) {
    super(props);

    // INFO
    this.info = {
      'Faction': utils.displayFaction(this.props.item.bCriminal, this.props.item.bEnforcer),
      'Rating': this.props.item.nMinRating,
      'Cost':  `$${this.props.item.eDefaultPrice[0].nCostAPBCash.toLocaleString()}`,
      'Lease': this.props.item.eDefaultPrice[0].eLease.sAPBDB,
      'Tradeable': this.props.item.eTrade
    }

    // Delete Cost and Lease if multiple prices exist
    if (this.props.item.eDefaultPrice.length > 1) {
      delete this.info['Cost'];
      delete this.info['Lease'];
    }
    
    if (this.props.item.detail) {
      this.info['Preset'] = utils.displayTrueFalse(this.props.item.detail.bPreset);
    }

    if (this.props.item.category === 'Clothing') {
      this.info['Gender'] = this.displayGender(this.props.item.detail.bMale, this.props.item.detail.bFemale);
    }

    if (this.props.item.category === 'Weapon') {
      // WEAPON
      this.weapon = {
        'Kill Icon': <img className="kill-icon" alt="kill icon" src={this.props.item.detail.eActivityMessageIcon} onError={utils.handleImageError} />,
        'Skinnable': utils.displayTrueFalse(this.props.item.detail.bIsSkinnable),
        'Equip In Vehicle': utils.displayTrueFalse(this.props.item.equipInVehicle)
      }
    }

    if (this.props.item.detail.eModifierItem) {
      // COOLDOWN
      if (this.props.item.detail.eModifierItem.fCooldownTimeAtPremiumLevel_0) {
        this.cooldown = {
          'Standard': `${this.props.item.detail.eModifierItem.fCooldownTimeAtPremiumLevel_0} sec`,
          'Premium': `${this.props.item.detail.eModifierItem.fCooldownTimeAtPremiumLevel_1} sec`
        }
      }
      
      // DURATION
      if (this.props.item.detail.eModifierItem.fDurationAtPremiumLevel_0) {
        this.duration = {
          'Standard': `${this.props.item.detail.eModifierItem.fDurationAtPremiumLevel_0} sec`,
          'Premium': `${this.props.item.detail.eModifierItem.fDurationAtPremiumLevel_1} sec`
        }
      }

      // STACKING
      if (this.props.item.detail.nCharges || this.props.item.detail.nMaxStacks) {
        this.stacking =  {
          'Charges': this.props.item.detail.nCharges,
          'Max Charges': this.props.item.detail.nMaxCharges,
          'Max Stacks': this.props.item.detail.nMaxStacks
        }
      }
    }
  }

  displayGender(male, female) {
    if (male && female) {
      return 'Both';
    } else if (male && !female) {
      return 'Male';
    } else if (!male && female) {
      return 'Female';
    }
    return 'None';
  }

  render() {
    return (
      <Container fluid as={Row} className="description">
        {this.props.item.detail !== undefined &&
        <Col sm={12} md={8}>
          <Row noGutters>
            <Col md={12} className="mb-3">
              <h6>DESCRIPTION</h6>
              <small dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.props.item.detail.sDescription)}} />
            </Col>

            {this.props.item.detail.eFnMod_0 &&  
            <Col md={12} className="mb-3">
              <h6>MODIFICATIONS</h6>
              <ItemFnMod mod={this.props.item.detail.eFnMod_0} {...this.props} />
              <ItemFnMod mod={this.props.item.detail.eFnMod_1} {...this.props} />
              <ItemFnMod mod={this.props.item.detail.eFnMod_2} {...this.props} />
              {this.props.item.detail.eFnMod_3 && 
                <ItemFnMod mod={this.props.item.detail.eFnMod_3} {...this.props} />}
            </Col>
            }

            {!!this.props.item.detail.eUnlockItem &&
            <Unlocks 
              unlock={this.props.item.detail}
              setSelected={this.props.setSelected} 
            />
            }

            {this.props.item.detail.eModifierItem && 
            <ModifierEffects 
              effects={this.props.item.detail.eModifierItem.aModifierEffects}
              modifierTypeLinks={this.props.item.detail.aModifierTypeLinks} />
            }

            {this.props.item.detail.CSAItemAssociation && 
            <Equipment assoc={this.props.item.detail.CSAItemAssociation} />
            }

            {this.props.item.detail.eRewardPackage && 
            <RewardPackage 
              detail={this.props.item.detail} 
              setSelected={this.props.setSelected} />
            }

            {this.props.item.detail.eDeployable && 
            <Deployable deployable={this.props.item.detail.eDeployable} />
            }

            <Acquisition item={this.props.item} />
          </Row>
        </Col>
        }
        <Col sm={12} md={4}>
          <SmallTable title="INFO" rows={this.info} />
          {this.weapon &&
            <SmallTable title="WEAPON" rows={this.weapon} />
          }
          {this.props.item.detail.eModifierItem &&
          <>
            <SmallTable title="COOLDOWN" rows={this.cooldown} />
            <SmallTable title="DURATION" rows={this.duration} />
            <SmallTable title="STACKING" rows={this.stacking} />
          </>
          }
        </Col>
      </Container>
    )
  }
}

class ItemFnMod extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.mod.sAPBDB === EMPTY_MOD) {
      return this.props.switchTab('modding');
    } else if (this.props.mod.sAPBDB === 'Mod_None') {
      return '';
    }
    return this.props.setSelected(this.props.mod.sAPBDB);
  }

  render() {
    const overlay =  (
      <Tooltip id={`tooltip-${this.props.mod.sAPBDB}`}>
        <b>{this.props.mod.eInventoryItemType.sDisplayName}</b>
        {this.props.mod.eModifierItem.aModifierEffects.map(effect => (
          <p
            key={`${effect.sAPBDB}-${effect.eEffectType}`} 
            className='mb-0' 
            dangerouslySetInnerHTML={{__html: utils.convertColorCode(effect.sDescription)}} />
        ))}
      </Tooltip>
    )

    const button = (
      <button onClick={this.handleClick} className="fnmod">
        <Image src={this.props.mod.eInventoryItemType.eHUDImage} 
              alt={this.props.mod.eInventoryItemType.sDisplayName} />
      </button>
    )

    if (this.props.mod.sAPBDB === 'Mod_None' || this.props.mod.sAPBDB === EMPTY_MOD) return button;
    return (
      <OverlayTrigger key='auto' placement='auto' overlay={overlay}>
        {button}
      </OverlayTrigger>
    )
  }
}

class Acquisition extends React.Component {
  constructor(props) {
    super(props);

    this.state = { acquisition: {} }

    this.request = utils.request.bind(this);
  }

  priceEntry(price) {
    //if (!price.sAPBDB.includes('JokerDistribution')) return null;
    if (price.bOnlyShowWhenUnlocked) return null;
    if (!price.bAlwaysAvailable) return null;

    let finalPrice;
    if (price.nCostAPBCash > 0 && price.nCostRewardTokens > 0) {
      finalPrice = `$${price.nCostAPBCash.toLocaleString()} / JT ${price.nCostRewardTokens.toLocaleString()}`;
    } else if (price.nCostAPBCash === 0 && price.nCostRewardTokens > 0) {
      finalPrice = `JT ${price.nCostRewardTokens.toLocaleString()}`;
    } else {
      finalPrice = 'Free';
    }

    let leaseTime;
    if (price.eLease) {
      if (price.bPurchaseUnlock) {
        leaseTime = '(Permanent)';
      } else {
        leaseTime = `(${price.eLease.sAPBDB})`;
      }
    }

    let randomReward;
    if (price.nGuaranteedRandomRewardAmount > 0) {
      randomReward = `+ ${price.sCustomAdditionalItem} Guaranteed at JT ${price.nGuaranteedRandomRewardAmount.toLocaleString()} spent.`
    }

    return (
      <li>
        Joker Store for {finalPrice} {leaseTime} {randomReward}
      </li>
    )
  }

  componentDidMount() {
    this.request(`/items/unlock/${this.props.item.sAPBDB}`, 'acquisition');
  }

  render() {
    let isAcquirable;

    // Check if there are enough entries that make the item acquirable
    if (this.props.item.eDefaultPrice.length > 1) isAcquirable = true;
    if (this.state.acquisitionLoaded && !this.state.acquisitionError) {
      if (this.state.acquisition.contactLevels.length > 0 ||
          this.state.acquisition.roleMilestones.length > 0)
        isAcquirable = true;
    }

    if (isAcquirable) {
      return (
        <Col className='mb-3' md={12}>
          <h6>ACQUISITION</h6>
          {this.props.item.eDefaultPrice.map(price => (
            <small key={price.sAPBDB}>
              {this.priceEntry(price)}
            </small>
          ))}
          {this.state.acquisitionLoaded &&
            <small>
              {this.state.acquisition.contactLevels.map(acq => (
                <li key={acq.sAPBDB}>
                  <NavLink to={`/contacts/${acq.eContact.sAPBDB}`}>{acq.eContact.sTitle}</NavLink> at contact level {acq.nLevel}.
                </li>
              ))}
              {this.state.acquisition.roleMilestones.map(acq => {
                if (!acq.role) return;
                return (
                <li key={acq.sAPBDB}>
                  <NavLink to={`/${acq.role.bAchievement ? 'achievements' : 'roles'}/${acq.role.sAPBDB}`}>
                    {acq.role.sDisplayName}
                  </NavLink>
                  {acq.role.bAchievement ? ' achievement' : ' role'} at level {acq.sTitle}.
                </li>
              )})}
            </small>
          }
        </Col>
      )
    } else {
      return null;
    }
  }
}

class Unlocks extends React.Component {
  constructor(props) {
    super(props);

    this.state = { }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/items/${this.props.unlock.eUnlockItem}`, 'item');
  }

  render() {
    let unlock;

    if (this.state.itemError) {
      unlock = 'error loading unlock';
    }

    if (this.state.itemLoaded) {
      unlock = (
        <span className="fake-link" onClick={() => this.props.setSelected(this.state.item.sAPBDB)}>
          <Image 
            width={32} height={32}
            src={this.state.item.eHUDImage}
            alt={this.state.item.sDisplayName}
            onError={utils.handleImageError} />
          <span dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.state.item.sDisplayName)}} />
        </span>
      )
    } else {
      unlock = utils.loadingCircle(true);
    }

    return (
      <Col className="mb-3" md={12}>
        <h6>UNLOCKS</h6>
        {unlock}
      </Col>
    )
  }
}

class Equipment extends React.Component {
  replacePercent(text) {
    let percent = (1.0 - this.props.assoc.fEffectivenessModifier) * 100;
    return text.replace('<Value_Percent>', percent.toFixed());
  }

  render() {
    return (
      <Col className="mb-3" md={12}>
        <h6>EFFECT</h6>
        <small>{this.replacePercent(this.props.assoc.eItemAssociationCategory.sEffect)}</small>
      </Col>
    )
  }
}

class ModifierEffects extends React.Component {
  render() {
    if (this.props.effects.length > 0) {
      return (
        <Col md={12} className="modifier-effects-detail">
          <h6>EFFECTS</h6>
          <ul>
            {this.props.effects.map((item, i) => [
              <li key={i} dangerouslySetInnerHTML={{__html: `
              <small>
                ${utils.convertColorCode(item.sDescription)}
                <br />
                <div style="font-size: .85em; color: silver">
                  Multiplier: ${item.fEffectMultiplier} | 
                  Add: ${item.fAddToResult} | 
                  Type: ${item.eEffectType} 
                  ${settingsService.get('site', 'debug') ? `
                    <span style='color: orange'>
                      => ${this.props.modifierTypeLinks.find(link => link.eModifierType === item.eEffectType).sVariable}
                    </span>
                    ` : ''}
                </div>
              </small>`}}></li>
            ])}
          </ul>
        </Col>
      )
    } else {
      return null;
    }
  }
}

class RewardPackage extends React.Component {
  render() {
    if (this.props.detail.eRewardPackage.eItems.length > 0 || 
        this.props.detail.eRewardPackage.eChildPackage.eItems.length > 0) {
      return (
        <Col md={12}>
          <h6>REWARDS</h6>
          <ul>
            {this.props.detail.eRewardPackage.eItems.map(item => (
              <li key={item.sAPBDB}>
                <small
                  className="fake-link"
                  onClick={() => this.props.setSelected(item.sAPBDB)}
                  dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}}>
                </small>
              </li>
            ))}
            {this.props.detail.eRewardPackage.eChildPackage.eItems.map(item => (
              <li key={item.sAPBDB}>
                <small 
                  className="fake-link"
                  onClick={() => this.props.setSelected(item.sAPBDB)}
                  dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}}>
                </small>
              </li>
            ))}
          </ul>
          {!!this.props.detail.eRewardPackage.bSendMail && 
          <>
            <h6>MAIL</h6>
            <small>
              <b>{this.props.detail.sMailSubject}</b>
              <p style={{whiteSpace: "pre-wrap"}}>{this.props.detail.sMailBody}</p>
            </small>
          </>
          }
        </Col>
      );
    }
    return null;
  }
}

class Deployable extends React.Component {
  constructor(props) {
    super(props);

    let taskItemEffect = this.props.deployable.eTaskItem.eEffect;
    this.deployable = {
      'Health': taskItemEffect.nHealth,
      'Jump Modifier': taskItemEffect.fJumpZModifier,
      'Repair Multiplier': taskItemEffect.fRepairMultiplier
    }

    if (this.props.deployable.bExplodeAtModifierEnd) {
      this.deployable['Timed Explosion Duration'] = `${taskItemEffect.fTimedExplosionDuration} sec`
    }
  }

  render() {
    return (
      <>
        <Col className="mb-3" md={5}>
          <SmallTable title="DEPLOYABLE" rows={this.deployable} />
        </Col>
        {!!this.props.deployable.eTaskItem.eEffect.eExplosion &&
        <Col md={{ span: 5, offset: 1 }}>
          <StatsExplosion explosion={this.props.deployable.eTaskItem.eEffect.eExplosion} />
        </Col>
        }
      </>
    )
  }
}

class ItemDetailTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: new URLSearchParams(this.props.history.location.search)
    }

    this.updateUrl = this.updateUrl.bind(this);
  }

  updateUrl(name, value) {
    if (value) {
      this.state.search.set(name, value)
    } else {
      this.state.search.delete(name)
    }
    
    this.props.history.push(
      `${this.props.history.location.pathname}?${decodeURIComponent(this.state.search.toString())}` 
    )
  }

  hasStatistics() {
    if (this.props.item.detail.eWeaponTypeLink) return true;
    else if (this.props.item.detail.eVehicle) return true;
    return false;
  }

  render() {
    return (
      <div className="item-detail-tabs">
        <Tabs onSelect={this.props.switchTab} activeKey={this.props.selectedTab} id="detailTabs" mountOnEnter>
        {this.hasStatistics() &&
          <Tab eventKey="" title={<span><i className="fas fa-align-left" /> DETAIL</span>}>
            <Statistics 
              detail={this.props.item.detail} 
              category={this.props.item.category} 
            />
          </Tab>
        }
        {this.hasStatistics() &&
          <Tab eventKey="modding" title={<span><i className="fas fa-sliders-h" /> MODDING</span>} >
            <Modding 
              item={this.props.item} 
              fnmods={this.props.fnmods}
              updateUrl={this.updateUrl} />
          </Tab>
        }
        {this.hasStatistics() &&
          <Tab eventKey="compare" title={<span><i className="fas fa-exchange-alt" /> COMPARE</span>}>
           <Compare 
            item={this.props.item} 
            compare={this.props.compare}
            updateUrl={this.updateUrl} />
          </Tab>
        }
        {this.props.item.detail.eWeaponTypeLink && this.hasStatistics() && settingsService.get('site', 'debug') &&
          <Tab eventKey="make" title={<span className="text-warning"><i className="fas fa-wrench" /> MAKE</span>}>
            <Make item={this.props.item} />
          </Tab>
        }
        {this.props.item.detail.eWeaponTypeLink && this.hasStatistics() &&
          <Tab eventKey="changes" title={<span><i className="fas fa-history" /> CHANGES</span>}>
            <Changes sAPBDB={this.props.item.detail.eWeaponTypeLink.eWeaponType_0.sAPBDB} />
          </Tab>
        }
        {this.hasStatistics() && this.props.item.detail.eVehicle &&
          <Tab eventKey="changes" title={<span><i className="fas fa-history" /> CHANGES</span>}>
            <Changes sAPBDB={this.props.item.detail.eVehicle.sAPBDB} />
          </Tab>
        }
        </Tabs>
      </div>
    );
  }
}
