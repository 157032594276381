import React from 'react';
import { Image, Table } from 'react-bootstrap';
import { CDN_ROOT } from '../_helpers/constants';

export const FAIcon = props => (
  <i title={props.title} className={props.icon} onClick={props.onClick}></i>
);

export const Badge = props => (
  <span className={'badge badge-' + props.type}>{props.text}</span>
);

export const FactionIcon = props => (
  <>
    {props.faction === 0 && <Image fluid src={`${CDN_ROOT}/apbdb/CrimNEnf_Large.webp`} />}
    {props.faction === 1 && <Image fluid src={`${CDN_ROOT}/apbdb/Enforcer_Large.webp`} />}
    {props.faction === 2 && <Image fluid src={`${CDN_ROOT}/apbdb/Criminal_Large.webp`} />}
    {props.faction === 3 && <Image fluid src={`${CDN_ROOT}/apbdb/CrimNEnf_Large.webp`} />}
  </>
);

export const ErrorMessage = props => (
  <div className={!props.noVCenter ? "screen-center" : null}>
    <div className={`text-center ${props.className}`}>
      {props.code && <h1 className="display-5">ERROR {props.code}</h1>}
      {props.children && <p className="lead">{props.children}</p>}
      {props.error &&
        <>
          <h5>Oh no.. an error occured.</h5>
          <small className="text-muted">
            {props.error.toString()}
            <br />
            {window.location.href}
          </small>
        </>
      }
    </div>
  </div>
);

export class SmallTable extends React.Component {
  checkDiff(key) {
    for (const object of this.props.diffs) {
      if (key === object.key) {
        if (Boolean(object.better)) {
          return <i className="fas fa-chevron-up"></i>;
        }
        return <i className="fas fa-chevron-down"></i>;
      }
    }
    return null;
  }

  checkModified(key) {
    try {
      if (this.props.rows[key] !== this.props.modified[key]) {
        return (
          <span className="modified">
            <i className="fas fa-caret-right"></i> {this.props.modified[key]}
          </span>
        );
      }  
    } catch (TypeError) {}
  }

  getAdditionalKeys() {
    // find modified keys that don't exist in the base object
    const adds = {};

    for (const key in this.props.modified) {
      if (!this.props.rows.hasOwnProperty(key)) {
        adds[key] = this.props.modified[key];
      }
    }

    return adds;
  }

  render() {
    if (!this.props.rows) return null;

    return (
      <>
        {this.props.title && <h6 className={this.props.color}>{this.props.title}</h6>}
        <Table size="sm" className={"table-lite " + this.props.className}>
          <tbody>
            {Object.entries(this.props.rows).map(([key, value]) => (
              <tr key={key}>
                <td className={this.props.color}>
                  <small>{key}</small>
                </td>
                <td className={`text-right ${this.props.color} ${this.props.reduced && 'reduced'}`}>
                  <small>
                    {this.props.diffs && this.checkDiff(key)} {value} {this.checkModified(key)}
                  </small>
                </td>
              </tr>
            ))}
            {Object.entries(this.getAdditionalKeys()).map(([key, value]) => (
              <tr key={key}>
                <td className={this.props.color}>
                  <small className="modified">{key}</small>
                </td>
                <td className={`text-right ${this.props.color} ${this.props.reduced && 'reduced'}`}>
                  <small className="modified">
                    {value}
                  </small>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )
  }
}
