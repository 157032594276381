export default class WeaponCalculation {
  /*
    Weapon Classes:
        => 0 = Grenade
        => 1 = Projectile
        => 2 = Raycast
        => 3 = Shotgun (unused)
        => 4 = Witnessing (unused)
  */
  constructor(weapontype) {
    // Check if projectile is used
    if (weapontype.eWeaponProjectile === 0) {
      this.healthDamage = weapontype.fHealthDamage;
      this.staminaDamage = weapontype.fStaminaDamage;

      // Shotgun
      this.rays = weapontype.eRangedWeaponType.nRaysPerShot;
      this.rayScale = weapontype.eRangedWeaponType.fPerRayDamageScale;
    } else {
      this.healthDamage = weapontype.eWeaponProjectile.eExplosion.nDamage;
      this.staminaDamage = weapontype.eWeaponProjectile.eExplosion.nStunDamage;
    }
      
    this.burstShots = weapontype.nBurstShots;
    this.fireInterval = weapontype.fFireInterval;
    this.burstInterval = weapontype.fBurstInterval;
    this.windUpTime = weapontype.fWindUpTime;
    this.firingState = weapontype.eWeaponFiringState;  // for burst ttk calc
    
    this.playerHealth = 1000;

    this.healthStk = this.calcStk(this.healthDamage);
    this.healthTtk = this.calcTtk(this.healthStk);
    this.staminaStk = this.calcStk(this.staminaDamage);
    this.staminaTtk = this.calcTtk(this.staminaStk);
  }

  calcStk(damage) {
    // Shotgun per ray scale damage
    if (this.rayScale !== 1 && this.rayScale !== undefined) {
      let increment = 0;
      let finalDamage = 0;
      while (increment < this.rays) {
        if (increment === 0) {
          finalDamage = damage;
        } else {
          finalDamage += Math.floor(damage * (this.rayScale ** increment));
        }
        increment++;
      }
      return Math.ceil(this.playerHealth / finalDamage);
    }
    if (this.rays > 1) {
      return Math.ceil(this.playerHealth / (damage * this.rays));
    }
    return Math.ceil(this.playerHealth / damage);
  }

  calcTtk(stk) {
    if (this.firingState === 3) {
      return ((stk - 1) * this.fireInterval) + (Math.floor((stk - 1) / this.burstShots) * this.burstInterval)
    }
    return ((stk - 1) * this.fireInterval + this.windUpTime);
  }
}
