import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image, Table } from 'react-bootstrap';

// Components
import { Header } from '../../DetailTemplate';
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import '../../Detail/Detail.scss';
import './Achievement.scss';

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      achievementError: false
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.getAchievement();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.getAchievement();
    }
  }

  getAchievement() {
    this.request(`/achievements/${this.props.selected}`, 'achievement');
  }

  render() {
    if (this.state.achievementError) {
      return <ErrorMessage error={this.state.achievementError} />;
    }

    if (this.state.achievementLoaded) {
      return (
        <Container fluid className="achievement-component">
          <MetaDecorator title={this.state.achievement.sDisplayName} />
          <Header toggleCompactMode={this.props.toggleCompactMode}>
            <Image 
              src={this.state.achievement.aMilestones[0].eRoleIcon}
              alt={this.state.achievement.sDisplayName}
              onError={utils.handleImageError} />
            <h4 className="d-inline align-middle">
              {this.state.achievement.sDisplayName}
            </h4>
          </Header>
          <Row>
            <Description achievement={this.state.achievement} />
            {this.state.achievement.aMilestones.map(milestone => (
            <Milestone key={milestone.sAPBDB} milestone={milestone} />
            ))}
          </Row>
        </Container>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class Description extends React.Component {
  render() {
    return (
      <Col md={12} className="description">
        <Row noGutters>
          <Col md={8} className="mb-3">
            <h6>DESCRIPTION</h6>
            <small>{this.props.achievement.sDescription}</small>
          </Col>
          <Actions achievement={this.props.achievement} />
        </Row>
      </Col>
    )
  }
}

class Actions extends React.Component {
  calculateDifference(level) {
    let final = this.props.achievement.aMilestones[level].fPassMark_0;

    if (level > 0) final -= this.props.achievement.aMilestones[level-1].fPassMark_0;
    
    return final;
  }

  render() {
    return (
      <Col md={4}>
        <h6>
          ACTIONS
          <small className="ml-2">
            {this.props.achievement.eActivities_0.sAPBDB}
          </small>
        </h6>
        <Table size="sm" className="table-lite">
          <thead>
            <tr>
              <th>Level</th>
              <th>Actions</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.props.achievement.aMilestones.map((milestone, index) => (
            <tr key={milestone.sAPBDB}>
              <td>{index+1}</td>
              <td>{this.calculateDifference(index).toLocaleString()}</td>
              <td>{milestone.fPassMark_0.toLocaleString()}</td>
            </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    )
  }
}

const Milestone = props => (
  <Col md={12}>
    <h6>{props.milestone.sTitle}</h6>
    <ul>
      {utils.combineRewardPackageItems(props.milestone.eReward).map(item => (
        <li key={item.sAPBDB} className="milestone">
          <Image 
            width={32} height={32}
            src={item.eHUDImage}
            alt={item.sDisplayName}
            onError={utils.handleImageError} />
          <NavLink 
            to={`/items/${item.sAPBDB}`}
            dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}} />
        </li>
      ))}
    </ul>
  </Col>
)
