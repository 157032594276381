import React from 'react';
import { Container, Row, Col, Table, Image } from 'react-bootstrap';
import { CDN_ROOT } from '../_helpers/constants';

// Components
import { 
  FilterSidebar, FilterInput, FilterInputRangeGroup, FilterCheckBox,
  FilterSelector, FilterTemplate, FilterFooter, Pagination, TemplateList 
} from './FilterTemplate';
import Detail from './Detail/Item';
import { Badge, FAIcon } from './Extras';

// Utilities
import utils from '../_helpers/utils';
import MetaDecorator from '../_helpers/MetaDecorator';

export default class Items extends FilterTemplate {
  constructor(props) {
    super(props);
    this.apiEndpoint = '/items';

    this.state = {
      search: new URLSearchParams(this.props.location.search),
      compactMode: Boolean(this.props.match.params.sapbdb),
      selected: this.props.match.params.sapbdb,
      sidebarCollapsed: true,
      items: [],
      filter: {
        page: 1,
        limit: 25,
        search: false,
        criminal: false,
        enforcer: false,
        preset: false,
        tradeable: false,
        jokerstore: false,
        innova: false,
        showtestitems: false,
        minrating: false,
        maxrating: false,
        minapbcash: false,
        maxapbcash: false,
        detail: false
      }
    }

    this.scrollbarRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      if (!this.props.match.params.sapbdb) {
        this.setState({ sapbdb: null, compactMode: false });
      }
    }
  }

  getList = () => {
    this.setState({ items: [] });
    this.request()
    .then(
      result => {
        this.setState({
          items: result.items,
          categories: {
            cat: result.filters.cat,
            subcat: result.filters.subcat,
            infracat: result.filters.infracat,
            cats: result.filters.cats ? result.filters.cats : [],
            subcats: result.filters.subcats ? result.filters.subcats : [],
            infracats: result.filters.infracats ? result.filters.infracats : [],
          }
        })},
      error => this.setState({ error })
    ).then(() => this.setState({ hasLoaded: true }))
  }

  updateFiltersFromSearch() {
    this.setState({
      filter: {
        page: this.checkFilter('page'),
        limit: this.checkFilter('limit'),
        search: this.checkFilter('search'),
        criminal: this.checkFilter('Criminal') === 'true',
        enforcer: this.checkFilter('Enforcer') === 'true',
        preset: this.checkFilter('Preset') === 'true',
        tradeable: this.checkFilter('Tradeable') === 'true',
        jokerstore: this.checkFilter('JokerStore') === 'true',
        innova: this.checkFilter('Innova') === 'true',
        showtestitems: this.checkFilter('ShowTestItems') === 'true',
        minrating: this.checkFilter('minRating'),
        maxrating: this.checkFilter('maxRating'),
        minapbcash: this.checkFilter('minAPBCash'),
        maxapbcash: this.checkFilter('maxAPBCash'),
        detail: this.checkFilter('Detail')
      }
    });
  }

  updateCategory = (name, selected) => {
    var cat;
    var subcat;
    var infracat;

    if (selected === 'Infracategory' && name === 'All') {
      subcat = this.findCategoryOption(this.state.categories.subcats, this.state.categories.subcat)
    } else if (selected === 'Subcategory' && name === 'All') {
      cat = this.findCategoryOption(this.state.categories.cats, this.state.categories.cat);
    } else if (selected === 'Infracategory') {
      infracat = this.findCategoryOption(this.state.categories.infracats, name);
    } else if (selected === 'Subcategory') {
      subcat = this.findCategoryOption(this.state.categories.subcats, name);
    } else if (selected === 'Category') {
      cat = this.findCategoryOption(this.state.categories.cats, name);
    } 

    // Clear category search states
    this.state.search.delete('cat');
    this.state.search.delete('subcat');
    this.state.search.delete('infracat');

    if (infracat) {
      this.setFilter('infracat', infracat.sAPBDB);
    } else if (subcat) {
      this.setFilter('subcat', subcat.sAPBDB);
    } else if (cat) {
      this.setFilter('cat', cat.sAPBDB);
    } else {
      this.setFilter('cat', false);
    }
  }

  findCategoryOption(list, name) {
    return list.find(x => x.sDisplayName === name || x.sAPBDB === name);
  }

  render() {
    return (
      <Container fluid className="items-component">
        <MetaDecorator title="Items" />
        <Row noGutters>
          <FilterSidebar 
            reload={this.getList} 
            collapsed={this.state.sidebarCollapsed}
            collapseSidebar={this.collapseSidebar}>
            {
              this.state.categories && this.state.categories.cats &&
                <FilterSelector 
                  name='Category' 
                  update={this.updateCategory}
                  selected={this.state.categories.cat}
                  options={this.state.categories.cats} />
            }
            {
              this.state.categories && this.state.categories.subcats.length > 0 && 
                <FilterSelector 
                  name='Subcategory' 
                  update={this.updateCategory}
                  selected={this.state.categories.subcat}
                  options={this.state.categories.subcats} />
            }
            {
              this.state.categories && this.state.categories.infracats.length > 0 && 
                <FilterSelector 
                  name='Infracategory' 
                  update={this.updateCategory}
                  selected={this.state.categories.infracat}
                  options={this.state.categories.infracats} />
            }
            <FilterInput 
              name='Search' 
              search={this.state.filter.search} 
              setFilter={this.setFilter} />
            <FilterInputRangeGroup 
              name='Rating' 
              setFilter={this.setFilter}
              minValue={this.state.filter.minrating}
              maxValue={this.state.filter.maxrating}
              min='0' 
              max='255' />
            {/*
            <FilterInputRangeGroup 
              name='APBCash'
              setFilter={this.setFilter}
              minValue={this.state.filter.minapbcash}
              maxValue={this.state.filter.maxapbcash}
              min='0' 
              max='999999' />*/}
            <FilterCheckBox 
              name='Criminal' 
              setFilter={this.setFilter} 
              active={this.state.filter.criminal} />
            <FilterCheckBox 
              name='Enforcer' 
              setFilter={this.setFilter} 
              active={this.state.filter.enforcer} />
            <FilterCheckBox 
              name='Tradeable' 
              setFilter={this.setFilter} 
              active={this.state.filter.tradeable} />
            <FilterCheckBox 
              name='Joker Store' 
              setFilter={this.setFilter} 
              active={this.state.filter.jokerstore} />            
            <FilterCheckBox 
              name='Innova' 
              setFilter={this.setFilter} 
              active={this.state.filter.innova} />
            <FilterCheckBox 
              name='Show Test Items' 
              setFilter={this.setFilter} 
              active={this.state.filter.showtestitems} />
            <FilterCheckBox 
              name='Detail' 
              displayName='Full Detail (experimental)'
              setFilter={this.setFilter} 
              active={this.state.filter.detail} />
          </FilterSidebar>
          <TemplateList 
            list={this.state.items} 
            hasLoaded={this.state.hasLoaded}
            error={this.state.error}
            compactMode={this.state.compactMode}
            collapsed={this.state.sidebarCollapsed}
            scrollbarRef={this.scrollbarRef} >
            <Table className="filter-table">
              <tbody>
                {this.state.items.map(item => (
                <tr 
                  key={item.sAPBDB} 
                  onClick={() => this.setSelected(item.sAPBDB)}
                  className={item.sAPBDB === this.state.selected ? 'selected' : ''}>
                  <td className="align-middle icon">
                    <img src={item.eHUDImage} alt={item.sDisplayName} onError={utils.handleImageError} />
                  </td>
                  <td className="align-middle item-displayname">
                    <button 
                      className="button-link text-left"
                      dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}} />
                    <br />
                    {item.sCategory === 'Weapons' && item.detail && <ItemFnMods detail={item.detail} />}
                    {item.sCategory === 'Vehicles' && item.detail && <ItemFnMods detail={item.detail} />}
                    <small className="text-muted">
                      {item.sCategory}
                      {item.sCategory === 'Clothing' && item.detail && <ItemGender detail={item.detail} />}
                    </small>
                  </td>
                  <td className="align-middle">
                    {item.sAPBDB.endsWith('_OTW') && <Badge type='warning' text='OTW' />}
                    {item.sAPBDB.endsWith('_Joker') && <Badge type='danger' text='JT' />}
                    {item.sAPBDB.endsWith('_RUS') && <Badge type='primary' text='RUS' />}
                    {!!item.bIsTestItem && <FAIcon title="Test Item" icon='fas fa-exclamation-triangle' />}
                  </td>
                  {!this.state.compactMode &&
                    <>
                      <td className="align-middle">
                        ${item.eDefaultPrice[0] ? item.eDefaultPrice[0].nCostAPBCash.toLocaleString() : item.eDefaultPrice.nCostAPBCash.toLocaleString()}
                        <br />
                        {item.nCostRewardTokens > 0 && 'JT' + item.eDefaultPrice.nCostRewardTokens.toLocaleString()}
                      </td>
                      <td className="align-middle">R {item.nMinRating}</td>
                      <FactionIcon crim={item.bCriminal} enf={item.bEnforcer} />
                    </>
                  }
                </tr>
                ))}
              </tbody>
            </Table>
            <FilterFooter>
              <Pagination 
                page={this.state.filter.page}
                compactMode={this.state.compactMode}
                itemCount={this.state.items.length}
                setFilter={this.setFilter}
                scrollbarRef={this.scrollbarRef} />
            </FilterFooter>
          </TemplateList>
          {
            this.state.compactMode &&
              <Col md={this.state.sidebarCollapsed ? 7 : 9}>
                <Detail 
                  sapbdb={this.state.selected}
                  history={this.props.history} 
                  setSelected={this.setSelected}
                  toggleCompactMode={this.toggleCompactMode} 
                />
              </Col>
          }
        </Row>
      </Container>
    );
  }
}

const ItemFnMods = props => (
  <span className="mr-1">
    <ItemFnMod mod={props.detail.eFnMod_0} />
    <ItemFnMod mod={props.detail.eFnMod_1} />
    <ItemFnMod mod={props.detail.eFnMod_2} />
    {props.detail.eFnMod_3 && <ItemFnMod mod={props.detail.eFnMod_3} />}
  </span>
)

const ItemFnMod = props => (
  <Image width={24} height={24} style={{ backgroundColor: 'black', marginRight: '.2rem' }}
    src={props.mod.eInventoryItemType.eHUDImage}
    alt={props.mod.eInventoryItemType.sDisplayName} 
  />
)

const ItemGender = props => (
  <span> | Gender: {!!props.detail.bMale && 'Male'}{!!props.detail.bFemale && 'Female'}</span>
)

export const FactionIcon = props => (
  <td className={props.textRight ? 'text-right' : 'align-middle'}>
    {!props.enf || (
      <Image src={`${CDN_ROOT}/apbdb/enforcer.webp`} alt="Enforcer" />
    )}
    {!props.crim || (
      <Image src={`${CDN_ROOT}/apbdb/criminal.webp`} alt="Criminal" />
    )}
  </td>
);
