import React from 'react';
import { BASE_ROOT } from '../_helpers/constants';

// Utilities
import utils from '../_helpers/utils';

const userContext = React.createContext({user: {}});

function login(username, password) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({name: username, password: password})
  }

  return fetch(`${BASE_ROOT}/auth/login`, options)
    .then(res => res.json())
    .then(
      result => {
        if (result) {
          localStorage.setItem('accessToken', result.accessToken);
          localStorage.setItem('refreshToken', result.refreshToken);
        }
      }
    )
}

const logout = async () => {
  const options = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: localStorage.getItem('refreshToken') })
  }
  
  await fetch(`${BASE_ROOT}/auth/logout`, options)
    .then(res => res.text())
    .then(
      () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }
    )
}

const refresh = async () => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token: localStorage.getItem('refreshToken') })
  }

  await fetch(`${BASE_ROOT}/auth/refresh`, options)
    .then(async res => {
      if (res.status === 403 || res.status === 401) {
        await logout();
      } else {
        return res.json();
      }
    })
    .then(
      result => {
        if (result) {
          localStorage.setItem('accessToken', result.accessToken);
        }
      }
    )
}

function register(username, password, email) {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ name: username, password: password, email: email })
  }

  return fetch(`${BASE_ROOT}/auth/register`, options);
}

function getUser() {
  return utils.parseJwt(localStorage.getItem('refreshToken'));
}

function getAccessToken() {
  return utils.parseJwt(localStorage.getItem('accessToken'));
}

const checkAccessExpired = async () => {
  const user = utils.parseJwt(localStorage.getItem('accessToken'));
  if (!user) return;

  if (new Date().getTime() / 1000 > user.exp - 30) {
    await refresh();
  }
}

const isAuthenticated = getAccessToken() !== null;

const authenticatedRequest = endpoint => {
  const options = {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    })
  }

  return fetch(BASE_ROOT + endpoint, options).then(res => res.json());
}

export const authService = {
    login,
    logout,
    refresh,
    register,
    getUser,
    getAccessToken,
    checkAccessExpired,
    authenticatedRequest,
    isAuthenticated,
    userContext
}