import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Table, Image } from 'react-bootstrap';

// Components
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Rating.scss';

export default class Rating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      heatError: false,
      maxLevel: 255
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/rating', 'rating');
  }

  render() {
    if (this.state.ratingError) {
      return <ErrorMessage error={this.state.ratingError}/>;
    }

    if (this.state.ratingLoaded) {
      let ratings = [];
      this.state.rating.forEach(r => {
        if (r.nRating <= this.state.maxLevel) {
          ratings.push(r);
        }
      })

      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <Container className="rating-component">
            <MetaDecorator title="Rating" />
            <Table>
              <thead>
                <tr>
                  <th>Rating</th>
                  <th>Crim Title</th>
                  <th>Enf Title</th>
                  <th>Crim Training</th>
                  <th>Enf Training</th>
                  <th>Crim Green</th>
                  <th>Enf Green</th>
                  <th>Crim Bronze</th>
                  <th>Enf Bronze</th>
                  <th>Crim Silver</th>
                  <th>Enf Silver</th>
                  <th>Crim Gold</th>
                  <th>Enf Gold</th>
                </tr>
              </thead>
              <tbody>
              {ratings.map(r => (
                <tr key={r.sAPBDB}>
                  <td>{r.nRating}</td>
                  <td>{r.sDisplayNameCrim}</td>
                  <td>{r.sDisplayNameEnf}</td>
                  <td><Image fluid src={r.eHUDTextureIconCriminal_0} /></td>
                  <td><Image fluid src={r.eHUDTextureIconEnforcer_0} /></td>
                  <td><Image fluid src={r.eHUDTextureIconCriminal_1} /></td>
                  <td><Image fluid src={r.eHUDTextureIconEnforcer_1} /></td>
                  <td><Image fluid src={r.eHUDTextureIconCriminal_2} /></td>
                  <td><Image fluid src={r.eHUDTextureIconEnforcer_2} /></td>
                  <td><Image fluid src={r.eHUDTextureIconCriminal_3} /></td>
                  <td><Image fluid src={r.eHUDTextureIconEnforcer_3} /></td>
                  <td><Image fluid src={r.eHUDTextureIconCriminal_4} /></td>
                  <td><Image fluid src={r.eHUDTextureIconEnforcer_4} /></td>
                </tr>
              ))}
              </tbody>
            </Table>
          </Container>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}