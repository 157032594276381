import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Row, Col, Table } from 'react-bootstrap';

// Components
import Detail from '../Achievement/Achievement';
import { FilterTemplate, FilterSidebar, FilterSelector, FilterInput, 
         FilterCheckBox, TemplateList } from '../../FilterTemplate';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

export default class Achievements extends FilterTemplate {
  constructor(props) {
    super(props);
    this.title = 'Achievements';
    this.apiEndpoint = '/achievements';

    this.state = {
      search: new URLSearchParams(this.props.location.search),
      compactMode: Boolean(this.props.match.params.sapbdb),
      selected: this.props.match.params.sapbdb,
      sidebarCollapsed: true,
      achievements: [],
      filter: {
        search: false,
        faction: 'Both',
        showHidden: false,
      },
    }

    // Preset factions for selection
    this.factions = [
      { sDisplayName: 'Both' },
      { sDisplayName: 'Criminal' },
      { sDisplayName: 'Enforcer' }
    ]
  }

  getList = () => {
    this.request()
      .then(
        result => this.setState({ achievements: result }),
        error => this.setState({ error })
      )
      .then(() => this.setState({ hasLoaded: true }))
  }

  updateFiltersFromSearch() {
    this.setState({
      filter: {
        search: this.checkFilter('search'),
        faction: this.checkFilter('Faction'),
        showHidden: this.checkFilter('ShowHidden'),
      }
    });
  }

  updateFaction = (value, state) => {
    if (value === 'Both') {
      this.setFilter(state, false);
    } else {
      this.setFilter(state, value);
    }
  }

  description(desc) {
    return desc.split('.')[0];
  }
  
  render() {
    return (
      <Container fluid>
        <MetaDecorator title={this.title} />
        <Row noGutters>
          <FilterSidebar 
            reload={this.getList}
            collapsed={this.state.sidebarCollapsed}
            collapseSidebar={this.collapseSidebar}>
            <FilterSelector noAll
              name='Faction' 
              update={this.updateFaction}
              selected={this.state.filter.faction}
              options={this.factions} />
            <FilterInput 
              name="Search" 
              search={this.state.filter.search} 
              setFilter={this.setFilter} />
            <FilterCheckBox 
              name='Show Hidden' 
              setFilter={this.setFilter} 
              active={this.state.filter.showHidden} />
          </FilterSidebar>
          <TemplateList 
            list={this.state.achievements}
            hasLoaded={this.state.hasLoaded}
            error={this.state.error}
            compactMode={this.state.compactMode}
            collapsed={this.state.sidebarCollapsed}>
            <Table className="filter-table">
              <tbody>
              {this.state.achievements.map(achievement => (
                <tr 
                  key={achievement.sAPBDB} 
                  onClick={() => this.setSelected(achievement.sAPBDB)}
                  className={this.state.selected === achievement.sAPBDB ? 'selected' : ''}>
                  <td className="icon">
                    <img 
                      className="icon-small"
                      alt={achievement.sDisplayName} 
                      src={achievement.eFirstMilestone.eRoleIcon}
                      onError={utils.handleImageError} />
                  </td>
                  <td className="align-middle">
                    {achievement.sDisplayName} <br />
                    <small className="text-muted">
                      {this.description(achievement.sDescription)}
                    </small>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          </TemplateList>
          {this.state.compactMode &&
            <Col md={this.state.sidebarCollapsed ? 7 : 9}>
              <SimpleBar style={{ height: 'calc(100vh - 56px)' }} 
                         className="detail-component">
                <Detail 
                  selected={this.state.selected}
                  toggleCompactMode={this.toggleCompactMode} />
              </SimpleBar>
            </Col>
          }
        </Row>
      </Container>
    )
  }
}
