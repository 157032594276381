import React from 'react';
import CrossfadeImage from 'react-crossfade-image';
import { Container, Row, Col, Image, ListGroup } from 'react-bootstrap';
import { CDN_ROOT } from '../../_helpers/constants';

// Styles
import '../../styles/Progression.scss';

export default class Progression extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      background: `${CDN_ROOT}/apbdb/progression/Constant_BG.webp`,
      imageCrim: `${CDN_ROOT}/apbdb/progression/CriminalFactionicon_Unselected.webp`,
      imageEnf: `${CDN_ROOT}/apbdb/progression/EnforcerFactionicon_Unselected.webp`
    }

    this.handleCrimMouseOver = this.handleCrimMouseOver.bind(this);
    this.handleCrimMouseLeave = this.handleCrimMouseLeave.bind(this);
    this.handleEnfMouseOver = this.handleEnfMouseOver.bind(this);
    this.handleEnfMouseLeave = this.handleEnfMouseLeave.bind(this);
  }


  handleCrimMouseOver() {
    this.setState({
      imageCrim: `${CDN_ROOT}/apbdb/progression/CriminalFactionicon.webp`
    });
  }

  handleCrimMouseLeave() {
    this.setState({
      imageCrim: `${CDN_ROOT}/apbdb/progression/CriminalFactionicon_Unselected.webp`
    });
  }

  handleEnfMouseOver() {
    this.setState({
      imageEnf: `${CDN_ROOT}/apbdb/progression/EnforcerFactionicon.webp`
    });
  }

  handleEnfMouseLeave() {
    this.setState({
      imageEnf: `${CDN_ROOT}/apbdb/progression/EnforcerFactionicon_Unselected.webp`
    });
  }

  render() {
    return (
      <div className="progression-component">
        <Image src={this.state.background} alt="background" className="background" />
        <Container fluid style={{background: this.state.background}}>
          <Row>
            <Col className="faction-area" 
                 onMouseOver={this.handleCrimMouseOver} 
                 onMouseLeave={this.handleCrimMouseLeave} >
              <div className="vertical-center">
                <CrossfadeImage alt="CriminalFactionicon" src={this.state.imageCrim} />
                <h1 className="text-center">CRIMINAL</h1>
                <ListGroup>
                  <FactionLink name='ACHIEVEMENTS' />
                  <FactionLink name='CONTACTS' />
                  <FactionLink name='ORGANISATIONS' />
                  <FactionLink name='ROLES' />
                </ListGroup>
              </div>
            </Col>
            <Col className="faction-area"
                 onMouseOver={this.handleEnfMouseOver} 
                 onMouseLeave={this.handleEnfMouseLeave} >
              <div className="vertical-center">
                <CrossfadeImage alt="CriminalFactionicon" src={this.state.imageEnf} />
                <h1 className="text-center">ENFORCER</h1>
                <ListGroup>
                  <FactionLink name='ACHIEVEMENTS' />
                  <FactionLink name='CONTACTS' />
                  <FactionLink name='ORGANISATIONS' />
                  <FactionLink name='ROLES' />
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};


class FactionLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      img: `${CDN_ROOT}/apbdb/progression/FactionSelectbulletpoint_Unselected.webp`
    }

    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
  }

  handleMouseOver() {
    this.setState({
      img: `${CDN_ROOT}/apbdb/progression/FactionSelectbulletpoint.webp`
    });
  }

  handleMouseLeave() {
    this.setState({
      img: `${CDN_ROOT}/apbdb/progression/FactionSelectbulletpoint_Unselected.webp`
    });
  }

  render() {
    return (
      <ListGroup.Item>
        <a href={this.props.link} 
           onMouseOver={this.handleMouseOver}
           onMouseLeave={this.handleMouseLeave}>
          <CrossfadeImage src={this.state.img} width={32} height={32} />
          <span>{this.props.name}</span>
        </a>
      </ListGroup.Item>
    )
  }
}