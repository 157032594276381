import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image, Table, Tab, Accordion, Card } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Components
import { ErrorMessage, FactionIcon } from '../../Extras';
import { FactionIcon as ItemsFactionIcon } from '../../Items';
import { WideTemplate, WideHeader, WideDescription, WideTabs } from '../../WideTemplate';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Contact.scss';

export default class Contact extends WideTemplate {
  constructor(props) {
    super(props);

    this.state = { 
      selectedTab: 'unlocks'
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/contacts/${this.props.match.params.sapbdb}`, 'contact');

    if (this.props.history.location.hash) {
      this.setState({ selectedTab: this.props.history.location.hash.substr(1) });
    }
  }

  switchTab = (key) => {
    this.setState({ selectedTab: key });
    this.props.history.replace({
        hash: `${key}`
    })
  }

  render() {
    if (this.state.contactError) {
      return <ErrorMessage error={this.state.contactError} />;
    }

    if (this.state.contactLoaded) {
      let org = this.state.contact.eOrganisation;
      let district = this.state.contact.eDistrict;

      // Link colors based on faction
      let accentColor = { color: 'gray' };
      if (org.eFaction.sAPBDB === 'Criminal') {
        accentColor = { color: 'red' };
      } else if (org.eFaction.sAPBDB === 'Enforcer') {
        accentColor = { color: 'cornflowerblue' };
      }

      let subTitle = (
        <>
          <NavLink 
            to={`/districts/${district.sAPBDB}`} 
            style={accentColor}>
            {district.sDisplayName}
          </NavLink>
          <span className="text-muted">{' // '}</span>
          <NavLink to={`/factions/${org.eFaction.sAPBDB}`} 
                  style={accentColor}>
            {org.eFaction.sDisplayName}
          </NavLink>
          <span className="text-muted">{' // '}</span>
          <NavLink to={`/organisations/${org.sAPBDB}`} 
                  style={accentColor}>
            {org.sName}
          </NavLink>
        </>
      )

      let contentRight = (
        <>
        <NavLink to={`/organisations/${org.sAPBDB}`}>
          <Image fluid src={org.eOrganisationIcon} alt={org.sDisplayName} />
        </NavLink>
        <NavLink to={`/factions/${org.eFaction.sAPBDB}`}>
          <FactionIcon faction={org.eFaction.eFaction} />
        </NavLink>
        </>
      )

      return (
        <Container fluid className="contact-component">
          <MetaDecorator 
            title={this.state.contact.sTitle}
            ogTitle={this.state.contact.sTitle}
            ogDescription={this.state.contact.sDescription}
            ogUrl={`/contacts/${this.state.contact.sAPBDB}`}
            ogImage={this.state.contact.eContactIcon} />
          <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
            <Row noGutters>
              <Image 
                alt="Contacts Background" 
                src={`${CDN_ROOT}/apbdb/background_detail.webp`} 
                className="background-image opacity-5" />
              <WideHeader 
                title={this.state.contact.sTitle}
                subTitle={subTitle}
                contentRight={contentRight} />
              <WideDescription>
                <Col md={3} className="text-center">
                  <Image 
                    fluid
                    className="contact-picture pb-1" 
                    src={`${CDN_ROOT}/apbdb/contacts/detail/${this.state.contact.sAPBDB}.webp`}
                    onError={utils.handleImageError} />
                  <ContactLevels levels={this.state.contact.aContactLevels} />
                  {this.state.contact.eContactReferrals.unlockedBy.length > 0 && 
                    <UnlockedContact 
                      title="Unlocked By" 
                      refs={this.state.contact.eContactReferrals.unlockedBy} 
                    />
                  }
                  {this.state.contact.eContactReferrals.unlocks.length > 0 && 
                    <UnlockedContact 
                      title="Unlocks Contact" 
                      refs={this.state.contact.eContactReferrals.unlocks} 
                    />
                  }
                </Col>
                <Col md={9}>
                  {this.state.contact.sDescription !== 'None' 
                    ? <p className="pre-wrap">{this.state.contact.sDescription}</p>
                    : <p>No description for this contact.</p>
                  }
                </Col>
              </WideDescription>
              <ContactTabs 
                contact={this.state.contact}
                switchTab={this.switchTab}
                selectedTab={this.state.selectedTab} />
            </Row>
          </SimpleBar>
        </Container>
      )
    } else {
      return utils.loadingCircle();
    } 
  }
}

class ContactLevels extends React.Component {
  calculateTotal(level) {
    let total = 0;
    for (let i = 0; i < level.nLevel; i++) {
      total += this.props.levels[i].nStandingThreshold;
    }
    return total;
  }

  render() {
    return (
    <Accordion className="contact-levels">
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          Contact Levels
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Rep</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {this.props.levels.map(level => (
                  <tr key={level.sAPBDB}>
                    <td>{level.nLevel}</td>
                    <td>{level.nStandingThreshold.toLocaleString()}</td>
                    <td>{this.calculateTotal(level).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
    )
  }
}

const UnlockedContact = props => (
  <Card className="contact-unlocked">
    <Card.Header>{props.title}</Card.Header>
    <Card.Body>
        {props.refs.map(ref => (
          <a key={ref.sAPBDB} href={`/contacts/${ref.sAPBDB}`}>
            <div className="ref">
              <Image src={ref.eContactIcon} alt={ref.sTitle} />
              {ref.sTitle}
            </div>
          </a>
        ))}
    </Card.Body>
  </Card>
)

class ContactTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasUnlocks: false,
      hasDailies: false,
      hasRandomRewards: false,
      hasMails: false,
      hasRandomMails: false,
      hasMissions: false
    }
  }

  componentDidMount() {
    // Contact Levels
    this.props.contact.aContactLevels.forEach(level => {
      // Unlocks
      if (level.eRewardPackage.eItems.length > 0 ||
        level.eRewardPackage.eChildPackage.eItems.length > 0) {
          this.setState({ hasUnlocks: true });
        }
      // Dailies
      if (level.aDailyActivities.length > 0) {
        this.setState({ hasDailies: true });
      }
      // Mails
      if (level.sRewardMailSubject !== 'None' && !level.sRewardMailSubject.startsWith('DNT')) {
        this.setState({ hasMails: true });
      }
    })

    // Random rewards
    this.props.contact.aRandomRewards.forEach(reward => {
      if (reward.sRewardMailBody === 'None') {
        this.setState({ hasRandomRewards: true });
      }
      if (reward.sRewardMailBody !== 'None') {
        this.setState({ hasRandomMails: true });
      }
    })

    // missions
    if (this.props.contact.aMissions.length > 0) {
      this.setState({ hasMissions: true });
    }
  }

  render() {
    return (
      <WideTabs activeKey={this.props.selectedTab} onSelect={this.props.switchTab}>
        <Tab 
          eventKey="unlocks" 
          disabled={!this.state.hasUnlocks}
          title={<span><i className="fas fa-unlock" /> UNLOCKS</span>}
        >
          <Unlocks levels={this.props.contact.aContactLevels} />
        </Tab>
        <Tab 
          eventKey="mails" 
          disabled={!this.state.hasMails}
          title={<span><i className="fas fa-envelope" /> UNLOCK MAILS</span>}
        >
          <Mails levels={this.props.contact.aContactLevels} />
        </Tab>
        <Tab 
          eventKey="randomRewards" 
          disabled={!this.state.hasRandomRewards}
          title={<span><i className="far fa-question-circle" /> RANDOM REWARDS</span>}
        >
          <RandomRewards rewards={this.props.contact.aRandomRewards} />
        </Tab>
        <Tab 
          eventKey="randomMails" 
          disabled={!this.state.hasRandomMails}
          title={<span><i className="fas fa-envelope" /> RANDOM MAILS</span>}
        >
          <RandomMails mails={this.props.contact.aRandomRewards} />
        </Tab>
        <Tab 
          eventKey="missions" 
          disabled={!this.state.hasMissions}
          title={<span><i className="fas fa-bullseye" /> MISSIONS</span>}
        >
          <Missions missions={this.props.contact.aMissions} />
        </Tab>
        <Tab 
          eventKey="activities" 
          disabled={!this.state.hasDailies}
          title={<span><i className="fas fa-calendar-day" /> DAILIES</span>}
        >
          <Dailies levels={this.props.contact.aContactLevels} />
        </Tab>
      </WideTabs>
    );
  }
}

class Unlocks extends React.Component {
  render() {
    if (this.props.levels.length > 1) {
      return (
        <Table>
          <thead>
            <tr>
              <th className="text-center">Level</th>
              <th></th>
              <th>Item</th>
              <th>Rating</th>
              <th>Faction</th>
            </tr>
          </thead>
          <tbody>
          {this.props.levels.map(level => (
              <Unlock key={level.id} level={level} />
          ))}
          </tbody>
        </Table>
      )
    } else {
      return <p>No unlocks for this contact.</p>;
    }
  }
}

class Unlock extends React.Component {
  render() {
    let content = [];

    utils.combineRewardPackageItems(this.props.level.eRewardPackage).forEach(unlock => {
      content.push((
        <tr key={unlock.sAPBDB}>
          <td className="text-center small-width">{this.props.level.nLevel}</td>
          <td className="small-width">
            <NavLink to={`/items/${unlock.sAPBDB}`}>
              <Image 
                src={unlock.eHUDImage} 
                alt={unlock.sDisplayName}
                onError={utils.handleImageError} />
            </NavLink>
          </td>
          <td>
            <NavLink 
              to={`/items/${unlock.sAPBDB}`}
              dangerouslySetInnerHTML={{__html: utils.convertColorCode(unlock.sDisplayName)}}
            />
          </td>
          <td>R {unlock.nMinRating}</td>
          <ItemsFactionIcon enf={unlock.bEnforcer} crim={unlock.bCriminal} />
        </tr>
      ))
    })

    return content;
  }
}

class Dailies extends React.Component {
  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Level</th>
            <th>Activity</th>
          </tr>
        </thead>
        <tbody>
          {this.props.levels.map(level => (
            <Daily key={'daily_'+level.id} level={level} />
          ))}
        </tbody>
      </Table>
    )
  }
}

class Daily extends React.Component {
  render() {
    let content = [];

    this.props.level.aDailyActivities.forEach(daily => {
      content.push((
        <tr key={daily.sAPBDB}>
          <td className="text-center small-width">{this.props.level.nLevel}</td>
          <td>
            <Accordion>
              <Card className="contact-card">
                <Accordion.Toggle as={Card.Header} 
                                  eventKey={this.props.level.eContactLevel.toString()}>
                  {`
                    ${daily.sTitle}
                    (${daily.eDailyActivity.eRedeemableReward.sMailSubject})
                    (${daily.eDailyActivity.eProbability.sAPBDB} - 
                    ${daily.eDailyActivity.eProbability.fCoefficient * 100}%)
                  `}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={this.props.level.eContactLevel.toString()}>
                  <Card.Body>
                    <p dangerouslySetInnerHTML={{
                      __html: utils.convertColorCode(daily.sLongDescription)}} />
                    <p dangerouslySetInnerHTML={{
                      __html: utils.convertColorCode(daily.sHUDDescription)}} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </td>
        </tr>
      ))
    })

    return content;
  }
}

class RandomRewards extends React.Component {
  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Weight</th>
            <th></th>
            <th>Item</th>
            <th>Rating</th>
            <th>Faction</th>
            <th>Offer Once</th>
          </tr>
        </thead>
        <tbody>
          {this.props.rewards.map(reward => (
            <RandomReward key={reward.sAPBDB} reward={reward} />
          ))}
        </tbody>
      </Table>
    )
  }
}

class RandomReward extends React.Component {
  render() {
    let content = [];

    utils.combineRewardPackageItems(this.props.reward.eReward).forEach(item => {
      content.push((
        <tr key={item.sAPBDB}>
          <td className="text-center small-width">{this.props.reward.fWeight.toLocaleString()}</td>
          <td className="small-width">
            <NavLink to={`/items/${item.sAPBDB}`}>
              <Image 
                src={item.eHUDImage} 
                alt={item.sDisplayName}
                onError={utils.handleImageError} />
            </NavLink>
          </td>
          <td>
            <NavLink to={`/items/${item.sAPBDB}`}>
              {item.sDisplayName}
            </NavLink>
          </td>
          <td>R {item.nMinRating}</td>
          <ItemsFactionIcon enf={item.bEnforcer} crim={item.bCriminal} />
          <td>{utils.displayTrueFalse(this.props.reward.bOfferOnce)}</td>
        </tr>
      ))
    })

    return content;
  }
}

class Mails extends React.Component {
  render() {
    let mails = [];

    this.props.levels.forEach(level => {
      if (level.sRewardMailSubject !== 'None' && !level.sRewardMailSubject.startsWith('DNT')) {
        mails.push((
          <tr key={'mail_'+level.id}>
            <td className="text-center small-width">{level.nLevel}</td>
            <td>
              <Accordion>
                <Card className="contact-card">
                  <Accordion.Toggle as={Card.Header} 
                                    eventKey={'mail_' + level.id.toString()}>
                    {level.sRewardMailSubject}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={'mail_' + level.id.toString()}>
                    <Card.Body>
                      <p className="pre-wrap">
                        {level.sRewardMailBody}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        ))
      }
    })

    return (
      <Table>
        <thead>
          <tr>
            <th className="text-center">Level</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {mails}
        </tbody>
      </Table>
    )
  }
}

class RandomMails extends React.Component {
  render() {
    let mails = [];

    this.props.mails.forEach(mail => {
      if (mail.sRewardMailSubject !== 'None') {
        mails.push((
          <tr key={'randomMail_'+mail.sAPBDB}>
            <td>
              <Accordion>
                <Card className="contact-card">
                  <Accordion.Toggle as={Card.Header} 
                                    eventKey={'randomMail_' + mail.id.toString()}>
                    {mail.sRewardMailSubject}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={'randomMail_' + mail.id.toString()}>
                    <Card.Body>
                      <p className="pre-wrap">
                        {mail.sRewardMailBody}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </td>
          </tr>
        ))
      }
    })

    return (
      <Table>
        <thead>
          <tr>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {mails}
        </tbody>
      </Table>
    )
  }
}

class Missions extends React.Component {
  render() {
    return (
      <Table>
        <thead>
          <tr>
            <th>Mission</th>
            <th>Group Size</th>
          </tr>
        </thead>
        <tbody>
          {this.props.missions.map(mission => (
            <tr key={mission.sAPBDB}>
              <td>
                <NavLink to={`/missions/${mission.sAPBDB}`}>
                  {mission.sMissionTitle}
                </NavLink>
              </td>
              <td>{mission.nGroupSizeMin} / {mission.nGroupSizeMax}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}
