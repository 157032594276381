import React from 'react';
import { Col, Table } from 'react-bootstrap';

// Components
import { authService } from '../../../_services/auth';

export class DiscordBot extends React.Component {
  constructor(props) {
    super(props);

    this.state = { logs: [] }
  }

  logLevelColor(level) {
    switch(level) {
      case 'warning':  return 'bg-warning';
      case 'error':  return 'bg-danger';
      default: return 'bg-info';
    }
  }

  logDateTime(timestamp) {
    return new Date(timestamp).toLocaleString();
  }

  componentDidMount() {
    setInterval(() => {
      authService.authenticatedRequest('/admin/dashboard/discord/log')
        .then(data => this.setState({ logs: data }));
    }, 1000)
  }

  render() {
    return (
      <Col className="dashboard-column" md={6}>
        <h5>Discord Live Log</h5>
        <Table className="table-lite">
          <tbody>
            {this.state.logs.map(l => (
              <tr key={l.id} className={`small ${this.logLevelColor(l.level)}`}>
                <td>{this.logDateTime(l.timestamp)}</td>
                <td>{l.level}</td>
                <td>{l.module}</td>
                <td style={{ wordWrap: "break-word" }}>{l.content}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    )
  }
}