import React from 'react';
import { NavLink } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { Container, Form, Button } from 'react-bootstrap';

// Utilities
import utils from '../../_helpers/utils';
import { authService } from '../../_services/auth';

export default class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      username: null,
      password: null,
      secondPassword: null,
      email: null,
      registered: false
    }
  }

  changeUsername = (e) => {
    this.setState({ username: e.target.value, error: false });
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value, error: false });
  }

  changeSecondPassword = (e) => {
    this.setState({ secondPassword: e.target.value, error: false });
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value, error: false });
  }

  handleRegister = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    if (this.state.password !== this.state.secondPassword) {
      this.setState({ error: 'Passwords do not match' });
    } else {
      authService.register(this.state.username, this.state.password, this.state.email)
        .then(res => {
          if (res.status === 409) {
            return res.text()
          } else if (res.status === 201) {
            this.setState({ registered: true });
          }
        })
        .then(data => this.setState({ error: data }))
        .catch(() => {
          this.setState({ error: 'Unknown error, check connectivity' });
        })
    }
    this.setState({ loading: false });
  }

  render() {
    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
        <Container className="screen-center">
          <authService.userContext.Consumer>
          {({user, logout}) => {
          if (user) {
            return (
              <p className="text-center">
                You are already logged in, {user.name}! <br />
                <Button variant="primary" onClick={logout}>
                  Logout?
                </Button>
              </p>
            )
          }

          if (this.state.registered) {
            return (
              <p>
                Successfully registerd! <NavLink to="/login">Login?</NavLink>
              </p>
            )
          }
          
          return (
            <Form onSubmit={this.handleRegister}>
              <h2>Register</h2>
              <Form.Group controlId="formRegisterUsername">
                <Form.Control 
                  required
                  placeholder="username" 
                  onChange={this.changeUsername} />
              </Form.Group>
              <Form.Group controlId="formRegisterPassword">
                <Form.Control 
                  required
                  type="password" 
                  placeholder="password" 
                  onChange={this.changePassword} />
              </Form.Group>
              <Form.Group controlId="formRegisterSecondPassword">
                <Form.Control 
                  required
                  type="password" 
                  placeholder="repeat password" 
                  onChange={this.changeSecondPassword} />
              </Form.Group>
              <Form.Group controlId="formRegisterEmail">
                <Form.Control 
                  required
                  type="email" 
                  placeholder="email address" 
                  onChange={this.changeEmail} />
              </Form.Group>
              {this.state.error && 
                <p className="text-danger">{this.state.error}</p>
              }
              <Button variant="primary" type="submit">
                {this.state.loading ? utils.loadingCircle(true) : 'Register'}
              </Button>
            </Form>
          )}}
          </authService.userContext.Consumer>
        </Container>
      </SimpleBar>
    )
  }
}