import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Row, Col } from 'react-bootstrap';


// Components
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Medals.scss';

export default class Medals extends React.Component {
  constructor(props) {
    super(props);

    this.state = { }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/medals/categories', 'categories');
  }

  render() {
    if (this.state.categoriesError) {
      return <ErrorMessage error={this.state.categoriesError} />;
    }

    if (this.state.categoriesLoaded) {
      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <MetaDecorator title="Medals" />
          <Container className="medals-component">
          <Row noGutters>
            {this.state.categories.map(cat => (
              <ScoreCategory key={cat.sAPBDB} cat={cat} />
            ))}
            </Row>
          </Container>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    } 
  }    
}

class ScoreCategory extends React.Component {
  scoreNumber = score => {
    if (score.toString().startsWith('-')) {
      return <span className="text-danger">{score}</span>;
    }
    return <span className="text-success">+{score}</span>;
  }

  render() {
    if (this.props.cat.aMedals.length > 0) {
      return (
        <Col md={6}>
          <h1 className="mt-3 display-4 text-center">
            {this.props.cat.sDescription.toUpperCase()}
          </h1>
          {this.props.cat.aMedals.map(medal => (
            <div className="medal" key={medal.sAPBDB}>
              <div className="header">
                <img
                  width={36}
                  height={36}
                  src={medal.eMedalIcon}
                  alt={medal.sTitle}
                  onError={utils.handleImageError}
                />
                <h5>
                  {medal.sTitle} {this.scoreNumber(medal.nScore)}
                </h5>
              </div>
              <span>{medal.sDescription}</span>
            </div>
          ))}
        </Col>
      )
    } else {
      return null;
    }    
  }
}