import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image, Tab, Table } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Components
import { ErrorMessage } from '../../Extras';
import { FactionIcon } from '../../Extras';
import { WideTemplate, WideHeader, WideDescription, WideTabs } from '../../WideTemplate';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

export default class Organisation extends WideTemplate {
  constructor(props) {
    super(props);

    this.state = { 
      selectedTab: 'contacts'
    }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/organisations/${this.props.match.params.sapbdb}`, 'organisation');

    if (this.props.history.location.hash) {
      this.setState({ selectedTab: this.props.history.location.hash.substr(1) });
    }
  }

  switchTab = (key) => {
    this.setState({ selectedTab: key });
    this.props.history.replace({
        hash: `${key}`
    })
  }

  render() {
    if (this.state.organisationError) {
      return <ErrorMessage error={this.state.organisationError} />;
    }

    if (this.state.organisationLoaded) {
      let accentColor = { color: 'gray' };
      if (this.state.organisation.eFaction.sAPBDB === 'Criminal') {
        accentColor = { color: 'red' };
      } else if (this.state.organisation.eFaction.sAPBDB === 'Enforcer') {
        accentColor = { color: 'cornflowerblue' };
      }

      let subTitle = (
        <NavLink 
          to={`/factions/${this.state.organisation.eFaction.sAPBDB}`} 
          style={accentColor}>
          {this.state.organisation.eFaction.sDisplayName}
        </NavLink>
      )

      let contentRight = (
        <>
          <Image 
            fluid 
            src={this.state.organisation.eOrganisationIcon} 
            alt={this.state.organisation.sDisplayName} 
          />
          <NavLink to={`/factions/${this.state.organisation.eFaction.sAPBDB}`}>
            <FactionIcon faction={this.state.organisation.eFaction.eFaction} />
          </NavLink>
        </>
      )

      return (
        <Container fluid className="organisation-component">
          <MetaDecorator title={this.state.organisation.sName} />
          <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
            <Row noGutters>
              <Image 
                alt="Contacts Background" 
                src={`${CDN_ROOT}/apbdb/background_detail.webp`} 
                className="background-image opacity-5" />
              <WideHeader 
                title={this.state.organisation.sName}
                subTitle={subTitle}
                contentRight={contentRight} />
              <WideDescription>
                <Col>
                  <p className="pre-wrap">
                    {this.state.organisation.eOrganisationContact.sDescription}
                  </p>
                </Col>
              </WideDescription>
              <OrganisationTabs 
                organisation={this.state.organisation}
                switchTab={this.switchTab}
                selectedTab={this.state.selectedTab} 
              />
            </Row>
          </SimpleBar>
        </Container>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class OrganisationTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasRelatedContacts: false
    }
  }

  componentDidMount() {
    if (this.props.organisation.aRelatedContacts.length > 0) {
      this.setState({ hasRelatedContacts: true });
    }
  }

  render() {
    return (
      <WideTabs selectedTab={this.props.selectedTab} switchTab={this.props.switchTab}>
        <Tab 
          eventKey="contacts" 
          disabled={!this.state.hasRelatedContacts}
          title={<span><i className="fas fa-users" /> CONTACTS</span>}
        >
          <ContactsList contacts={this.props.organisation.aRelatedContacts} />
        </Tab>
      </WideTabs>
    );
  }
}

const ContactsList = props => (
  <Table>
    <tbody>
      {props.contacts.map(contact => (
        <tr>
          <td className="small-width text-center">
            <Image fluid src={contact.eContactIcon} alt={contact.sTitle} />
          </td>
          <td>
            <NavLink to={`/contacts/${contact.sAPBDB}`}>
              {contact.sTitle}
            </NavLink>
          </td>
          <td>
            <NavLink to={`/factions/${contact.eOrganisation.eFaction.sAPBDB}`}>
              {contact.eOrganisation.eFaction.sDisplayName}
            </NavLink>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)
