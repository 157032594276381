import React from 'react';
import SimpleBar from 'simplebar-react';
import { NavLink } from 'react-router-dom';
import { Container, Accordion, Card, Table } from 'react-bootstrap';

// Components
import { ErrorMessage } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Changelog.scss';

export default class Changelog extends React.Component {
  constructor(props) {
    super(props);

    this.state = { entries: [], entriesLoaded: false }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/changelog', 'entries');
  }

  render() {
    if (this.state.entriesError) return <ErrorMessage error={this.state.entriesError} />;

    if (this.state.entriesLoaded) {
      return (
        <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
          <MetaDecorator title="Changelog" />
          <Container className="changelog-component">
            <h1 className="display-4 mt-3">
              CHANGELOG
            </h1>
            {this.state.entries.map(e => (
              <Accordion key={e.id}>
                  <Entry e={e} />
              </Accordion>
            ))}
          </Container>
        </SimpleBar>
      )
    } else {
      return utils.loadingCircle();
    }
  }
}

class Entry extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      changes: [], 
      changesLoaded: false,
    }

    this.request = utils.request.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.state.changes.length > 0) return;
    this.request(`/changelog/${this.props.e.id}`, 'changes');
  }

  sortByKey = arr => {
    return arr.sort((a, b) => {
      // Sort by isNew
      if (a.isNew === b.isNew) {
        // If both have the same isNew value, sort by isRemoved
        if (a.isRemoved === b.isRemoved) {
          // If both have the same isRemoved value, sort by sAPBDB
          if (a.sAPBDB < b.sAPBDB) {
            return -1;
          } else if (a.sAPBDB > b.sAPBDB) {
            return 1;
          } else {
            return 0;
          }
        } else if (a.isRemoved) {
          // If a is isRemoved, move it to the bottom of the isNew group
          return 1;
        } else {
          // If b is isRemoved, move it to the bottom of the isNew group
          return -1;
        }
      } else if (a.isNew) {
        // If a is isNew, move it to the bottom of the array
        return 1;
      } else {
        // If b is isNew, move it to the bottom of the array
        return -1;
      }
    });
  }

  render() {
    let options = { weekday: 'long', month: 'short', year: 'numeric', day: 'numeric'};
    let date = new Date(this.props.e.PubDate);
    let items; let weapons; let missions; let explosions; let vehicles; let roles;

    if (this.state.changesLoaded) {
      items = this.state.changes.filter(c => c.TableName === 'InventoryItemTypes');
      weapons = this.state.changes.filter(c => c.TableName === 'WeaponTypes' || c.TableName === 'RangedWeaponType');
      missions = this.state.changes.filter(c => c.TableName === 'MissionTemplates');
      explosions = this.state.changes.filter(c => c.TableName === 'Explosions');
      vehicles = this.state.changes.filter(c => c.TableName === 'VehicleSetupTypes');
      roles = this.state.changes.filter(c => c.TableName === 'PlayerRoles');
    }

    return (
      <Card>
        <Accordion.Toggle 
          as={Card.Header} 
          eventKey={this.props.e.id.toString()} 
          onClick={this.handleClick}>
          <p className="m-0">
            {this.props.e.OldVersion} {`>`} {this.props.e.NewVersion} 
            {this.props.e.Title && <span className="text-muted"> : {this.props.e.Title}</span>}
            <span className="text-muted float-right">
              {this.props.e.PubDate ? date.toLocaleDateString("en-US", options) : null} 
            </span>
          </p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={this.props.e.id.toString()} >
          <Card.Body>
            {this.props.e.Description && <p>{this.props.e.Description}</p>}
            {this.state.changesError && <ErrorMessage noVCenter error={this.state.changesError} />}
            {this.state.changesLoaded ? 
            <>
              {weapons && 
                <Changes title="Weapon Changes" changes={this.sortByKey(weapons)} />
              }
              {explosions && 
                <Changes title="Explosion Changes" changes={this.sortByKey(explosions)} />
              }
              {missions && 
                <Changes 
                  title="Mission Changes" 
                  navLink="/missions"
                  changes={this.sortByKey(missions)} />
              }
              {vehicles && 
                <Changes title="Vehicle Changes" changes={this.sortByKey(vehicles)} />
              }
              {roles && 
                <Changes 
                  title="Roles & Achievements" 
                  navLink="/roles"
                  changes={this.sortByKey(roles)} />
              }
              {items && 
                <Changes 
                  title="Items" 
                  navLink="/items" 
                  changes={this.sortByKey(items)} />}
              {!this.state.changes.length && <p>No changes recorded.</p>}
            </> : utils.loadingCircle(true)}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    )
  }
}

const Changes = props => {
  if (props.changes.length) {
    return (
      <Table size="sm" hover striped bordered>
        <thead>
          <tr>
            <th>{props.title}</th>
            {props.changes.some(obj => obj.Attribute !== null) &&
              <>
                <th>Attribute</th>
                <th>Old</th>
                <th>New</th>
              </>
            }
          </tr>
        </thead>
        <tbody>
          {props.changes.map(change => {
            let key = change.isChanged ? `changed-${change.Attribute}` : change.isNew ? 'new' : change.isRemoved && 'removed';
            let sapbdb = change.sAPBDB;

            if (props.navLink) {
              sapbdb = <NavLink to={`${props.navLink}/${sapbdb}`}>{sapbdb}</NavLink>
            }

            return (
              <tr 
                className={change.isNew ? 'new-entry' : change.isRemoved && 'deleted-entry'}
                key={`${key}-${change.sAPBDB}-${change.TableName}`}>
                
                {change.isNew || change.isRemoved ?
                  <td colSpan={4}>
                    <code>{change.TableName}</code> {sapbdb} 
                  </td>
                : null } 

                {!!change.isChanged && 
                  <>
                    <td>{sapbdb}</td>
                    <td>{change.Attribute}</td>
                    <td>{change.OldValue}</td>
                    <td>{change.NewValue}</td>
                  </>
                }
                
              </tr>
              )
          })}
        </tbody>
      </Table>
    )
  }
  return null;
}
