import React from 'react';
import { Row, Col } from 'react-bootstrap';

// Extras
import utils from '../../_helpers/utils';
import { ErrorMessage } from '../Extras';

export default class Changes extends React.Component {
  constructor(props) {
    super(props);

    this.state = { }

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request(`/changelog/item/${this.props.sAPBDB}`, 'changelogs');
  }

  createChangelog(changelog) {
    let options = { weekday: 'long', month: 'short', year: 'numeric', day: 'numeric'};
    let date = new Date(changelog.PubDate);

    return (
      <Col md={12} key={changelog.id}>
        <i style={{ color: 'lightgray' }}>
          {changelog.Title ? `${changelog.Title} - ` : ''}
          {changelog.NewVersion} ({date.toLocaleDateString("en-US", options)})
        </i>
        <ul>
          {changelog.changes.map(c => (
            <li key={`change-${c.TableName}-${c.id}`}>
              <small>
                {c.isChanged ?
                  <>Updated <code>{c.Attribute}</code> from <code>{c.OldValue}</code> to <code>{c.NewValue}</code>.</>
                : c.isNew ?
                  <>Added <code>{c.TableName} :: {c.sAPBDB}</code></>
                : !!c.isRemoved &&
                  <>Removed <code>{c.TableName} :: {c.sAPBDB}</code></>
                }
              </small>
            </li>
          ))}
        </ul>
      </Col>
    )
  }

  render() {
    if (this.state.changelogsError) {
      return <ErrorMessage noVCenter error={this.state.changelogsError} />
    }

    if (this.state.changelogsLoaded) {
      if (this.state.changelogs.length === 0) {
        return (
          <Row>
            <Col className="text-center">
              <i>No recorded changes found.</i>
            </Col>
          </Row>
        )
      }

      return (
        <Row>
          {this.state.changelogs.map(changelog => this.createChangelog(changelog))}
        </Row>
      )
    }

    return utils.loadingCircle(true);
  }
}