import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaDecorator = ({ title, ogTitle, ogDescription, ogUrl, ogImage }) => {
  if (ogDescription && ogDescription.length > 255) {
    ogDescription = `${ogDescription.substring(0, 255)}...`;
  }

  return (
    <Helmet>
      <title>{title.replace(/(<([^>]+)>)/gi, "")} | APB:Db</title>
      {ogTitle && <meta property="og:title" content={ogTitle} />}
      {ogDescription && <meta property="og:description" content={ogDescription} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
    </Helmet>
  )
}

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string
}

export default MetaDecorator;