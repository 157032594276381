import React from 'react';
import { Form, Row, Col, Image, ListGroup } from 'react-bootstrap';

// Components
import Statistics from './ItemStatistics';

// Extras
import utils from '../../_helpers/utils';

export default class Compare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      version: null,
      relatedItems: [],
      searchedItems: [],
      searchedItemsLoaded: true
    }

    this.request = utils.request.bind(this);
    this.searchItems = this.searchItems.bind(this);
    this.getRelatedItems = this.getRelatedItems.bind(this);
    this.resetSelectedItem = this.resetSelectedItem.bind(this);
  }

  componentDidMount() {
    this.getRelatedItems();

    if (this.props.compare) this.getSelectedItem(this.props.compare);
  }

  getRelatedItems() {
    let category;

    switch(this.props.item.category) {
      case 'Vehicle':
        category = `?cat=${this.props.item.category}`;
        break;
      default:
        category = `?infracat=${this.props.item.infracategory}`;
        break;
    }

    this.request(`/items/${category}`, 'relatedItems', 'items');
  }

  getSelectedItem(sapbdb) {
    this.props.updateUrl('item', sapbdb);
    this.request(`/items/${sapbdb}`, 'selected');
  }

  searchItems(e) {
    if (e.key === 'Enter') {
      this.request(`/items?search=${e.target.value}&cat=${this.props.item.category}`, 'searchedItems', 'items');
    }
  }

  resetSelectedItem() {
    this.props.updateUrl('item', null);
    this.setState({selected: null});
  }

  render() {
    if (!this.state.selected) {
      return (
        <Row className="compare-tab">
          <Col className="text-center">
            <Form.Control 
              onKeyDown={this.searchItems}
              type="text" 
              placeholder='Search item to compare'
              className="text-left compare-search" />
            {this.state.searchedItemsLoaded ? 
              <>
                <ListGroup className="list-group-small text-left">
                  {this.state.searchedItems.map(item => (
                  <ListGroup.Item 
                    key={item.sAPBDB}
                    onClick={() => this.getSelectedItem(item.sAPBDB)}>
                    <Image 
                      src={item.eHUDImage} 
                      alt={item.sDisplayName}
                      onError={utils.handleImageError} />
                    <small dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}}></small>
                  </ListGroup.Item>
                  ))}
                </ListGroup>
                {this.state.searchedItems.length < 1 && <small>no results or incomplete search</small>}
              </>
              : utils.loadingCircle(true)
            }
          </Col>
          <Col>
            <h6>RELATED ITEMS</h6>
            {this.state.relatedItemsLoaded ? 
              <ListGroup className="list-group-small">
                {this.state.relatedItems.map(item => (
                <ListGroup.Item 
                  key={item.sAPBDB}
                  onClick={() => this.getSelectedItem(item.sAPBDB)}>
                  <Image 
                    src={item.eHUDImage} 
                    alt={item.sDisplayName}
                    onError={utils.handleImageError} />
                  <small dangerouslySetInnerHTML={{__html: utils.convertColorCode(item.sDisplayName)}}></small>
                </ListGroup.Item>
                ))}
              </ListGroup>
              : utils.loadingCircle(true)
            }
          </Col>
        </Row>
      )
    } else {
      return (
        <Row noGutters className="compare-tab">
          <Col md={{span: 4, offset: 1}} className="text-center">
            <h6 dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.props.item.sDisplayName)}}></h6>
          </Col>
          <Col md={2} className="text-center">
            <i className="fas fa-exchange-alt"></i>
          </Col>
          <Col md={4} className="text-center">
            <h6 dangerouslySetInnerHTML={{__html: utils.convertColorCode(this.state.selected.sDisplayName)}}></h6>
          </Col>
          <Col md={1} className="text-center reset">
            <small onClick={this.resetSelectedItem}>
              <i className="fas fa-undo"></i> Reset
            </small>
          </Col>
          <Col md={6}>
            <Statistics 
              cols={12} 
              detail={this.props.item.detail} 
              category={this.props.item.category}
              diff={this.state.selected.detail} />
          </Col>
          <Col md={6}>
            <Statistics 
              cols={12} 
              detail={this.state.selected.detail} 
              category={this.state.selected.category}
              diff={this.props.item.detail} />
          </Col>
        </Row>
      )
    }
  }
}
