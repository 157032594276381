import React from 'react';
import { Image } from 'react-bootstrap';
import { API_ROOT, CDN_ROOT, MISSING_ICON } from './constants';

const utils = {
  request(url, state, subresults=false) {
    this.setState({ [state + 'Loaded']: false });

    return fetch(API_ROOT + url)
    .then(res => res.json())
    .then(
      (result) => {
        if (subresults) {
          this.setState({
            [state]: result[subresults],
            [state + 'Loaded']: true
          })
        } else {
          this.setState({
            [state]: result,
            [state + 'Loaded']: true
          })
        }
      },
      (error) => {
        this.setState({
          [state + 'Error']: error,
          [state + 'Loaded']: true
        })
      }
    )
  },

  convertColorCode(text) {
    try {
      text = text.split('<Color:/>').join('</span>');  // replace endings
      text = text.split('</Col>').join('</span>');  // replace endings
      text = text.split('</col>').join('</span>');  // replace endings
      text = text.split('<Col: StageText>').join('<span style="color:yellow;">');  // replace StageText
      text = text.split('<col: Yellow>').join('<span style="color:yellow;">');  // replace StageText
      var match = text.match(/<Color:([^<]*)>/g);  // get all color codes
    } catch (TypeError) {
      return text;
    }

    if (match === null) return text;  // exit if match null

    // replace color codes with span styles
    match.forEach(m => text = text.replace(m, this.colorCodeToStyle(m)));

    return text;
  },

  colorCodeToStyle(cc) {
    try {
      var r = this.textBetweenTwoCharacters(cc, 'R=', ' G').replace('=', '');
      var g = this.textBetweenTwoCharacters(cc, 'G=', ' B').replace('=', '');
      var b = cc.split('B=')[1].replace('>', '');
  
      return `<span style="color: rgb(${r*255}, ${g*255}, ${b*255})">`;
    } catch {
      return cc;
    }
  },

  textBetweenTwoCharacters(text, first, second) {
    return text.substring( 
      text.indexOf( first ) + 1, 
      text.indexOf( second ) 
    )
  },

  fixDecimals(val) {
    return Number(val.toFixed(3));
  },

  calculateHardDamage(damage, multiplier) {
    return this.fixDecimals(damage * multiplier);
  },

  loadingCircle(noVCenter=false, text='') {
    let alignment;
    if (noVCenter) {
      alignment = 'text-center';
    } else {
      alignment = 'screen-center text-center'
    }

    return (
      <div className={alignment}>
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
          {text}
        </div>
      </div>
    );
  },

  displayTrueFalse(value) {
    if (value === 0) {
      return 'No';
    }
    return 'Yes';
  },

  displayFaction(bCriminal, bEnforcer) {
    if (bCriminal && bEnforcer) {
      return "Both";
    } else if (bCriminal) {
      return "Criminal";
    } else if (bEnforcer) {
      return "Enforcer";
    }
  },

  displayFactionAlt(faction) {
    if (faction === 0) {
      return "Both";
    } else if (faction === 1) {
      return "Enforcer";
    } else if (faction === 2) {
      return "Criminal";
    }
  },

  seperateCapitalLetters(text) {
    try {
      return text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
    } catch (TypeError) {
      return text;
    }
  },

  calcScaleDamage(damage, rays, scale) {
    var final_damage = 0;
    for (let i = 0; i < rays; i++) {
        final_damage += Math.floor(damage * (scale ** i));
    }
    return final_damage;
  },

  parseJwt(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  },

  handleImageError(e) {
    if (e.target.src !== MISSING_ICON) {
      e.target.onerror = null; 
      e.target.src = MISSING_ICON;
    }
  },

  combineRewardPackageItems(rewardPackage)  {
    let combinedItems = [];
    let items = rewardPackage.eItems.concat(rewardPackage.eChildPackage.eItems);
  
    items.forEach(item => {
      if (item) combinedItems.push(item);
    })
  
    return combinedItems;
  },

  isDev() {
    if (window.location.hostname === "localhost" || 
        window.location.hostname === "dev.apbdb.com") {
      return true;
    }
    return false;
  },

  hoverLine() { 
    return {
      id: 'hoverLine',
      afterDatasetsDraw(chart) {
        // If chart or tooltips not found, skip rendering
        if (!chart) return;
        if (!chart.tooltip._active.length) return;

        // Draws vertical line on hover for better visibility
        const { 
          ctx, 
          tooltip,
          chartArea: { top, bottom },
          scales: { x } } = chart;

        const xCoord = x.getPixelForValue(tooltip.dataPoints[0].dataIndex);

        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'rgba(255, 0, 0, 1)';
        ctx.moveTo(xCoord, top);
        ctx.lineTo(xCoord, bottom);
        ctx.stroke();
        ctx.closePath();
      }
    }
  },

  supportMe() {
    return (
      <p className="support-text">
        <a href="https://ko-fi.com/speed_z">
          <Image 
            src={`${CDN_ROOT}/apbdb/ko-fi.webp`} 
            alt="ko-fi logo"
            height={32} width={32} />
          Support the project on Ko-fi
        </a>
      </p>
    )
  }
}

export default utils;