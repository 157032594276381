import React from 'react';
import { Container, Row, Col, Tabs } from 'react-bootstrap';

// Styles
import '../styles/WideTemplate.scss';

export class WideTemplate extends React.Component {
  // public functions here
}

export const WideHeader = props => (
  <Col className="wide-header" md={12}>
    <Container>
      <Row noGutters className="align-items-center">
        <Col xs={8} md={8}>
          <h2>{props.title}</h2>
          {props.subTitle}
        </Col>
        <Col xs={4} md={4} className="text-right justify-content-center">
          {props.contentRight}
        </Col>
      </Row>
    </Container>
  </Col>
)

export const WideDescription = props => (
  <Col className="wide-description" md={12}>
    <Container>
      <Row>
        {props.children}
      </Row>
    </Container>
  </Col>
)

export const WideTabs = props => (
  <Container className="wide-tabs">
    <Tabs 
      id="tabs" mountOnEnter
      activeKey={props.selectedTab}
      onSelect={props.switchTab}
    >
      {props.children}
    </Tabs>
  </Container>
)
