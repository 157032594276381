import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';

// Utilities
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Districts.scss';

export default class Districts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      row1: [
        {
          name: 'Financial',
          type: 'Action District',
          sAPBDB: 'Financial'
        },
        {
          name: 'Waterfront',
          type: 'Action District',
          sAPBDB: 'Waterfront'
        }
      ],
      row2: [
        {
          name: 'Breakwater Marina',
          type: 'Social District',
          sAPBDB: 'Social',
          //vertical: true
        },
        {
          name: 'Abington Towers',
          type: 'Fight Club',
          sAPBDB: 'PGAsylum',
          //vertical: true
        },
        {
          name: 'Baylan Shipping Storage',
          type: 'Fight Club',
          sAPBDB: 'PGCrate',
          //vertical: true
        },
        {
          name: 'The Beacon',
          type: 'Fight Club',
          sAPBDB: 'PGBeacon',
          //last: true
        }
      ]
    }
  }

  render() {
    return (
      <Container fluid className="districts-component">
        <MetaDecorator title="Districts" />
        <Row className="no-gutters">
          {this.state.row1.map(o => (
            <DistrictColumnContent o={o} key={o.sAPBDB} size={6} />
          ))}
          {this.state.row2.map((o, i) => (
            <DistrictColumnContent o={o} key={o.sAPBDB} size={3} />
          ))}
        </Row>
      </Container>
    );  
  }
}

const DistrictColumnContent = props => (
  <Col as={NavLink} 
       to={`/districts/${props.o.sAPBDB}`} 
       md={12} lg={props.size} 
       className={`district-col ${props.o.vertical && 'vertical'} ${props.o.last && 'last'}`}>
    <Image fluid
      alt={props.o.Name} 
      src={`${CDN_ROOT}/apbdb/districts/${props.o.name.toLowerCase().split(' ').join('')}.webp`} />
    <div className="centered">
      <h2 className="font-weight-light">{props.o.name}</h2>
      <p>{props.o.type}</p>
    </div>
  </Col>
);