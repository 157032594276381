import React from 'react';
import {
  Chart as ChartJS,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Utilities
import utils from '../../_helpers/utils';

ChartJS.register(
  Tooltip
);

export class DamageDropOffChart extends React.Component {
  constructor(props) {
    super(props);

    this.weapon = this.props.weapon_modified ? this.props.weapon_modified : this.props.weapon;
    this.ranged = this.props.ranged_modified ? this.props.ranged_modified : this.props.ranged;

    this.options = {
      responsive: true,
      spanGaps: true,  // Skip null data to connect points
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          axis: 'x',
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';
              if (label) label += ': ';
              label += context.parsed.y;
              return label;
            }
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        y: {
          beginAtZero: true
        }
      }
    }

    // Set line tension and line interpolation mode if a curve exists
    let tension = this.hasWeaponCurve(this.weapon) ? 0.4 : 0;
    let interpolMode = this.hasWeaponCurve(this.weapon) ? 'monotone' : 'default';
    this.data = {
      labels: [],
      datasets: [{
        label: 'Health Damage',
        data: [],
        borderColor: 'yellowgreen',
        pointBackgroundColor: 'yellowgreen',
        borderWidth: 2,
        lineTension: tension,
        cubicInterpolationMode: interpolMode,
        pointRadius: 0,
        fill: false
      },
      {
        label: 'Stamina Damage',
        data: [],
        borderColor: 'red',
        pointBackgroundColor: 'red',
        borderWidth: 2,
        lineTension: tension,
        cubicInterpolationMode: interpolMode,
        pointRadius: 0,
        fill: false
      }, 
      {
        label: 'Hard Damage',
        data: [],
        borderColor: 'lightskyblue',
        pointBackgroundColor: 'lightskyblue',
        borderWidth: 2,
        lineTension: tension,
        cubicInterpolationMode: interpolMode,
        pointRadius: 0,
        fill: false
      }]
    }
  }

  componentDidMount() {
    this.update();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.weapon_modified !== this.props.weapon_modified
        || prevProps.ranged_modified !== this.props.ranged_modified) {
      this.weapon = this.props.weapon_modified;
      this.ranged = this.props.ranged_modified;
      this.update();
    } else if (prevProps.weapon !== this.props.weapon 
        || prevProps.ranged !== this.props.ranged) {
      this.weapon = this.props.weapon;
      this.ranged = this.props.ranged;
      this.update();
    }
  }

  update() {
    // Add range lables from 0-99m
    this.data.labels = [];  // clear labels
    for (let i = 0; i < 100; i++) {
      this.data.labels.push(i + " m");
    }

    // Health Damage
    this.addDataset(0, utils.calcScaleDamage(
      this.weapon.fHealthDamage, 
      this.ranged.nRaysPerShot, 
      this.ranged.fPerRayDamageScale));
    // Stamina Damage
    this.addDataset(1, utils.calcScaleDamage(
      this.weapon.fStaminaDamage, 
      this.ranged.nRaysPerShot, 
      this.ranged.fPerRayDamageScale));
    // Hard Damage
    this.addDataset(2, utils.calcScaleDamage(utils.calculateHardDamage(
        this.weapon.fHealthDamage, 
        this.weapon.fHardDamageModifier), 
      this.ranged.nRaysPerShot, 
      this.ranged.fPerRayDamageScale));

    this.forceUpdate();
  }

  addDataset(dataset, damage) {
    var maxRange = +this.ranged.fMaxRange / 100;
    var minDamageRange = (+this.ranged.fMinDamageRange + +this.props.ranged.fRampDistance) / 100;
    var dropoffRange = +this.ranged.fRampDistance / 100;

    if (this.hasWeaponCurve(this.weapon)) {
      var curveInc = 5;
      var curve = Object.create(this.weapon.eWeaponCurve.sEffectiveRange);
      minDamageRange = +this.ranged.fMinDamageRange / 100;
    }

    var scaleMeters = minDamageRange - dropoffRange;
    var minDamage = damage * (this.ranged.fMinimumDamagePercentage / 100);
    var scaleDamage = damage - minDamage;
    var damagePerMeter = scaleDamage / scaleMeters;

    var range = 0;
    var rampInc = 1;

    this.data.datasets[dataset].data = [];  // clear dataset
    while (range < maxRange) {
      if (range <= dropoffRange || minDamageRange === 0) {
        this.data.datasets[dataset].data.push(Math.floor(damage));
      } else if (range > dropoffRange && range < minDamageRange) {
        this.data.datasets[dataset].data.push(Math.floor(damage - damagePerMeter * rampInc));
        rampInc++;
      } else {
        this.data.datasets[dataset].data.push(Math.floor(minDamage));
      }

      // Weapon Curve
      if (this.hasWeaponCurve(this.weapon)) {
        if (range >= dropoffRange && range <= minDamageRange) {
          var diff = (range - dropoffRange).toFixed();
          if (!(diff % curveInc)) {
            var distIndex = ((Number(curve.start * 100) + Number(diff)) / curveInc).toFixed();
            this.data.datasets[dataset].data[range] = Math.floor(damage * curve.distribution[distIndex]);
          } else {
            this.data.datasets[dataset].data[range] = null;
          }
        } else if (range >= minDamageRange) {
          this.data.datasets[dataset].data[range] = Math.floor(damage * curve.distribution[curve.distribution.length-1]);
        }
      }

      // if (dataset === 0) console.log(`${range} m | ${dropoffRange} -> ${minDamageRange} = ${this.data.datasets[dataset].data[this.data.datasets[dataset].data.length-1]}`)
      range++;
    }
  }

  hasWeaponCurve(weapon) {
    if (weapon.eWeaponCurve.sEffectiveRange 
        && typeof(weapon.eWeaponCurve.sEffectiveRange) !== 'string') {
        return true;
    }
    return false;
  }

  hasMissingCurve(weapon) {
    return weapon.eWeaponCurve !== 0 
      && typeof(weapon.eWeaponCurve.sEffectiveRange) === 'string'
      && weapon.eWeaponCurve.sEffectiveRange !== 'None';
  }

  render() {
    return (
      <div className="mb-3">
        <h6 className="pl-1">
          RANGE DAMAGE DROPOFF {this.hasWeaponCurve(this.weapon) && <span className="text-warning">CURVE BETA</span>}
        </h6>
        {this.hasMissingCurve(this.weapon) &&
        <small className="text-danger pl-1">
          Warning: Range Curve data missing, dropoff graph may be wrong.
        </small>
        }
        <Line 
          data={this.data} 
          options={this.options} 
          plugins={[utils.hoverLine()]} />
      </div>
    )
  }
}