// Keys that are ignored by the comparison
const ignoredKeys = [
  'Ammo Category', 'Detonated On Impact', 'Drive Type'
]

// Keys that should be reversed better > worse -> better < worse
const reverseKeys = [
  'Time To Kill', 'Shots To Kill', 'Time To Stun', 'Shots To Stun', 'Equip Time', 
  'Fire Interval', 'Burst Interval', 'Reload Time', 'Resupply Delay', 'Fuse Delay',
  'Wind-Up Time', 'Accuracy Radius at 10m', 'Spread at 10m', 'Per Shot Modifier', 
  'Shot Modifier Cap', 'Recovery Delay', 'Crouch Modifier', 'Walk Modifier', 
  'Run Modifier', 'Sprint Modifier', 'Jump Modifier', 'Lean Modifier', 
  'Marksman Modifier', 'In Vehicle Modifier', 'Sprint Delay', 'Spawn Cost',
  'Max Repair Time', 'Gravity Multiplier'
]

const compare = {
  // Compares 2 objects and returns an array of objects containing differences
  // Will only consider equal keys for comparison. Only compares on the first
  // object level (no child arrays, objects).
  diff(first, second) {
    let diffs = [];

    Object.entries(first).forEach(([key, value]) => {
      // Skip if key should be ignored
      if (ignoredKeys.includes(key)) return

      // Ignore everything after first whitespace and replace dollars
      let firstValue = String(value).split(' ')[0].replace('$', '');
      let secondValue = String(second[key]).split(' ')[0].replace('$', '');

      if (firstValue !== secondValue) {
        // Check if key should be reversed
        if (reverseKeys.includes(key)) {
          diffs.push({
            key: key,
            better: Number(firstValue) < Number(secondValue) ? true : false
          })
        } else {
          diffs.push({
            key: key,
            better: Number(firstValue) > Number(secondValue) ? true : false
          })
        }
      } 
    })
    
    return diffs;
  }
}

export default compare