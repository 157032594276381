import React from 'react';
import SimpleBar from 'simplebar-react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { CDN_ROOT } from '../../../_helpers/constants';


// Components
import { FAIcon } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './About.scss';

export default class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = { version: {}, versionLoaded: false}

    this.request = utils.request.bind(this);
  }

  componentDidMount() {
    this.request('/version', 'version');
  }

  render() {
    return (
      <SimpleBar style={{ height: 'calc(100vh - 56px)' }}>
        <Container className="about-component">
          <MetaDecorator title="About" />
          <Row className="justify-content-center align-items-center">
            <Col md={6}>
              <Image src={`${CDN_ROOT}/apbdb/Ch05.webp`} fluid />
            </Col>
            <Col className="header-text">
              <h1>
                <span>About</span>
              </h1>
              <p>
                APB Database is a community project website for APB Reloaded and 
                was first created back in 2011 by Pilate. Nowadays the project
                is fully maintained by Speedz.
              </p>
              <p>
                The website tries to provide as much accurate information about the
                game as possible. Items, Progression, Districts, Missions and 
                a bunch of more data being kept up-to-date for the community.
              </p>

              {utils.supportMe()}

              <h4>Status</h4>
              {this.state.versionLoaded && !this.state.versionError ?
                <p>
                  Live Client: {this.state.version.live.version} ({new Date(this.state.version.live.pubdate).toDateString()})<br />
                  OTW Client: {this.state.version.otw.version} ({new Date(this.state.version.otw.pubdate).toDateString()})<br />
                  Database: {this.state.version.db.version} ({new Date(this.state.version.db.pubdate).toDateString()})
                </p>
                : utils.loadingCircle(true)
              }

              <h4>Contact</h4>
              <p>
                <FAIcon title='Mail' icon='fas fa-envelope' /> contact.speedz@gmail.com <br />
                <FAIcon title='Discord' icon='fab fa-discord' /> speedhaste
              </p>

              <h4>Contributers & Supporters</h4>
              <p>
                Pilate, Natzu, Nry_Chan, GhosT, Skitty, Tobii, Kewlin, Kevkof, 
                rooq, Yeedman, Ketog, Nobri, Brandon
              </p>

              <h4>Links</h4>
              <p>
                <a href="http://old.apbdb.com">Old Database</a>
              </p>
            </Col>
          </Row>
        </Container>
      </SimpleBar>
    )
  }
}