import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Image, Modal, Button } from 'react-bootstrap';
import { CDN_ROOT } from '../_helpers/constants';

// Utilities
import utils from '../_helpers/utils';

// Styles
import '../styles/Navigation.scss';

// Services
import { authService } from '../_services/auth';
import { settingsService } from '../_services/settings';

export default class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { timer: 0, showSettings: false }
  }

  componentDidMount() {
    settingsService.checkSettings();
    const user = authService.getAccessToken();
    if (user) {
      setInterval(() => {
        this.setState({ timer: Math.round(user.exp - new Date().getTime() / 1000) });
      }, 1000)
    }
  }

  toggleSettings = () => {
    if (this.state.showSettings) {
      this.setState({ showSettings: false });
    } else {
      this.setState({ showSettings: true });
    }
  }

  render() {
    return (
      <div className="navigation-component">
        <Navbar variant="dark" expand="md" fixed="top" className="flex-md-nowrap p-0">
          <Navbar.Brand as={NavLink} to="/" className={`col-6 col-sm-6 col-md-2 mr-0${utils.isDev() && " navbar-brand-dev"}`}>
            <b>APB</b>: DATABASE {utils.isDev() && " // DEV"}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navigation" />
          <Navbar.Collapse id="navigation">
            <Nav className="mr-auto">
              <Nav.Link as={NavLink} to="/items">
                <APBIcon icon="inventory" /> ITEMS
              </Nav.Link>
              <NavDropdown 
                id="nav-dropdown-missions"
                title={<><APBIcon icon="factionheadericon" /> PROGRESSION</>}
              >
                <NavDropdown.Item as={NavLink} to="/achievements">ACHIEVEMENTS</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/contacts">CONTACTS</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/heat">HEAT</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/rating">RATING</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/roles">ROLES</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={NavLink} to="/districts">
                <APBIcon icon="worldselecticon"/> SAN PARO
              </Nav.Link>
              <NavDropdown 
                id="nav-dropdown-missions"
                title={<><APBIcon icon="mission_won" /> MISSIONS</>}
              >
                <NavDropdown.Item as={NavLink} to="/minigames">MINIGAMES</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/missions">MISSIONS</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/medals">MEDALS</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown 
                id="nav-dropdown-other" 
                title={<><APBIcon icon="AssignedUnlockNoItem" /> OTHER</>}
              >
                <NavDropdown.Item as={NavLink} to="/tracker">TRACKER</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/commands">COMMANDS</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/other">OTHER</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/changelog">CHANGELOG</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="/about">ABOUT</NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <Nav.Link as={NavLink} to="/">
              <i className="fas fa-search text-muted" /> 
            </Nav.Link>
            <Nav.Link onClick={this.toggleSettings}>
              <i className="fas fa-cog text-muted" />
            </Nav.Link>
            <SettingsModal 
              show={this.state.showSettings} 
              toggle={this.toggleSettings} />
            <authService.userContext.Consumer>
              {({user, logout}) => {
                if (!user) return null;
                return (
                  <NavDropdown 
                    id="nav-dropdown-user" drop="left"
                    title={<Image className="icon" src={`${CDN_ROOT}/interface/player.webp`}/>} 
                  >
                    <NavDropdown.Header>
                      {`${user.name} (${user.role}) ${this.state.timer}`}
                    </NavDropdown.Header>                    
                    <NavDropdown.Item as={NavLink} to="/admin">
                      <i className="fas fa-user-shield" /> Admin
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={logout}>
                      <i className="fas fa-sign-out-alt" /> Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                )
              }}
            </authService.userContext.Consumer>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}


const APBIcon = props => (
  <Image 
    className="icon" 
    alt={props.icon} 
    src={`${CDN_ROOT}/interface/${props.icon}.webp`} />
)

class SettingsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: [
        {
          name: 'debug',
          value: false,
          description: 'Display Debug Information'
        },
        {
          name: 'modding',
          value: false,
          description: 'Disable Modding Restrictions'
        }
      ]
    }
  }

  reloadSettings = () => {
    const site = settingsService.get('site');
    var settings = this.state.settings.map(setting => {
      return {
        name: setting.name,
        value: site[setting.name],
        description: setting.description
      }
    })
    
    this.setState({ settings: settings });
  }

  onEnter = () => {
    this.reloadSettings();
  }

  onExited = () => {
    window.location.reload();
  }

  handleChange = (name, value) => {
    if (value) {
      settingsService.set('site', name, false);
    } else {
      settingsService.set('site', name, true);
    }
    this.reloadSettings();
  }

  render() {
    return (
      <Modal 
        onEnter={this.onEnter} 
        onExited={this.onExited} 
        show={this.props.show} 
        onHide={this.props.toggle}
      >
        <Modal.Header>
          <Modal.Title>Settings</Modal.Title>
        </Modal.Header>
    
        <Modal.Body>
          {this.state.settings.map(setting => (
            <div key={setting.name}>
              <input 
                checked={setting.value}
                className="styled-checkbox" 
                id={setting.name} 
                type="checkbox" 
                onChange={() => this.handleChange(setting.name, setting.value)} />
              <label htmlFor={setting.name}>
                {setting.description}
              </label>
            </div>
          ))}
        </Modal.Body>
    
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.toggle}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
