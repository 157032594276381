import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

// Components
import { FilterSidebar, FilterTemplate, FilterInput, FilterInputRangeGroup, 
         FilterCheckBox, FilterSelector, TemplateList } from '../../FilterTemplate';
import Detail from '../Mission/Mission';
import { FactionIcon } from '../../Extras';

// Utilities
import utils from '../../../_helpers/utils';
import MetaDecorator from '../../../_helpers/MetaDecorator';

// Styles
import './Missions.scss';

export default class Missions extends FilterTemplate {
  constructor(props) {
    super(props);
    this.apiEndpoint = '/missions';

    this.state = {
      search: new URLSearchParams(this.props.location.search),
      compactMode: Boolean(this.props.match.params.sapbdb),
      selected: this.props.match.params.sapbdb,
      sidebarCollapsed: true,
      missions: [],
      filter: {
        page: 1,
        limit: 25,
        search: false,
        faction: 'Both',
        showDisabled: false,
        showTest: false,
        mingroupsize: null,
        maxgroupsize: null
      }
    }

    // Preset factions for selection
    this.factions = [
      { sDisplayName: 'Both' },
      { sDisplayName: 'Criminal' },
      { sDisplayName: 'Enforcer' }
    ]
  }

  getList = () => {
    this.request()
      .then(
        result => this.setState({ 
          missions: result.missions,
          opcats: result.filters.opcats|| []
        }),
        error => this.setState({ error }))
      .then(() => this.setState({ hasLoaded: true }))
  }

  updateFiltersFromSearch() {
    this.setState({
      filter: {
        page: this.checkFilter('page'),
        limit: this.checkFilter('limit'),
        search: this.checkFilter('search'),
        faction: this.checkFilter('Faction'),
        showDisabled: this.checkFilter('ShowDisabled'),
        showTest: this.checkFilter('ShowTest'),
        mingroupsize: this.checkFilter('minGroupSize'),
        maxgroupsize: this.checkFilter('maxGroupSize'),
        lastStage: this.checkFilter('lastStage')
      }
    });
  }

  updateLastStage = (name) => {
    if (name !== 'All') {
      this.setFilter('lastStage', name);
    } else {
      this.setFilter('lastStage', false);
    }
  }

  updateFaction = (value, state) => {
    if (value === 'Both') {
      this.setFilter(state, false);
    } else {
      this.setFilter(state, value);
    }
  }

  render() {
    return (
      <Container fluid className="missions-component">
        <MetaDecorator title="Missions" />
        <Row noGutters>
          <FilterSidebar reload={this.getList} collapsed={this.state.sidebarCollapsed} 
            collapseSidebar={this.collapseSidebar}>
            <FilterSelector noAll
              name='Faction' 
              update={this.updateFaction}
              selected={this.state.filter.faction}
              options={this.factions} />
            <FilterInput 
              name="Search" 
              search={this.state.filter.search} 
              setFilter={this.setFilter} />
            <FilterInputRangeGroup 
              name='GroupSize' autoCorrect={false}
              setFilter={this.setFilter}
              minValue={this.state.filter.mingroupsize}
              maxValue={this.state.filter.maxgroupsize}
              min='2' 
              max='40' />
            {
              this.state.opcats &&
                <FilterSelector 
                  name='Last Stage' 
                  update={this.updateLastStage}
                  selected={this.state.filter.lastStage}
                  options={this.state.opcats} />
            }
            <FilterCheckBox 
              name='Show Disabled' 
              setFilter={this.setFilter} 
              active={this.state.filter.showDisabled} />
            <FilterCheckBox 
              name='Show Test' 
              setFilter={this.setFilter} 
              active={this.state.filter.showTest} />
          </FilterSidebar>
          <TemplateList 
            list={this.state.missions}
            hasLoaded={this.state.hasLoaded}
            error={this.state.error}
            compactMode={this.state.compactMode}
            collapsed={this.state.sidebarCollapsed}>
            <Table className="mission-table">
              <thead>
                <tr>
                  <td>{this.state.missions.length} missions</td>
                  <td>Stages</td>
                  {!this.state.compactMode && <td>Group Size</td>}
                  {!this.state.compactMode && <td>Faction</td>}
                  {!this.state.compactMode && <td>Final Stage</td>}
                </tr>
              </thead>
              <tbody>
              {this.state.missions.map(mission => (
                <tr key={mission.sAPBDB} 
                    onClick={() => this.setSelected(mission.sAPBDB)}
                    className={mission.sAPBDB === this.state.selected ? 'selected' : ''}>
                  <td className="align-middle">{mission.sMissionTitle}</td>
                  <td className="align-middle">{mission.nStages}</td>
                  {!this.state.compactMode &&
                    <td className="align-middle">
                      {mission.nGroupSizeMin} / {mission.nGroupSizeMax}
                    </td>
                  }
                  {!this.state.compactMode &&
                    <td className="align-middle faction-icon">
                      <FactionIcon faction={mission.eFaction} />
                    </td>
                  }
                  {!this.state.compactMode &&
                    <td className="align-middle">{utils.seperateCapitalLetters(mission.sLastStage)}</td>
                  }
                </tr>
              ))}
              </tbody>
            </Table>
          </TemplateList>
          {
            this.state.compactMode &&
              <Col md={this.state.sidebarCollapsed ? 7 : 9}>
                <Detail 
                  sapbdb={this.state.selected} 
                  toggleCompactMode={this.toggleCompactMode} />
              </Col>
          }
        </Row>
      </Container>
    );
  }
}
