import React from 'react';
import { Line } from 'react-chartjs-2';

// Utilities
import utils from '../../_helpers/utils';

export class VehicleTorqueChart extends React.Component {
  constructor(props) {
    super(props);

    this.options = {
      responsive: true,
      plugins: {
        tooltip: {
          mode: 'index',
          intersect: false,
          axis: 'x',
          callbacks: {
            label: function(context) {
              var label = context.dataset.label || '';
              if (label) label += ': ';
              label += context.parsed.y;
              return label;
            }
          }
        },
        annotation: {
          annotations: {
            deathLine: {
              type: 'line',
              xMin: 3 + (this.props.vehicle.fRedlineRPM / 7000),
              xMax: 3 + (this.props.vehicle.fRedlineRPM / 7000),
              borderColor: 'red',
              borderWidth: .75
            }
          }
        }
      }
    }

    this.data = {
      labels: this.addRPMToLabels([0, 500, 2500, 4500, 7000]),
      datasets: [
        {
          label: 'Torque',
          backgroundColor: 'rgba(255,0,0,0.05)',
          borderColor: 'red',
          pointRadius: 0,
          data: [
            0, 
            this.props.vehicle.f500RpmTorque, 
            this.props.vehicle.f2500RpmTorque, 
            this.props.vehicle.f4500RpmTorque, 
            this.props.vehicle.f7000RpmTorque
          ],
          lineTension: 0
        }
      ]
    }
  }

  addRPMToLabels(data) {
    return data.map(d => d = `${d} RPM`);
  }

  render() {
    return (
      <div className="mb-3">
        <h6>VEHICLE TORQUE</h6>
        <Line 
          data={this.data} 
          options={this.options} 
          plugins={[utils.hoverLine()]} />
      </div>
    )
  }
}