// Roles inherits all logic from the Achievements component
import Achievements from '../Achievements/Achievements';

export default class Roles extends Achievements {
  constructor(props) {
    super(props);
    this.title = 'Roles';
    this.apiEndpoint = '/roles';

    this.state = {
      search: new URLSearchParams(this.props.location.search),
      compactMode: Boolean(this.props.match.params.sapbdb),
      selected: this.props.match.params.sapbdb,
      sidebarCollapsed: true,
      achievements: [],
      filter: {
        search: false,
        faction: 'Both',
        showHidden: false,
      },
    }

    // Preset factions for selection
    this.factions = [
      { sDisplayName: 'Both' },
      { sDisplayName: 'Criminal' },
      { sDisplayName: 'Enforcer' }
    ]
  }
}
